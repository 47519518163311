import React from 'react';
import { useConnect } from '@lore/query-connect';
import BaseActivity from '../../../components_profiles/BaseActivity';

export default function Activity(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <BaseActivity activity={activity} />
  );
};
