import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import SectionTitle from './SectionTitle';
import EmptyState from '../../components/EmptyState';
import Message from '../project-messages-user/Message';

export default function Messages(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const title = 'Recent Messages';
  const link = (
    <Link to="/messages" className="text-cs-blue-500 text-lg">
      Inbox
    </Link>
  );

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          senderId: {
            $not: currentUser.id
          },
          // createdAt: {
          //   $lte: timestamp
          // }
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  });

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <div>
        <SectionTitle
          title={title}
          link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (messages.data.length === 0) {
    return (
      <div>
        <SectionTitle
          title={title}
          link={link}
        />
        <EmptyState
          title="You have no messages."
        />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle
        title={title}
        link={link}
      />
      <div className="grid grid-cols-1 gap-4">
        {messages.data.map(function(message, index) {
          return (
            <div
              key={message.id || message.cid}
              className="bg-white shadow-cs-flat -m-0.5 border-2 border-cs-gray-100 rounded-cs-10 p-4 overflow-hidden relative"
            >
              <Link to={`/messages/${message.data.senderId}`} className="absolute h-full w-full -m-4" />
              <Message message={message} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
