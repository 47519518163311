import React from 'react';
import HeaderNew from './Header-new';
import Questions from './Questions';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
// import launch from '../../../dialogs-routable/_launcher/launch';

export default function Project(props) {
  const { project, className } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  // const to = currentUser.id === project.data.creatorId ?
  //   `/project/${project.id}/manage` :
  //   `/project/${project.id}/view`;

  const to = currentUser.id === project.data.creatorId ? `/manage` : `/view`;

  function onClick() {
    launch(DialogConfig.ProjectManage(project.id, to));
  }

  return (
    <div className={classNames(
        'flex flex-col bg-white shadow-cs-flat -m-0.5 border-2 border-cs-gray-100 rounded-cs-10 relative overflow-hidden',
        // 'hover:bg-cs-orange-50 hover:bg-opacity-50',
        className
    )}>
      {/*<Link to={to} className="absolute w-full h-full" />*/}
      <div onClick={onClick} className="cursor-pointer absolute w-full h-full" />
      <HeaderNew project={project} />
      <div className="w-full bg-cs-gray-50 py-4 px-4">
        <Questions project={project}/>
      </div>
    </div>
  );
}
