import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import Card from '../../../components/Card';

export default function Claim(props) {
  const { claim, children, alignChildren = 'bottom' } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: claim.data.userId
  });

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const offerCreator = useConnect('user.byId', {
    id: offer.data.creatorId
  }, {
    enabled: !!offer.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    offer.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    offerCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  const to = currentUser.id === offer.data.creatorId ?
    `/offer/${offer.id}/manage` :
    `/offer/${offer.id}/view`;

  function onClick() {
    launch(DialogConfig.ClaimView(claim.id));
  }

  return (
    <Card border={false} className={classNames(
      // 'bg-white shadow-md rounded-xl overflow-hidden divide-y divide-gray-200 relative',
      'divide-y divide-gray-200 relative',
      'cursor-pointer',
      claim.data.response === 'Pending' ?
        '-m-0.5 border-2 border-cs-orange-500' :
        '-m-0.5 border-2 border-cs-gray-100'
      //   'border border-cs-gray-200'
    )} onClick={onClick}>
      {/*<Link to={to} className="absolute w-full h-full" />*/}
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={offerCreator.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {offerCreator.data.firstName} {offerCreator.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              <span>Offer Creator</span>
            </div>
          </div>
        </div>
        {claim.data.response === 'Connect' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-green-600">
              Connected
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>You reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {offer.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(claim.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {claim.data.response === 'Pending' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-cs-orange-500">
              Awaiting response
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>Your reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {offer.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(claim.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {claim.data.response === 'Decline' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500">
              Declined
            </div>
            <div className="flex text-sm text-cs-gray-500">
              {claim.data.responseMessage}
            </div>
          </div>
        )}
      </div>
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
