import { useEffect } from 'react';
import useRouter from '../../hooks/useRouter';

export default function Redirect(props) {
  const { to, replace = false } = props;
  const { navigate } = useRouter();

  useEffect(() => {
    if (replace) {
      navigate(to, { replace: true });
    } else {
      navigate(to);
    }
  }, []);

  return null;
};
