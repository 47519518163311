import getQueryUtils from './queryUtils';
import { REQUIRED_EDUCATOR_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import { useConnect } from '@lore/query-connect';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 16
  } = props;

  const queryUtils = getQueryUtils(search, [
    'name',
    'searchEducator',
    'organization',
    'grades',
    'subjects',
    'practices',
    'locationCondition'
  ]);

  return useConnect('user.find', {
    where: {
      eager: {
        $where: {
          $and: [...queryUtils.where],
          isEducator: true,
          ...REQUIRED_EDUCATOR_FIELDS
        },
        // userSubjects: {},
        // userAges: {},
        // userActivityTypes: {},
        // userExpertises: {},
        // subjects: {},
        // ages: {},
        // activityTypes: {},
        // expertises: {},
        // organizations: {}
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
