import React from 'react';
import { useUser } from '@lore/auth';
import { ArchiveBoxIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import BannerTemplate from '../../project-manage/CloseProjectBanner/BannerTemplate';
import BannerActionTemplate from '../../project-manage/CloseProjectBanner/BannerActionTemplate';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function CloseEventBanner(props) {
  const { event } = props;

  const evaluation = useConnect('evaluationEventEducator.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: event.data.creatorId
        }
      }
    }
  });

  const currentUser = useUser();
  const { history } = usePageRouter();

  const launch = useDialogLauncher();

  const isCreator = currentUser.id === event.data.creatorId;

  if (!isCreator) {
    return null;
  }

  function onCloseEvent() {
    launch(DialogConfig.EventClose(event.id));
  }

  function onViewEvaluation() {
    history.push(`/evaluation-event-educator/${evaluation.id}`);
  }

  if (event.data.completed) {
    return (
      <BannerTemplate
        icon={CheckIcon}
        // emoji="🎉"
        title="This event was completed."
        subtitle={(
          <>
            Woohoo! 🎉 You're amazing. Thank you so much. If you haven't completed an
            evaluation (or would like to review your responses) you can do so using the
            link below.
          </>
        )}
        theme="green"
        actions={(
          <>
            <BannerActionTemplate
              label="View evaluation"
              onClick={onViewEvaluation}
              theme="green"
            />
            {currentUser.data.isSuperuser && (
              <BannerActionTemplate
                label="[Change status]"
                onClick={onCloseEvent}
                theme="green"
              />
            )}
          </>
        )}
      />
    );
  }

  if (event.data.cancelled) {
    return (
      <BannerTemplate
        icon={TrashIcon}
        title="This event was cancelled."
        subtitle={(
          <>
            We closed your event and recorded it as cancelled. You'll be able to
            find it in your Past Events for future reference or to duplicate it to
            reuse in the future.
          </>
        )}
        theme="red"
        actions={currentUser.data.isSuperuser && (
          <>
            <BannerActionTemplate
              label="[Change status]"
              onClick={onCloseEvent}
              theme="blue"
            />
          </>
        )}
      />
    );
  }

  return (
    <BannerTemplate
      icon={ArchiveBoxIcon}
      title="Are you ready to close your event?"
      subtitle={(
        <>
          Did you complete it? Tell us how it went! Do you need to cancel it? No
          problem! Just let us know and we'll remove it from search results and
          let partners know.
        </>
      )}
      theme="blue"
      actions={(
        <>
          <BannerActionTemplate
            label="Close event"
            onClick={onCloseEvent}
            theme="blue"
          />
        </>
      )}
      // actions={(
      //   <div className="flex justify-between w-full">
      //     <BannerActionTemplate
      //       label="Yes, this event is done!"
      //       // onClick={onViewEvaluation}
      //       theme="blue"
      //     />
      //     <BannerActionTemplate
      //       label="No, I won't be able to complete this event."
      //       // onClick={onViewEvaluation}
      //       theme="blue"
      //     />
      //   </div>
      // )}
    />
  );
}
