import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import Header from './Header';
import Jobs from './Jobs';
import EmptyState from '../../../components/EmptyState/EmptyState.legacy';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

Form.propTypes = {
  job: PropTypes.object,
  organization: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    jobs,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const launch = useDialogLauncher();

  function onCreateOrganization() {
    launch(DialogConfig.OrganizationCreate());
  }

  const [data, setData] = useState({
    // nothing
  });
  const [showErrors, setShowErrors] = useState(false);

  function onSubmit() {
    onNext(data);
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                // title="What's your school or organization?"
                // subtitle="Locations you create are visible only to you"
                body={(
                  <>
                    <Header />
                    <Jobs jobs={jobs}>
                      <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onCreateOrganization}>
                        <EmptyState
                          title="Add organization"
                        />
                      </div>
                    </Jobs>
                    {/*{state.data.name && (*/}
                    {/*  <div className="bg-cs-yellow-50 border-l-4 border-cs-yellow-400 p-4 mt-12">*/}
                    {/*    <div className="flex">*/}
                    {/*      <div className="flex-shrink-0">*/}
                    {/*        <ExclamationTriangleIcon className="h-5 w-5 text-cs-yellow-400" aria-hidden="true" />*/}
                    {/*      </div>*/}
                    {/*      <div className="ml-3">*/}
                    {/*        <p className="text-sm text-cs-yellow-700 mb-0">*/}
                    {/*          Not affiliated with {state.data.name}?{' '}*/}
                    {/*          <span className="font-medium underline cursor-pointer text-cs-yellow-700 hover:text-cs-yellow-600">*/}
                    {/*            Remove affiliation.*/}
                    {/*          </span>*/}
                    {/*        </p>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
