import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../profile/ProfileLayout';
import User from '../user/User';

export default function(props) {
  const currentUser = useUser();

  return (
    <ParentLayout page="View">
      <div className="page">
        <User user={currentUser} />
      </div>
    </ParentLayout>
  );
};
