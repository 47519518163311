import React, { useState } from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Projects from './Projects';
import Questions from './Questions';
import ProtectedButton from '../../components/Button/ProtectedButton';
import { SolidButton } from '../../components/Button';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

function Header(props) {
  const { currentUser, children } = props;

  const launch = useDialogLauncher();

  function onChooseProjectEvent() {
    launch(DialogConfig.SelectorProjectEvent());
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Your Projects</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <div className="text-3xl font-semibold text-cs-gray-900">
          Your Projects
        </div>
        {currentUser.data.isEducator && (
          <ProtectedButton
            button={SolidButton}
            label="Create New"
            onClick={onChooseProjectEvent}
          />
        )}
      </div>
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          'project.creatorId': {
            $not: currentUser.id
          },
          'project.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: {
            userId: currentUser.id,
            creatorId: currentUser.id
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (
    projects.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING
  ) {
    return (
      <Header currentUser={currentUser}>
        <Loader className="mx-7" />
      </Header>
    );
  }

  return (
    <Header currentUser={currentUser}>
      {currentUser.data.isEducator && (
        <div className="space-y-8">
          <Projects type="expired" />
          <Projects type="active" />
          <Projects type="draft" />
          <Projects type="past" />
        </div>
      )}
      {!currentUser.data.isEducator && (
        <div className="space-y-8">
          <Questions type="invited" />
          <Questions type="active" />
          <Questions type="past" />
        </div>
      )}
    </Header>
  );
}
