import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

DiscoverBanner.propTypes = {
  offer: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired
};

export default function DiscoverBanner(props) {
  const { offer, creator, onClose } = props;

  const [timestamp] = useState(new Date().toISOString());

  const launch = useDialogLauncher();

  function showConnectDialog(onChange) {
    launch(DialogConfig.ClaimDiscover(offer.id));
  }

  if (
    offer.data.endDate &&
    DateTime.fromISO(timestamp).diff(DateTime.fromISO(offer.data.endDate)).milliseconds > 0
  ) {
    return (
      <Wrapper
        creator={creator}
        title="Interested in this Offer?"
        subtitle={(
          <>
            Connect with {creator.data.firstName} to express interest, ask questions, or provide
            feedback on how the offer might be more valuable to you and other educators.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton disabled={true}>
            Window to connect has closed
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      creator={creator}
      title="Interested in this Offer?"
      subtitle={(
        <>
          Connect with {creator.data.firstName} to express interest, ask questions, or provide
          feedback on how the offer might be more valuable to you and other educators.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton onClick={showConnectDialog}>
          Connect
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
