import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Evaluation from './Evaluation';
import ProjectHeader from '../my-projects/Project/Header-new';

export default function EvaluationProjectPartner(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const question = useConnect('question.first', {
    where: {
      eager: {
        $where: {
          projectId: evaluation.data.projectId,
          userId: evaluation.data.userId
        }
      }
    }
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    question.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <Evaluation
      to={!evaluation.data.completed ? `/evaluation-project-partner/${evaluation.id}` : undefined}
      evaluation={evaluation}
      resource={project}
      type="Project"
      result={question.data.result}
    >
      <ProjectHeader
        project={project}
        type="question"
        showTags={false}
      />
    </Evaluation>
  );
}
