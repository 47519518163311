import React from 'react';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function Demographics(props) {
  const { user } = props;

  const { history } = useRouter();

  return (
    <div className="grid grid-cols-2 gap-4 space-y-4x">
      <DataCard
        title="Gender identity"
        subtitle={user.data.gender || '(not provided)'}
        onClick={() => history.push(Urls(user).DEMOGRAPHICS)}
      />
      <DataCard
        title="Ethnicity"
        subtitle={user.data.ethnicity || '(not provided)'}
        onClick={() => history.push(Urls(user).DEMOGRAPHICS)}
      />
      <DataCard
        title="Year of birth"
        subtitle={user.data.yearOfBirth || '(not provided)'}
        onClick={() => history.push(Urls(user).DEMOGRAPHICS)}
      />
    </div>
  );
};
