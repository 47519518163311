import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../EditLayout';
import Form from './Form';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const currentUser = useUser();

  return (
    <ParentLayout
      page="Experience"
      title="What life experiences can you share?"
      description={(
        <>
          Educators are frequently looking to connect learners with the opportunity
          to build the following skill sets. Think about all parts of yourself and
          your life that have shaped who you are today.
        </>
      )}
    >
      <Form
        user={currentUser}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={16}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        // onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        // onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(currentUser).PROFILE_EDIT)}
      />
    </ParentLayout>
  );
};
