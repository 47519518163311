import React from 'react';
import SectionHeader from '../../components_profiles/SectionHeader';

export default function SectionTitle(props) {
  const { title, subtitle, children } = props;

  return (
    <SectionHeader
      title={title || children}
      subtitle={subtitle}
    />
  );

  return (
    <h2 className="font-semibold text-xl mb-4">
      {children}
    </h2>
  );
}
