import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { UseQueryConnect, useQueryMutation } from '../../../../hooks/@lore/query-connect';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import Role from './Role';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import EmptyState from '../../../components/EmptyState/EmptyState.legacy';

Sites.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Sites(props) {
  const {
    project,
    roles,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(project.data, [
    'siteId'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit(site) {
    if (site) {
      mutation.mutate({
        siteId: site.id
      });
      setData({
        siteId: site.id
      })
    } else {
      onSkip();
    }
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  const launch = useDialogLauncher();

  function onCreateSite() {
    launch(DialogConfig.SiteCreate());
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onPrevious={onPrevious}
              navigation={navigation}
              // title="Step 1: Share Your Idea"
              // subtitle="Subtitle"
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <>
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 gap-3">
                      {roles.data.map(function(role) {
                        return (
                          <UseQueryConnect key={role.id} _key="site.byId" params={{ id: role.data.siteId }}>
                            {function(site) {
                              return (
                                <Role
                                  role={role}
                                  onClick={(site) => onSubmit(site)}
                                />
                              );
                            }}
                          </UseQueryConnect>
                        );
                      })}
                    </div>
                    <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onCreateSite}>
                      <EmptyState
                        title="Add new school/site"
                      />
                    </div>
                  </div>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
