import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Event from '../my-events/Event';
import SectionTitle from './SectionTitle';
import EmptyEvent from '../my-events/EmptyEvent';
import getBucketParams from '../my-events/getBucketParams';

export default function Events(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = getBucketParams(timestamp);

  const title = 'Your Events';
  const subtitle = undefined; //'Events you created that are in active or draft state.';
  const link = (
    <Link to="/my-events" className="text-cs-blue-500 text-lg">
      View all
    </Link>
  );

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: [
            { ...params.active },
            { ...params.draft }
          ]
        }
      }
    },
    pagination: {
      pageSize: 2,
      order: 'createdAt desc'
    }
  });

  if (events.state === PayloadStates.FETCHING) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (
    currentUser.data.isEducator &&
    events.data.length === 0
  ) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div className="space-y-4">
          <EmptyEvent />
        </div>
      </div>
    );
  }

  if (events.data.length > 0) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            {events.data.map(function(event, index) {
              if (event.state === PayloadStates.FETCHING) {
                return (
                  <Loader key={index}/>
                );
              }

              return (
                <Event
                  key={index}
                  event={event}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return null;
}
