import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

CreatorBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function CreatorBanner(props) {
  const { project, creator, onClose } = props;

  const launch = useDialogLauncher();

  function onViewMessage() {
    launch(DialogConfig.ProjectManage(project.id, '/manage'));
    // onClose && onClose();
  }

  function onFindPartners() {
    launch(DialogConfig.ProjectManage(project.id, '/invite-populate'));
    // onClose && onClose();
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          Your project is active!
        </>
      )}
      subtitle={(
        <>
          Go find Partners to invite or message those who have already responded.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onViewMessage}>
          Go to messages
        </ConnectButton>
        <ConnectButton onClick={onFindPartners}>
          Find Partners
        </ConnectButton>
        {/*<ConnectButton disabled={true}>*/}
        {/*  This is you*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
