import React from 'react';
import { DateTime } from 'luxon';
import HiddenTag from '../../../_components/HiddenTag';
import MissingFieldsTag from '../../../_components/MissingFieldsTag';
import CancelledTag from '../../../_components/CancelledTag';
import CompletedTag from '../../../_components/CompletedTag';
import ExpiredTag from '../../../_components/ExpiredTag';
import useMissingFields from '../../../event-manage/useMissingFields';

export default function StatusTags(props) {
  const { event } = props;

  const { missingFields } = useMissingFields(event);

  if (event.data.cancelled) {
    return (
      <div className="flex space-x-4">
        <CancelledTag resource={event} />
        {!event.data.published && (
          <HiddenTag resource={event} />
        )}
      </div>
    );
  }

  if (event.data.completed) {
    return (
      <div className="flex space-x-4">
        <CompletedTag resource={event} />
        {!event.data.published && (
          <HiddenTag resource={event} />
        )}
      </div>
    );
  }

  const diff = DateTime.now().diff(DateTime.fromISO(event.data.date)).as('days');

  if (
    diff >= 0 ||
    !event.data.published ||
    missingFields.data.length > 0
  ) {
    return (
      <div className="flex space-x-4">
        {diff >= 0 && (
          <ExpiredTag resource={event} />
        )}
        {!event.data.published && (
          <HiddenTag resource={event} />
        )}
        {missingFields.data.length > 0 && (
          <MissingFieldsTag resource={event} />
        )}
      </div>
    );
  }

  return null;
}
