import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Evaluation from './Evaluation';
import EventHeader from '../my-events/Event/Header-new';

export default function EvaluationEventPartner(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const invitation = useConnect('invitation.first', {
    where: {
      eager: {
        $where: {
          projectId: evaluation.data.projectId,
          userId: evaluation.data.userId
        }
      }
    }
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  if (
    event.state === PayloadStates.FETCHING ||
    invitation.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <Evaluation
      to={!evaluation.data.completed ? `/evaluation-event-partner/${evaluation.id}` : undefined}
      evaluation={evaluation}
      resource={event}
      type="Event"
      result={invitation.data.result}
    >
      <EventHeader
        event={event}
        type="invitation"
        showTags={false}
      />
    </Evaluation>
  );
}
