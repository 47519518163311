import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        title="Your request to add a missing school has been received."
        subtitle="We will send you an email once the school has been added, or if we need more information."
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={onCancel}>
              Close
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
