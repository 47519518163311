/**
 * This component serves as the root of your application, and should typically be the only
 * component subscribed to the store.
 *
 * It is also a good place to fetch the current user. Once you have configured 'models/currentUser'
 * to fetch the current user (by pointing it to the correct API endpoint) uncomment the commented
 * out code below in order to fetch the user, display a loading experience while they're being
 * fetched, and store the user in the applications context so that components can retrieve it
 * without having to pass it down through props or extract it from the Redux store directly.
 */

import React from 'react';
import { useConnect } from '@lore/query-connect';
import * as Sentry from '@sentry/browser';
import { PayloadStates } from '@lore/utils';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import SentryUser from './SentryUser';
import SentryError from './SentryError';
import GoogleUser from './GoogleUser';
import { UserContext } from '@lore/auth';
import { useConfig } from '@lore/config';
import { DialogProvider } from '@lore/dialogs';
import storage from '../utils/storage';
import isBlacklistedRedirectUrl from '../utils/isBlacklistedRedirectUrl';
import NetworkContext from '../context/NetworkContext';
import useTags from '../hooks/useTags';
import useTagTrees from '../hooks/useTagTrees';
import { Navigate, Outlet } from 'react-router-dom';
import useRouter from '../hooks/useRouter';
import DialogLauncherLayout from '../dialogs-routable/_launcher/Layout';

export default function Master(props) {
  const { history, location } = useRouter();
  const config = useConfig();

  const currentUser = useConnect('currentUser.singleton');
  const networks = useConnect('network.find');
  const tags = useTags();
  const tagTrees = useTagTrees();

  function reportError(error, errorInfo) {
    if (config.raven.enabled) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  if (currentUser.state === PayloadStates.FETCHING) {
    return null;
  }

  if (currentUser.state === PayloadStates.ERROR_FETCHING) {
    if (currentUser.error && currentUser.error.statusCode === 401) {
      const redirectUrl = `${window.location.pathname}${window.location.search}`;

      if (!isBlacklistedRedirectUrl(redirectUrl)) {
        storage.set('redirectUrl', redirectUrl);
      }

      history.push('/logout');
      return null;
    }

    reportError(new Error('Master:err'), currentUser);

    return (
      <div>
        <RemoveLoadingScreen />
        <SentryError />
      </div>
    );
  }

  if (
    networks.state === PayloadStates.FETCHING ||
    tags.state === PayloadStates.FETCHING ||
    tagTrees.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const network = _.find(networks.data, a => a.id === currentUser.data.networkId);

  const networkRedirect = !currentUser.data.networkId && !(
    location.pathname === '/setup/network' ||
    location.pathname === '/setup/unavailable' ||
    location.pathname === '/setup/thanks'
  );

  const profileRedirect = currentUser.data.isEducator === null && !(
    location.pathname === '/setup/network' ||
    location.pathname === '/setup/unavailable' ||
    location.pathname === '/setup/thanks' ||
    location.pathname === '/setup/profile'
  );

  const inactiveRedirect = currentUser.data.inactive && !(
    location.pathname === '/inactive'
  );

  if (inactiveRedirect) {
    return (
      <Navigate to="/inactive" />
    );
  }

  return (
    <UserContext.Provider value={currentUser}>
      <NetworkContext.Provider value={network}>
        <RemoveLoadingScreen />
        <SentryUser />
        <GoogleUser />
        <DialogProvider
          templates={config.dialogs.templates}
          defaultTemplate={config.dialogs.defaultTemplate}
        >
          {networkRedirect ? (
            <Navigate to="/setup/network" />
          ) : profileRedirect ? (
            <Navigate to="/setup/profile" />
          ) : (
            <Outlet />
          )}
        </DialogProvider>
        <DialogLauncherLayout />
      </NetworkContext.Provider>
    </UserContext.Provider>
  );
};
