import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const user = useUser();

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Avatar</title>
      </Helmet>
      <Form
        user={user}
        navigation={user.data.isEducator ?
          <Navigation activeStep={10} steps={10}/> :
          <Navigation activeStep={11} steps={12}/>
        }
        onPrevious={(newData) => user.data.isEducator ?
          history.push(Urls.CONTACT) :
          history.push(Urls.CONTACT)
        }
        onNext={(newData) => user.data.isEducator ?
          history.push(Urls.END_VIEW_PROFILE(user)) :
          history.push(Urls.SOCIAL)
        }
        onSkip={() => history.push(Urls.END)}
      />
    </ParentLayout>
  );
};
