import React from 'react';
import PropTypes from 'prop-types';
import FormTemplate from '../../dialogs_common/OnboardingTemplate';
import SolidButton from '../../components/Button/SolidButton';
import { CheckIcon } from '@heroicons/react/24/outline';
import Card from '../../components/Card';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';

ChangeEmail.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function ChangeEmail(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  function onShow() {
    launch(DialogConfig.ChangeEmail());
  }

  return (
    <Card>
      <FormTemplate
        className="w-full"
        title="Would you like to change your email?"
        subtitle="This is what you use to log in and where we send notifications."
        // alert={error ? (
        //   <RequestError request={request} />
        // ) : showErrors ? (
        //   <ErrorMessage>
        //     Please fill in all required fields.
        //   </ErrorMessage>
        // ) : null}
        body={(
          <>
            <div className="p-6 rounded-lg border border-cs-gray-200 mb-4 bg-cs-gray-100">
              <div className="flex flex-row justify-between">
                <div className="text-base font-semibold text-cs-gray-900">
                  Email
                </div>
                <div className="flex flex-row space-x-1 items-center text-green-600">
                  <CheckIcon className="h-5 w-5" />
                  <span>
                    Verified
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <span className="text-base text-cs-gray-500">
                  {user.data.email}
                </span>
              </div>
            </div>
          </>
        )}
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={onShow}>
              Change Email
            </SolidButton>
          </div>
        )}
      />
    </Card>
  );
};
