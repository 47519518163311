import React, { useEffect } from 'react';
import { useUser } from '@lore/auth';
import { Link } from 'react-router-dom';
import SolidButton from '../../components/Button/SolidButton';
import BubbleLayout from './BubbleLayout';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import useRouter from '../../hooks/useRouter';

export default function Create(props) {
  const { history } = useRouter();

  const user = useUser();

  const mutation = useQueryMutation('event.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      history.push(`/post-event/${request.id}`)
    }
  }, [success]);

  function onCreateEvent() {
    mutation.mutate({
      creatorId: user.id
    });
  }

  return (
    <BubbleLayout>
      <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
        <div className="text-3xl font-semibold text-cs-gray-900 text-center mb-4">
          Create an Event!
        </div>
        <div className="text-base text-cs-gray-700 text-center space-y-2 mb-12">
          <div>
            We'll help you think through your idea so you can share it with the community.
          </div>
          <div>
            The more info you share, the better your potential matches.
          </div>
          <div>
            You can always edit the details later, so don't worry if it's not perfect!
          </div>
        </div>
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton disabled={saving} onClick={onCreateEvent}>
            Let's get started!
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 font-semibold" to="/my-events">
            Cancel
          </Link>
        </div>
      </div>
    </BubbleLayout>
  )
};
