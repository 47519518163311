import React, { useState } from 'react';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Question from '../my-projects/Question';
import SectionTitle from './SectionTitle';
import ProjectHeader from '../my-projects/Project/Header-new';

export default function QuestionsConnected(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const title = 'Your Projects';
  const link = (
    <Link to="/my-projects" className="text-cs-blue-500 text-lg">
      View all
    </Link>
  );

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          'project.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          userId: currentUser.id,
          response: 'Connect',
          'project.completed': false,
          'project.cancelled': false
        }
      }
    },
    pagination: {
      pageSize: 2,
      order: 'createdAt desc'
    }
  });

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <div>
        <SectionTitle
          title={title}
          link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (questions.data.length === 0) {
    return null;
  }

  return (
    <div>
      <SectionTitle
        title={title}
        link={link}
      />
      <div className="space-y-4">
        <div className="grid grid-cols-1 gap-4">
          {questions.data.map(function(question, index) {
            return (
              <UseQueryConnect key={index} _key="project.byId" params={{ id: question.data.projectId }}>
                {function(project) {
                  if (project.state === PayloadStates.FETCHING) {
                    return (
                      <Loader />
                    );
                  }

                  return (
                    <Question
                      question={question}
                      alignChildren="top"
                    >
                      <ProjectHeader
                        project={project}
                        type="question"
                      />
                    </Question>
                  );
                }}
              </UseQueryConnect>
            );
          })}
        </div>
      </div>
    </div>
  );
}
