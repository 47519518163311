import React from 'react';
import { useConnect } from '@lore/query-connect';
import { Helmet } from 'react-helmet-async';
import { useUser } from '@lore/auth';
import SubheaderButton from '../../components_deprecated/SubheaderButton';
import Loader from '../../components/Loader';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import User from './User';
import PrimaryHeader from '../../components/PrimaryHeader';
import { PencilIcon } from '@heroicons/react/24/outline';

function Header(props) {
  const { user, title, header, children } = props;

  return (
    <>
      <Helmet>
        <title>{title || 'People'}</title>
      </Helmet>
      {header && (
        <PrimaryHeader title={title || 'People'} />
      )}
      {children}
    </>
  );
}

export default function Layout(props) {
  const { project, event, offer, header = true } = props;
  const { history, match } = useRouter();

  const userId = Number(match.params.userId);

  const currentUser = useUser();
  const requestedUser = useConnect('user.byId', {
    id: userId
  });
  const user = userId === currentUser.id ? currentUser : requestedUser;

  if (user.state === PayloadStates.FETCHING) {
    return (
      <Header header={header} title="...">
        <div className="container page">
          <Loader />
        </div>
      </Header>
    );
  }

  return (
    <Header header={header} title={user.data.isEducator ? 'Educators' : 'Partners'}>
      <div className="container page">
        <Helmet>
          <title>{`${user.data.firstName} ${user.data.lastName}`.trim() || `User #${user.id}`}</title>
        </Helmet>
        <div className="mb-12 flex justify-between">
          <SubheaderButton onClick={() => history.length > 1 ? history.goBack() : history.push(user.data.isEducator ? '/educators' : '/partners')} />
          {(currentUser.id === user.id) && (
            <SubheaderButton
              icon={PencilIcon}
              label="Edit my profile"
              onClick={() => history.push('/profile/edit')}
            />
          )}
        </div>
        <User
          user={user}
          project={project}
          event={event}
          offer={offer}
        />
        {/*<div className="flex">*/}
        {/*  <div className="w-1/4"/>*/}
        {/*  <div id="projects" className="w-3/4 pl-12">*/}
        {/*    {user.data.isEducator ? (*/}
        {/*      <Projects user={user} />*/}
        {/*    ) : (*/}
        {/*      <ProjectUsers user={user} />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Header>
  );
}
