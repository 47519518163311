import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Offer from '../offer/Offer';

export default function(props) {
  const { offer } = props;

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Offer offer={offer} />
  );
};
