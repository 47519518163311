import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Avatar from '../../../components/Avatar';

Claims.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Claims(props) {
  const { offer } = props;

  const MAX_CONVERSATIONS = 7;

  const responses = [
    'Connect',
    'Pending'
  ];

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: {
            $in: responses
          }
        }
      }
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <div>
        <div className="text-sm text-cs-gray-500 font-semibold uppercase mb-2">
          Messages
        </div>
        <Loader/>
      </div>
    );
  }

  function Circle(props) {
    return (
      <div className="h-8 w-8 bg-cs-orange-200 rounded-full text-cs-gray-900 flex items-center text-sm justify-center">
        {props.children}
      </div>
    )
  }

  return (
    <div>
      <div className="text-sm text-cs-gray-500 font-semibold uppercase mb-2">
        Messages
      </div>
      <div className="flex space-x-2">
        {claims.data.length === 0 && (
          <div className="flex items-center space-x-2">
            <Circle>
              0
            </Circle>
            <span className="text-sm text-cs-gray-500">
              No messages yet
            </span>
          </div>
        )}
        {claims.data.slice(0,MAX_CONVERSATIONS).map(function(claim, index) {
          return (
            <UseQueryConnect key={index} _key="user.byId" params={{ id: claim.data.userId }}>
              {function(user) {
                return (
                  <Avatar
                    size={8}
                    src={user.data.avatarUrl}
                  />
                );
              }}
            </UseQueryConnect>
          );
        })}
        {claims.data.slice(MAX_CONVERSATIONS).length > 0 && (
          <Circle>
            +{claims.data.slice(MAX_CONVERSATIONS).length}
          </Circle>
        )}
      </div>
    </div>
  );
}
