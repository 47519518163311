import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import ProtectedButton from '../../../components/Button/ProtectedButton';
import SolidButton from '../../../components/Button/SolidButton';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';

export default function ProtectedCreateButton(props) {
  const launch = useDialogLauncher();

  function onChooseProjectEvent() {
    launch(DialogConfig.SelectorProjectEvent());
  }

  return (
    <ProtectedButton
      button={SolidButton}
      icon={PlusIcon}
      label="Create new"
      onClick={onChooseProjectEvent}
    />
  );
}
