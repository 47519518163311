import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import validators from '../../../utils/validators';
import FormTemplate from '../../../dialogs_common/OnboardingTemplate';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../../components/Button';
import RadioButtonField from './RadioButtonField';
import DateRangeField from './DateRangeField';
import SleepAlert from '../SleepAlert';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import Card from '../../../components/Card';
import FormTemplateButtonFooter from '../../../dialogs_common/FormTemplateButtonFooter';

const AWAY_MESSAGE_MAX_LENGTH = 1000;

export default function Sleeping(props) {
  const { user } = props;

  const currentUser = useUser();

  const [timestamp] = useState(DateTime.now().toISO());
  const isSleeping = DateTime.fromISO(user.data.sleepingEndingAt).diff(DateTime.fromISO(timestamp)).milliseconds > 0;

  const [data, setData] = useState({
    sleepingStartingAt: isSleeping ? user.data.sleepingStartingAt : new Date().toISOString(),
    sleepingEndingAt: isSleeping ? user.data.sleepingEndingAt : new Date().toISOString(),
    sleepingBy: currentUser.id,
    interval: isSleeping ? -1 : 0,
    awayMessage: user.data.awayMessage || `Thank you for reaching out to me. I am currently away from CommunityShare, so can't connect at this time.`,
    allowMessagesWhileAway: user.data.allowMessagesWhileAway || false,
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      // onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(_.omit(data, [
      'interval'
    ]));
  }

  function wakeUpProfile() {
    const timestamp = new Date().toISOString();

    setData(_.merge({}, data, {
      sleepingStartingAt: timestamp,
      sleepingEndingAt: timestamp
    }));

    mutation.mutate({
      sleepingStartingAt: timestamp,
        sleepingEndingAt: timestamp
    });
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onChangeMultiple(values) {
    setData(_.merge({}, data, values));
  }

  return (
    <Card>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          awayMessage: [
            // validators.isRequired,
            validators.maxLength(AWAY_MESSAGE_MAX_LENGTH)
          ]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                title="Going away for a while?"
                subtitle="We know life gets busy and sometimes even takes you to far off lands. If you plan to be temporarily unavailable to participate in CommunityShare, you can set your profile to ‘sleep'. This will keep your account alive, but will make your profile hidden so people won't be able to find and contact you. You can also set up an ‘away' message that will automatically be sent to folks you have an pre-existing conversation with, just so they know you have not totally disappeared!"
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    {DateTime.now().diff(DateTime.fromISO(user.data.sleepingStartingAt)).milliseconds < 0 && (
                      <SleepAlert heading={`Your profile will go to sleep on ${DateTime.fromISO(user.data.sleepingStartingAt).toLocaleString(DateTime.DATE_MED)}.`}>
                        <div className="mt-8">
                          <OutlineButton onClick={wakeUpProfile}>
                            Prevent profile from sleeping
                          </OutlineButton>
                        </div>
                      </SleepAlert>
                    )}
                    {(
                        DateTime.now().diff(DateTime.fromISO(user.data.sleepingStartingAt)).milliseconds > 0 &&
                        DateTime.now().diff(DateTime.fromISO(user.data.sleepingEndingAt)).milliseconds < 0
                    ) && (
                      <SleepAlert heading="Your profile is currently asleep.">
                        <div className="text-cs-gray-500">
                          <p>
                            It will wake up on {DateTime.fromISO(user.data.sleepingEndingAt).toLocaleString(DateTime.DATE_MED)}.
                          </p>
                          <SolidButton onClick={wakeUpProfile}>
                            Wake up profile now
                          </SolidButton>
                        </div>
                      </SleepAlert>
                    )}
                    <FormField name="interval" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            label="Sleep my profile for..."
                            value={field.value}
                            error={field.error}
                            placeholder="Select a role..."
                            options={{
                              data: [
                                [3, '3 months'],
                                [6, '6 months'],
                                [12, '1 year'],
                                [-1, 'Custom date range']
                              ].map((option) => {
                                return {
                                  id: option[0],
                                  data: {
                                    name: option[1]
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            onChange={(value) => {
                              if (value > 0) {
                                onChangeMultiple({
                                  sleepingStartingAt: new Date().toISOString(),
                                  sleepingEndingAt: DateTime.now().plus({ months: value }).toISO(),
                                  interval: value
                                })
                              } else {
                                field.onChange(value)
                              }
                            }}
                          />
                          {field.value === -1 && (
                            <DateRangeField
                              key={`${data.sleepingStartingAt}${data.sleepingEndingAt}`}
                              label="Put my profile to sleep between..."
                              startDate={data.sleepingStartingAt}
                              endDate={data.sleepingEndingAt}
                              error={showErrors && (errors.sleepingStartingAt || errors.sleepingEndingAt)}
                              onStartDateChange={(value) => {
                                return onChange('sleepingStartingAt', value);
                              }}
                              onEndDateChange={(value) => {
                                return onChange('sleepingEndingAt', value);
                              }}
                            />
                          )}
                        </>
                      )}
                    </FormField>
                  </>
                )}
                footer={(
                  <FormTemplateButtonFooter>
                    <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Save
                    </SolidButton>
                  </FormTemplateButtonFooter>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </Card>
  );
};
