import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Project from '../project/Project';

export default function(props) {
  const { project } = props;

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Project project={project} />
  );
};
