import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

MessageProject.propTypes = {
  message: PropTypes.object.isRequired
};

export default function MessageProject(props) {
  const { message } = props;

  const launch = useDialogLauncher();

  const project = useConnect('project.byId', {
    id: message.data.projectId
  }, {
    enabled: !!message.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  if (
    !message.data.projectId ||
    project.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  function onClick() {
    launch(DialogConfig.Project(project.id));
  }

  return (
    <span>
      Related to <span className="text-cs-orange-500 cursor-pointer hover:text-cs-yellow-500" onClick={onClick}>{project.data.title || activity.data.name}</span> (Project)
    </span>
  );
}
