import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import TagCheckboxCards from '../_common/TagCheckboxCards';

UserTagCards.propTypes = {
  userTags: PropTypes.object
};

export default function UserTagCards(props) {
  const {
    user,
    tags,
    userTags,
    selectable
  } = props;

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleTag(tag) {
    const userTag = _.find(userTags.data, function(userTag) {
      return userTag._tag.id === tag.id;
    });

    if (!userTag) {
      mutation_create.mutate({
        userId: user.id,
        tagId: tag.id
      }, {
        onSuccess: payload => userTags._add(payload)
      });
    } else {
      mutation_destroy.mutate(userTag._tagNode, {
        onSuccess: payload => userTags._remove(payload)
      });
    }
  }

  function onCreateUserTag(tag, userTag) {
    launch(DialogConfig.TagNodeCreate(tag.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserTag(tag, userTag) {
    launch(DialogConfig.TagNodeUpdate(userTag._tagNode.id));
  }

  function onDestroyUserTag(tag, userTag) {
    launch(DialogConfig.TagNodeDestroy(userTag._tagNode.id));
  }

  return (
    <Overlay isVisible={saving}>
      <TagCheckboxCards
        tags={tags}
        facadeTagNodes={userTags}
        onChange={function(tag, facadeTagNode) {
          if (facadeTagNode?._tagNode.data.description) {
            return onDestroyUserTag(tag, facadeTagNode);
          }

          return onToggleTag(tag);
        }}
        onUpdateDescription={onUpdateUserTag}
        selectable={selectable}
      />
    </Overlay>
  );
}
