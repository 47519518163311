import React from 'react';
import Partners from './Partners';
import Organization from './Organization';
import Date from './Date';
import Creator from './Creator';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import Slots from './Slots';
import Filled from './Filled';
import classNames from 'classnames';

export default function Header(props) {
  const { event, children, type = 'event', onClick, showTags = true } = props;

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {showTags && (
          <StatusTags event={event} />
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(event) : undefined}
        >
          <Title event={event} />
          <Description event={event} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <Date event={event} />
          {type === 'event' && (
            <>
              <Slots event={event} />
              <Filled event={event} />
            </>
          )}
          {type === 'invitation' && (
            <Creator event={event} />
          )}
          <Organization event={event} />
        </div>
        {type === 'event' && (
          <Partners event={event} />
        )}
      </div>
    </div>
  );
}
