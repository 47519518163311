import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

PendingBanner.propTypes = {
  offer: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired
};

export default function PendingBanner(props) {
  const { offer, claim, creator, onClose } = props;

  const launch = useDialogLauncher();

  function showInterestedDialog(onChange) {
    launch(DialogConfig.ClaimConnect(claim.id));
  }

  function showDeclineDialog(onChange) {
    launch(DialogConfig.ClaimDecline(claim.id));
  }

  return (
    <Wrapper
      title="Interested in this Offer?"
      subtitle={(
        <>
          Connect with {creator.data.firstName} to express interest, ask questions, or provide
          feedback on how the offer might be more valuable to you and other educators.
        </>
      )}
      creator={creator}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showDeclineDialog}>
          Sorry, this doesn't work for me
        </ConnectButton>
        <ConnectButton onClick={showInterestedDialog}>
          I'm interested!
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
