import React from 'react';
import { CalendarIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
// import CloseProjectButton from './CloseProjectButton';
import MissingFields from './MissingFields';
// import Visibility from './Visibility';

export default function Header(props) {
  const currentUser = useUser();

  return (
    <div className="flex flex-col px-8 pt-3 pb-6 bg-white space-y-6">
      <div className="space-y-1">
        <div className="flex items-center justify-between">
          <div className="text-3xl font-semibold text-cs-gray-900 leading-tight max-w-3xl">
            {currentUser.data.firstName} {currentUser.data.lastName}
          </div>
          {/*<CloseProjectButton*/}
          {/*  project={project}*/}
          {/*/>*/}
        </div>
        <div className="flex space-x-4">
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <EnvelopeIcon className="h-6 w-6" />
            <span>
              {currentUser.data.email}
            </span>
          </div>
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <CalendarIcon className="h-6 w-6" />
            <span>
              Joined {DateTime.fromISO(currentUser.data.createdAt).toLocaleString(DateTime.DATE_MED)}
            </span>
          </div>
          {/*<Visibility project={project} />*/}
        </div>
      </div>
      <MissingFields user={currentUser} />
    </div>
  );
};
