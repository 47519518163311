import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Entries from './Entries';
import Overlay from '../../../../forms/_common/Overlay';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';
import useTags from '../../../../hooks/useTags';
import TagTypes from '../../../../constants/TagTypes';
import useTagTrees from '../../../../hooks/useTagTrees';
import { useQueryMutation } from '../../../../../hooks/@lore/query-connect';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import DisclosureFilter from '../../../../forms/_fields_v2/DisclosureFilter';
import LabelDivider from './LabelDivider';

Expertises.propTypes = {
  userExpertises: PropTypes.object
};

export default function Expertises(props) {
  const {
    user,
    expertiseTypes,
    userExpertises,
    showDialogs = false
  } = props;

  const launch = useDialogLauncher();

  const expertises = useTags(TagTypes.Expertise);
  const tagTrees = useTagTrees();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onCreate(tag, node) {
    launch(DialogConfig.TagNodeCreate(tag.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdate(tag, node) {
    launch(DialogConfig.TagNodeUpdate(node._tagNode.id));
  }

  function onDestroy(tag, node) {
    launch(DialogConfig.TagNodeDestroy(node._tagNode.id));
  }

  function onSelect(expertise) {
    const userExpertise = _.find(userExpertises.data, function(userExpertise) {
      return userExpertise._tag.id === expertise.id;
    });

    if (!userExpertise) {
      if (showDialogs) {
        onCreate(expertise, userExpertise);
      } else {
        mutation_create.mutate({
          userId: user.id,
          tagId: expertise.id
        }, {
          onSuccess: payload => userExpertises._add(payload)
        });
      }
    } else {
      if (showDialogs) {
        onDestroy(expertise, userExpertise);
      } else {
        mutation_destroy.mutate(userExpertise._tagNode, {
          onSuccess: payload => userExpertises._remove(payload)
        });
      }
    }
  }

  if (expertises.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  function renderCategory(category) {
    const countArray = _.remove(userExpertises.data.map(function(userExpertise) {
      return userExpertise._parentTag.id === category.id;
    }))

    return (
      <DisclosureFilter
        key={category.id}
        label={category.data.name}
        value={countArray}
      >
        <Overlay isVisible={saving}>
          <div className="-mx-4">
            <Entries
              user={user}
              expertiseType={category}
              userExpertises={userExpertises}
              onSelect={onSelect}
              onDestroy={onDestroy}
              onUpdate={onUpdate}
            />
          </div>
        </Overlay>
      </DisclosureFilter>
    );
  }

  const groupedCategories = _.groupBy(expertiseTypes.data, datum => datum.data.name[0]);

  const categories = _.mapValues(groupedCategories, function(value, key) {
    return (
      <React.Fragment key={key}>
        <LabelDivider label={key} />
        {value.map(renderCategory)}
      </React.Fragment>
    );
  });

  return (
    <div className="flex flex-col">
      <div className="w-full space-y-4">
        {_.reduce(categories, function(result, value, key) {
          result.push(value);
          return result;
        }, [])}
      </div>
    </div>
  );
}
