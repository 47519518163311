import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import IconSection from '../../_new_card_components/IconSection';

Distance.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Distance(props) {
  const { offer } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const organization = useConnect('organization.byId', {
    id: offer.data.organizationId
  }, {
    enabled: !!offer.data.organizationId
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    (offer.data.organizationId && organization.state === PayloadStates.FETCHING)
  ) {
    return (
      <IconSection
        icon={MapPinIcon}
        title="..."
      />
    );
  }

  const distance = getDistanceFromLatLonInMiles(
    offer.data.organizationId ? organization.data : creator.data,
    currentUser.data
  );

  return (
    <IconSection
      icon={MapPinIcon}
      title={distance <= 1 ? '1 mile away' : `${distance} miles away`}
    />
  );
}
