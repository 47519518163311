import React from 'react';
import Partners from './Partners';
import Organization from './Organization';
import DateRange from './DateRange';
import Creator from './Creator';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import classNames from 'classnames';

export default function Header(props) {
  const { project, children, type = 'project', onClick, showTags = true } = props;

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {showTags && (
          <StatusTags project={project} />
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(project) : undefined}
        >
          <Title project={project} />
          <Description project={project} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <DateRange project={project} />
          {type === 'project' && (
            <Creator project={project} />
          )}
          <Organization project={project} />
        </div>
        {type === 'project' && (
          <Partners project={project} />
        )}
      </div>
    </div>
  );
}
