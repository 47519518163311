import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ActionBar from '../project-manage/ActionBar';
import Header from '../project-manage/Header';
import ProjectViewLayout from '../project-view/Dialog';
import ProjectEditLayout from '../project-edit/Dialog';
import ProjectManageLayout from '../project-manage/Dialog';
import ProjectInvitePopulateLayout from '../project-invite-populate/Dialog';
import ProjectInviteLayout from '../project-invite/Dialog';
import useDialogConfig from '../../dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../dialogs-routable/_hooks/useDialogRouter';

export default function ProjectManageDialogLayout(props) {
  const { project } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/manage');
    }
  }, []);

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/manage': 'Manage',
    '/view': 'View',
    '/edit': 'Edit',
    '/invite-populate': 'Invite',
    '/invite': 'Invite',
    '/messages': 'Messages'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {project.data.title ? `${project.data.title}: ${page}` : `Project #${project.id}: ${page}`}
        </title>
      </Helmet>
      <Header project={project} />
      <div className="-mx-6">
        <ActionBar project={project} />
      </div>
      <div className="space-y-12">
        {pathname === `/view` && (
          <ProjectViewLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/edit` && (
          <ProjectEditLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite-populate` && (
          <ProjectInvitePopulateLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite` && (
          <ProjectInviteLayout
            project={project}
            router={router}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/manage` && (
          <ProjectManageLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {/*{pathname === `/messages` && (*/}
        {/*  <ProjectMessagesLayout*/}
        {/*    project={project}*/}
        {/*    onClose={props.onCancel}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </div>
  );
};
