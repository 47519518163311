import React from 'react';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import Loader from '../../components/Loader';
import classNames from 'classnames';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';

export default function ConnectButton(props) {
  const {
    children,
    onClick,
    disabled = false,
    gray = false,
    outline = false,
    color = 'accent'
  } = props;

  const missingFields = useUserProfileMissingFields();

  const launch = useDialogLauncher();

  const colorMap = {
    text: {
      accent: 'text-cs-orange-500',
      primary: 'text-cs-yellow-500',
      blue: 'text-cs-blue-500',
      green: 'text-cs-green-500',
      purple: 'text-cs-purple-500',
      gray: 'text-cs-gray-500'
    }
  };

  if (!missingFields) {
    return (
      <Loader />
    );
  }

  const fieldCount = missingFields.profile.length + missingFields.interests.length;

  function onCompleteProfile() {
    launch(DialogConfig.ProfileMissing());
  }

  const className = outline ? classNames(
    'font-semibold text-lgx rounded-lg text-center py-3 px-6 w-56 min-w-fit',
    '-m-px border border-white',
    'text-white',
    disabled && 'cursor-not-allowed opacity-80',
    !disabled && 'cursor-pointer hover:bg-white hover:bg-opacity-10 shadow-lgx'
  ) : classNames(
    // 'bg-white font-semibold uppercase rounded-lg text-center py-3 px-4 w-56 min-w-fit',
    'bg-white font-semibold text-lgx rounded-lg text-center py-3 px-6 w-56 min-w-fit',
    gray ? colorMap.text['gray'] : colorMap.text[color] || 'text-cs-orange-500',
    disabled && 'cursor-not-allowed opacity-80',
    !disabled && 'cursor-pointer hover:bg-cs-orange-50x hover:bg-opacity-90 shadow-lgx'
  );

  if (disabled) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }

  if (fieldCount > 0) {
    return (
      <div className={className} onClick={onCompleteProfile}>
        {children}
      </div>
    );
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};
