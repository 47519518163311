import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import { useConnect } from '@lore/query-connect';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import { useUser } from '@lore/auth';

Invitation.propTypes = {
  invitation: PropTypes.object.isRequired
};

export default function Invitation(props) {
  const { invitation, selected } = props;

  const currentUser = useUser();

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const recipient = useConnect('user.byId', {
    id: invitation.data.userId
  });

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  }, {
    enabled: !!event.data.creatorId
  });

  const invitationCreator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  if (
    event.state === PayloadStates.FETCHING ||
    recipient.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    invitationCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const user = currentUser.id === event.data.creatorId ?
    recipient :
    creator;

  const isStewardGenerated = (
    invitationCreator.id !== event.data.creatorId &&
    invitation.data.userId !== invitation.data.creatorId
  );

  return (
    <div className={classNames(
      `flex flex-col rounded-lg p-4x w-full relative`,
      'bg-white shadow',
      'hover:bg-cs-orange-50'
    )}>
      {isStewardGenerated && (
        <div className="px-4 py-1 bg-cs-orange-100">
          <span className="text-sm text-cs-gray-500">
            Connection made by {invitationCreator.data.firstName} {invitationCreator.data.lastName} (Steward)
          </span>
        </div>
      )}
      <div className={classNames(
        `flex p-4`
      )}>
        <Link to={`/event/${invitation.data.eventId}/messages/${user.id}`} className="absolute h-full w-full" />
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={10} src={user.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              {DateTime.fromISO(invitation.data.updatedAt).toRelative()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
