import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import PendingBanner from './PendingBanner';
import YesBanner from './YesBanner';
import MaybeButtons from './MaybeBanner';
import NoBanner from './NoBanner';
import LoadingBanner from '../../../_banner/LoadingBanner';

ConnectBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function ConnectBanner(props) {
  const { event, onClose } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          $and: {
            eventId: event.id,
            userId: currentUser.id
          }
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === event.data.creatorId;
  const invitation = invitations.data[0];

  if (isCreator) {
    return (
      <CreatorBanner
        event={event}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (!invitation) {
    return (
      <DiscoverBanner
        event={event}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (invitation.data.response === 'Pending') {
    return (
      <PendingBanner
        event={event}
        invitation={invitation}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (invitation.data.response === 'Yes') {
    return (
      <YesBanner
        event={event}
        creator={creator}
        invitation={invitation}
        onClose={onClose}
      />
    );
  }

  if (invitation.data.response === 'Maybe') {
    return (
      <MaybeButtons
        event={event}
        creator={creator}
        invitation={invitation}
        onClose={onClose}
      />
    );
  }

  if (invitation.data.response === 'No') {
    return (
      <NoBanner
        event={event}
        creator={creator}
        invitation={invitation}
        onClose={onClose}
      />
    );
  }

  return null;
}
