import React from 'react';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

export default function ConnectBanner(props) {
  const { claim, creator, onClose } = props;

  const launch = useDialogLauncher();

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          You connected over this offer!
        </>
      )}
      subtitle={(
        <>
          Go to your messages to keep moving the conversation moving forward.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {/*<ConnectButton onClick={() => history.push(to)}>*/}
        {/*  Start evaluation {counts}*/}
        {/*</ConnectButton>*/}
        <ConnectButton color="green" onClick={() => {
          // history.push(`/offer/${claim.data.offerId}/messages`);
          // onClose && onClose();
          launch(DialogConfig.Messages(creator.id, { offerId: claim.data.offerId }))
        }}>
          Go to messages
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
