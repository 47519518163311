import React, { useEffect } from 'react';
import { useUser } from '@lore/auth';
import { Link } from 'react-router-dom';
import SolidButton from '../../components/Button/SolidButton';
import BubbleLayout from './BubbleLayout';
import Tooltip from '../_post-opportunity/forms/Tooltip';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import useRouter from '../../hooks/useRouter';

export default function Create(props) {
  const { history } = useRouter();

  const user = useUser();

  const mutation = useQueryMutation('offer.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      history.push(`/post-offer/${request.id}`)
    }
  }, [success]);

  function onCreateOffer() {
    mutation.mutate({
      creatorId: user.id
    });
  }

  return (
    <BubbleLayout>
      <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
        <div className="text-3xl font-semibold text-cs-gray-900 text-center mb-4">
          Create an Offer
        </div>
        <div className="text-base text-cs-gray-700 text-center space-y-2 mb-12">
          <div>
            An Offer is the best way for you - as a Partner - to share lesson/activity
            ideas, programs, and events that you or your organization can offer Educators.
          </div>
          <div className="flex items-center justify-center space-x-1">
            <span className="font-semibold">TIP:</span>
            <Tooltip icon={false} tooltip={(
              <div className="text-sm text-cs-gray-700 text-left space-y-2 max-w-md">
                <div>
                  A <span className="font-semibold">Project</span> is created by Educators who want
                  to collaborate with a Partner to create an idea, activity or event they have
                  in mind.
                </div>
                <div>
                  An <span className="font-semibold">Offer</span> is created by Partners to share
                  existing materials, resources, or programs that they or their organization
                  can offer to Educators.
                </div>
                <div>
                  Offers are often (but not always) formal educational outreach programs
                  offered by you or your organization.
                </div>
              </div>
            )}>
              <div className="text-cs-blue-500 font-semibold underline cursor-default">
                What's the difference between an Offer and a Project?
              </div>
            </Tooltip>
          </div>
          <div>
            We'll guide you through a few steps to help you set up your Offer.
          </div>
        </div>
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton disabled={saving} onClick={onCreateOffer}>
            Let's get started!
          </SolidButton>
          <div className="text-base text-cs-gray-700 space-x-1">
            <span>Still confused?</span>
            <Link className="text-cs-orange-500 hover:text-cs-yellow-500 font-semibold underline" to="/offers">
              See what others are offering.
            </Link>
          </div>
        </div>
      </div>
    </BubbleLayout>
  )
};
