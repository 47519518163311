import React from 'react';
import _ from 'lodash';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import CloseEventButton from './CloseEventButton';
import CloseEventBanner from './CloseEventBanner';
import MissingFields from './MissingFields';
import Visibility from './Visibility';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import useEventTags from '../../hooks/useEventTags';
import { formatDate, formatDateRange, formatTimeRange } from '../../utils/dateTimeUtils';

export default function Header(props) {
  const { event } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const isCreator = currentUser.id === event.data.creatorId;

  const times = useTags(TagTypes.Time);
  const eventTimes = useEventTags(event, TagTypes.Time);

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-1">
        <div className="flex items-center justify-between">
          <div className="text-3xl font-semibold text-cs-gray-900 leading-tight max-w-3xl">
            {event.data.title || activity.data.name || '...'}
          </div>
          {/*<CloseEventButton*/}
          {/*  event={event}*/}
          {/*/>*/}
        </div>
        <div className="flex space-x-4">
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <CalendarIcon className="h-6 w-6" />
            {event.data.specificDate === 'Yes' ? (
              <span>
                {formatDate(event.data.date)}
              </span>
            ) : (
              <span>
                {formatDateRange(event.data.startDate, event.data.endDate)}
              </span>
            )}
          </div>
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <ClockIcon className="h-6 w-6" />
            {event.data.specificTime === 'Yes' ? (
              <span>
                {formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone)}
              </span>
            ) : (
              <>
                {eventTimes.state === PayloadStates.FETCHING || times.state === PayloadStates.FETCHING ? (
                  <span>
                    ...
                  </span>
                ) : (
                  <span>
                    {eventTimes.data.map(function(eventTime) {
                      const time = _.find(times.data, model => model.id === eventTime.data.timeId);

                      return time.data.name;
                    }).join(', ')}
                  </span>
                )}
              </>
            )}
          </div>
          {isCreator && (
            <Visibility event={event} />
          )}
        </div>
      </div>
      {isCreator && (
        <>
          <CloseEventBanner event={event} />
          <MissingFields event={event} />
        </>
      )}
    </div>
  );
};
