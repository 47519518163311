import React from 'react';
import { Helmet } from 'react-helmet-async';
import Stories from './Stories';
import Partners from './Partners';
import Educators from './Educators';

export default function Layout(props) {
  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Your Bookmarks</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Your Bookmarks
      </div>
      <div className="space-y-10">
        <Partners />
        <Stories />
        <Educators />
      </div>
    </div>
  );
};
