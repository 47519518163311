import React from 'react';
import { useUser } from '@lore/auth';
import { ArchiveBoxIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import BannerTemplate from './BannerTemplate';
import BannerActionTemplate from './BannerActionTemplate';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function CloseProjectBanner(props) {
  const { project } = props;

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: project.data.creatorId
        }
      }
    }
  });

  const currentUser = useUser();
  const { history } = usePageRouter();

  const launch = useDialogLauncher();

  const isCreator = currentUser.id === project.data.creatorId;

  if (!isCreator) {
    return null;
  }

  function onCloseProject() {
    launch(DialogConfig.ProjectClose(project.id));
  }

  function onViewEvaluation() {
    history.push(`/evaluation-project-educator/${evaluation.id}`);
  }

  if (project.data.completed) {
    return (
      <BannerTemplate
        icon={CheckIcon}
        // emoji="🎉"
        title="This project was completed."
        subtitle={(
          <>
            Woohoo! 🎉 You're amazing. Thank you so much. If you haven't completed an
            evaluation (or would like to review your responses) you can do so using the
            link below.
          </>
        )}
        theme="green"
        actions={(
          <>
            {evaluation.state === PayloadStates.NOT_FOUND ? (
              <BannerActionTemplate
                label="[Evaluation missing]"
                theme="green"
              />
            ) : (
              <BannerActionTemplate
                label="View evaluation"
                onClick={onViewEvaluation}
                theme="green"
              />
            )}
            {currentUser.data.isSuperuser && (
              <BannerActionTemplate
                label="[Change status]"
                onClick={onCloseProject}
                theme="green"
              />
            )}
          </>
        )}
      />
    );
  }

  if (project.data.cancelled) {
    return (
      <BannerTemplate
        icon={TrashIcon}
        title="This project was cancelled."
        subtitle={(
          <>
            We closed your project and recorded it as cancelled. You'll be able to
            find it in your Past Projects for future reference or to duplicate it to
            reuse in the future.
          </>
        )}
        theme="red"
        actions={currentUser.data.isSuperuser && (
          <>
            <BannerActionTemplate
              label="[Change status]"
              onClick={onCloseProject}
              theme="blue"
            />
          </>
        )}
      />
    );
  }

  return (
    <BannerTemplate
      icon={ArchiveBoxIcon}
      title="Are you ready to close your project?"
      subtitle={(
        <>
          Did you complete it? Tell us how it went! Do you need to cancel it? No
          problem! Just let us know and we'll remove it from search results and
          let partners know.
        </>
      )}
      theme="blue"
      actions={(
        <>
          <BannerActionTemplate
            label="Close project"
            onClick={onCloseProject}
            theme="blue"
          />
        </>
      )}
      // actions={(
      //   <div className="flex justify-between w-full">
      //     <BannerActionTemplate
      //       label="Yes, this project is done!"
      //       // onClick={onViewEvaluation}
      //       theme="blue"
      //     />
      //     <BannerActionTemplate
      //       label="No, I won't be able to complete this project."
      //       // onClick={onViewEvaluation}
      //       theme="blue"
      //     />
      //   </div>
      // )}
    />
  );
}
