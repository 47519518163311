import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import useRouter from '../../../../hooks/useRouter';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

CreatorBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function CreatorBanner(props) {
  const { offer, creator, onClose } = props;

  const { history } = useRouter();
  const launch = useDialogLauncher();

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          Your offer is active!
        </>
      )}
      subtitle={(
        <>
          Go find Educators to invite or message those who have already responded.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => {
          // history.push(`/offer/${offer.id}/messages`);
          // onClose && onClose();
          launch(DialogConfig.OfferManage(offer.id, '/manage'))
        }}>
          Go to messages
        </ConnectButton>
        <ConnectButton onClick={() => {
          // history.push(`/offer/${offer.id}/invite-populate`);
          // onClose && onClose();
          launch(DialogConfig.OfferManage(offer.id, '/invite-populate'))
        }}>
          Find Educators
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
