import React from 'react';
import PropTypes from 'prop-types';

WelcomeBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default function WelcomeBanner(props) {
  const { title, subtitle, children } = props;

  return (
    <div>
      <div className="text-3xl font-semibold text-cs-gray-900 mb-1">
        {title}
      </div>
      <div className="text-lg text-cs-gray-700 mb-12">
        {subtitle}
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};
