import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import CloseProjectButton from './CloseProjectButton';
import CloseProjectBanner from './CloseProjectBanner';
import MissingFields from './MissingFields';
import Visibility from './Visibility';
import { formatDate, formatDateRange } from '../../utils/dateTimeUtils';

export default function Header(props) {
  const { project } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const isCreator = currentUser.id === project.data.creatorId;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-1">
        <div className="flex items-center justify-between">
          <div className="text-3xl font-semibold text-cs-gray-900 leading-tight max-w-3xl">
            {project.data.title || activity.data.name || '...'}
          </div>
          {/*<CloseProjectButton*/}
          {/*  project={project}*/}
          {/*/>*/}
        </div>
        <div className="flex space-x-4">
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <CalendarIcon className="h-6 w-6" />
            {project.data.specificDate === 'Yes' ? (
              <span>
                {formatDate(project.data.date, currentUser.data.timezone)}
              </span>
            ) : (
              <span>
                {formatDateRange(project.data.startDate, project.data.endDate, currentUser.data.timezone)}
              </span>
            )}
          </div>
          {isCreator && (
            <Visibility project={project} />
          )}
        </div>
      </div>
      {isCreator && (
        <>
          <CloseProjectBanner project={project} />
          <MissingFields project={project} />
        </>
      )}
    </div>
  );
};
