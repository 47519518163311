import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { useUser } from '@lore/auth';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

InviteRedirectDialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function InviteRedirectDialog(props) {
  const { user } = props;

  const currentUser = useUser();

  function onSubmit() {
    props.onCancel();
    props.onSubmit && props.onSubmit();
  }

  if (
    user.data.isEducator &&
    !currentUser.data.isEducator
  ) {
    return (
      <DialogTemplate size="medium">
        <FormTemplate
          title="Looks like you want to connect with an educator."
          // subtitle="Before you can do that you need to first create a request, and then you can invite partners from inside that request."
          onClose={props.onCancel}
          body={(
            <>
              <p>
                That's great! The first step is to create an Offer that describes what you're
                looking for, and then you'll be able to invite educators to participate.
              </p>
              <p>
                Would you like to create an Offer now?
              </p>
            </>
          )}
          footer={(
            <FormTemplateButtonFooter>
              <OutlineButton className="mr-4" onClick={props.onCancel}>
                Cancel
              </OutlineButton>
              <SolidButton onClick={onSubmit}>
                Yes, I want to create an Offer
              </SolidButton>
            </FormTemplateButtonFooter>
          )}
        />
      </DialogTemplate>
    );
  }

  if (
    user.data.isEducator &&
    currentUser.data.isEducator
  ) {
    return (
      <DialogTemplate size="medium">
        <FormTemplate
          title="You can't connect with Educators at this time."
          // subtitle="Before you can do that you need to first create a request, and then you can invite partners from inside that request."
          onClose={props.onCancel}
          body={(
            <div>
              <p>
                Currently only Partners can connect with Educators. We are exploring how we might support
                Educator-to-Educator connections, but we do not offer a way to do that at this time.
              </p>
              <p>
                Consider reaching out to this educator via email for now.
              </p>
            </div>
          )}
          footer={(
            <FormTemplateButtonFooter>
              <OutlineButton onClick={props.onCancel}>
                Close
              </OutlineButton>
            </FormTemplateButtonFooter>
          )}
        />
      </DialogTemplate>
    );
  }

  if (
    !user.data.isEducator &&
    !currentUser.data.isEducator
  ) {
    return (
      <DialogTemplate size="medium">
        <FormTemplate
          title="You can't connect with Partners at this time."
          // subtitle="Before you can do that you need to first create a request, and then you can invite partners from inside that request."
          onClose={props.onCancel}
          body={(
            <>
              <p>
                Currently only Educators can connect with Partners. We are exploring how we might support
                Partner-to-Partner connections, but we do not offer a way to do that at this time.
              </p>
            </>
          )}
          footer={(
            <FormTemplateButtonFooter>
              <OutlineButton onClick={props.onCancel}>
                Close
              </OutlineButton>
            </FormTemplateButtonFooter>
          )}
        />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate size="medium">
      <FormTemplate
        title="Looks like you want to connect with a partner."
        // subtitle="Before you can do that you need to first create a request, and then you can invite partners from inside that request."
        onClose={props.onCancel}
        body={(
          <>
            <p>
              That's great! The first step is to create a Project that describes what you're
              looking for, and then you'll be able to invite partners to collaborate.
            </p>
            <p>
              Would you like to create a Project now?
            </p>
          </>
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={props.onCancel}>
              Cancel
            </OutlineButton>
            <SolidButton onClick={onSubmit}>
              Yes, I want to create a Project
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    </DialogTemplate>
  );
};
