import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import SelectField from '../../../forms/_fields_v2/SelectField';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'gender',
    'ethnicity',
    'yearOfBirth',
    'showDemographics'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // firstName: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                title="Your demographic story"
                subtitle={(
                  <div className="space-y-2">
                    <div>
                      As part of our commitment to diversity, equity, and inclusion, we're seeking to
                      better understand the rich diversity of backgrounds of our CommunityShare partners
                      and educators.
                    </div>
                    <div>
                      Your privacy is our priority. Any information you share will be kept private and
                      secure. We will not share your information unless you give permission to include
                      this information on your profile by clicking the checkbox below.
                    </div>
                    <div>
                      You can change this information in the future by revisiting your CommunityShare profile.
                    </div>
                  </div>
                )}
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div className="flex flex-col">
                    <div className="w-full sm:w-80 space-y-6 mb-12">
                      <FormField name="gender" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <SelectField
                            label="Gender identity"
                            value={field.value}
                            error={field.error}
                            placeholder="Select a value..."
                            options={{
                              data: [
                                'Man',
                                'Woman',
                                'Agender',
                                'Gender Fluid',
                                'Gender Non-conforming',
                                'Genderqueer',
                                'Non-binary',
                                'Questioning',
                                'Self Identify Another Option',
                                'Prefer Not to Say'
                              ].map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                      <FormField name="ethnicity" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <SelectField
                            label="Ethnicity"
                            value={field.value}
                            error={field.error}
                            placeholder="Select a value..."
                            options={{
                              data: [
                                'Native American / American Indian or Alaska Native',
                                'Asian or Asian American',
                                'Black or African American',
                                'Hispanic or Latino',
                                'Native Hawaiian or Other Pacific Islander',
                                'White or Caucasian',
                                'Multiracial',
                                'Other',
                                'Prefer Not to Say'
                              ].map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                      <FormField name="yearOfBirth" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <StringField
                            label="Year of birth"
                            value={field.value}
                            error={field.error}
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                      <FormField name="showDemographics" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <CheckboxField
                            label="It is fine to include my demographic information in my profile."
                            checked={field.value}
                            error={field.error}
                            onChange={(event) => field.onChange(event.target.checked)}
                          />
                        )}
                      </FormField>
                    </div>
                  </div>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
