import React from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from '@lore/config';
import useRouter from '../hooks/useRouter';
import classNames from 'classnames';
import useNetwork from '../hooks/useNetwork';

export default function Network(props) {
  const { showName = true } = props;

  const { location } = useRouter();
  const { tenant } = useConfig();

  const network = useNetwork();

  const src = tenant.networkLogo[network.data.subdomain];
  const name = tenant.networkName[network.data.subdomain];

  const isSelected = location.pathname === '/my-network';

  if (src) {
    return (
      <div className={classNames(
        'flex items-center space-x-3',
        !isSelected && 'hover:opacity-60'
      )}>
        <div className={classNames(
          'flex items-center h-14 w-14 overflow-hidden',
          // 'shadow-cs-flat -m-0.5 border-2 rounded-2xl',
          'shadow-cs-flat -m-px border rounded-cs-10',
          isSelected ?
            'border-cs-orange-500 bg-cs-orange-100' :
            'border-cs-gray-200 bg-white'
        )}>
          <img className="h-11 w-11 object-contain m-auto" src={src} alt="logo" />
        </div>
        {showName && (
          <Link to="/my-network" className={classNames(
            'flex-1 text-lg font-semibold',
            isSelected ? 'text-cs-orange-500' : 'text-cs-gray-900'
          )}>
            {name}
          </Link>
        )}
      </div>
    );
  }

  return null;
};
