import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Offer from './Offer';
import SectionTitle from '../my-projects/SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import getBucketParams from './getBucketParams';
import ProtectedLinkButton from '../../components/Button/ProtectedLinkButton';
import { OutlineButton, SolidButton } from '../../components/Button';
import LinkButton from '../../components/Button/LinkButton';

export default function Offers(props) {
  const { type = 'active' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = getBucketParams(timestamp);

  const title = {
    active: 'Active',
    draft: 'Draft',
    past: 'Past'
  };

  const emptyStates = {
    active: (
      <EmptyState
        title="No active offers"
        subtitle="Get started by creating an offer or exploring current offers by partners."
      >
        <EmptyStateActions>
          <LinkButton
            button={OutlineButton}
            to="/offers"
            label="Explore offers"
          />
          <ProtectedLinkButton
            button={SolidButton}
            to="/post-offer"
            label="Create Offer"
          />
        </EmptyStateActions>
      </EmptyState>
    ),
    draft: (
      <EmptyState
        title="No draft offers"
        subtitle="Offers that are missing required fields will be shown here."
      />
    ),
    past: (
      <EmptyState
        title="No past offers"
        subtitle={(
          <>
            Offers that are no longer active will appear here, as well as any offers
            that were completed or cancelled.
          </>
        )}
      />
    )
  };

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (offers.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (offers.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {offers.data.map(function(offer, index) {
          if (offer.state === PayloadStates.FETCHING) {
            return (
              <Loader key={index} />
            );
          }

          return (
            <Offer
              key={index}
              offer={offer}
            />
          );
        })}
      </div>
    </div>
  );
}
