import React from 'react';
import { Helmet } from 'react-helmet-async';
import { stringify } from 'query-string';
import Redirect from '../project-messages/Redirect';
import { useUser } from '@lore/auth';

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Projects</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Explore Projects
      </div>
      <Redirect
        to={{
          pathname: '/projects',
          search: stringify({
            networks: [currentUser.data.networkId],
            locationCondition: JSON.stringify({
              address: currentUser.data.zipCode,
              latitude: currentUser.data.latitude,
              longitude: currentUser.data.longitude,
              radius: 50
            })
          })
        }}
        replace={true}
      />
    </div>
  );
}
