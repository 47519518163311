import React from 'react';
import _ from 'lodash';
import { LinkIcon, MagnifyingGlassIcon, PencilIcon, UsersIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import ActionBarMenu from './ActionBarMenu';
import useDialogConfig from '../../dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../dialogs-routable/_hooks/useDialogRouter';

export default function ActionBar(props) {
  const { event, invitation } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);

  const links = _.remove([
    {
      icon: LinkIcon,
      label: 'View Event',
      to: `/view`
    },
    {
      icon: PencilIcon,
      label: 'Edit Event',
      to: `/edit`
    },
    {
      icon: MagnifyingGlassIcon,
      label: 'Find Partners',
      to: `/invite-populate`
    },
    {
      icon: UsersIcon,
      label: 'Manage Partners',
      to: `/manage`,
    },
    // {
    //   icon: EnvelopeIcon,
    //   label: 'Send Message',
    //   to: `/messages`
    // }
  ], entry => entry);

  return (
    <div className="flex items-center justify-center px-6 py-4 bg-cs-gray-100x border-t border-b border-cs-gray-100">
      <div className="flex flex-wrap gap-4 justify-center">
        {links.map(function(link, index) {
          const active = (
            router.location.pathname.indexOf(link.to) === 0 ||
            link.to.indexOf(router.location.pathname) === 0
          );

          return (
            <div
              key={index}
              // to={link.to}
              onClick={() => router.navigate(link.to)}
              className={classNames(
                'flex space-x-2 items-center',
                // 'px-4 py-3 rounded-lg cursor-pointer',
                'px-3 py-2.5 cursor-pointer',
                'rounded-lg',
                // 'rounded-lg',
                // 'shadow-lg',
                // 'shadow-cs-flat',
                active ? '-m-px border border-cs-orange-500': 'x-m-0.5 xborder-2 border-cs-gray-100',
                'text-sm',
                active ?
                  'text-cs-orange-500 bg-cs-orange-50' :
                  'text-cs-gray-500 bg-white hover:bg-cs-gray-50'
              )}
            >
              <link.icon className="h-5 w-5" />
              <span>
                {link.label}
              </span>
            </div>
          );
        })}
        <ActionBarMenu
          event={event}
          invitation={invitation}
        />
      </div>
    </div>
  );
};
