import React from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';

export default function Slots(props) {
  const { event } = props;

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
      <UsersIcon className="h-6 w-6" />
      <span>
        {event.data.slots}
      </span>
    </div>
  );
};
