import React from 'react';
import { getDialogArray } from '../_hooks/launch';
import DialogTemplate from '../../_dialog_test/DialogTemplate';
import dialogLauncherMap from './dialogLauncherMap';
import { DialogConfigContext } from '../_hooks/DialogConfigContext';
import _ from 'lodash';

export default function build(removeDialogFromSearchParams) {
  const dialogArray = getDialogArray();

  // console.log(`_launcher/Layout:builder - ${dialogArray.length}`);
  //
  // if (dialogArray.length > 0) {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const result = JSON.parse(searchParams.get('dialogs'));
  //   console.log(result);
  // }

  return _.reduce(_.reverse(dialogArray), function(previousDialog, dialogConfig, index) {
    const Launcher = dialogLauncherMap[dialogConfig.type];

    function onClose() {
      // console.log(`onClose:${dialogConfig.type}`);
      removeDialogFromSearchParams(dialogConfig.id);
    }

    return (
      <DialogTemplate
        // key={dialogConfig.id}
        dialog={function(props) {
          return (
            <DialogConfigContext.Provider value={dialogConfig}>
              <Launcher onClose={props.onClose} />
            </DialogConfigContext.Provider>
          )
        }}
        childDialog={previousDialog}
        options={dialogConfig.options}
        afterLeave={onClose}
        // afterEnter={() => console.log('afterEnter')}
      />
    );
  }, null);
};
