import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const user = useUser();

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - ZIP Code</title>
      </Helmet>
      <Form
        user={user}
        navigation={user.data.isEducator ?
          <Navigation activeStep={3} steps={10}/> :
          <Navigation activeStep={3} steps={12}/>
        }
        onPrevious={(newData) => history.push(Urls.NAME)}
        onNext={(newData) => user.data.isEducator ?
          history.push(Urls.SCHOOL) :
          history.push(Urls.ORGANIZATION)
        }
        onSkip={() => user.data.isEducator ?
          history.push(Urls.SCHOOL) :
          history.push(Urls.ORGANIZATION)
        }
      />
    </ParentLayout>
  );
};
