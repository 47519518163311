import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import SelectField from '../../../forms/_fields_v2/SelectField';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'zipCode',
    'timezone'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          zipCode: [
            validators.isRequired,
            validators.isZipCode
          ],
          timezone: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                title={`Welcome, ${user.data.firstName}!`}
                // subtitle="Locations you create are visible only to you"
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div className="flex flex-col">
                    <div className="mb-6">
                      <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
                        Where are you located?
                      </h5>
                      <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">
                        This helps us find people and activities near you and show the right times for your location.
                      </h6>
                    </div>
                    <div className="w-full sm:w-80 mb-6">
                      <FormField name="zipCode" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <StringField
                            label="ZIP Code"
                            required={true}
                            // description="This will help us identify opportunities near you."
                            value={field.value}
                            error={field.error}
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                    </div>
                    <div>
                      <FormField name="timezone" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <SelectField
                            label="Timezone"
                            required={true}
                            // description="This will help us display dates and times in a way that is meaningful to you."
                            value={field.value}
                            error={field.error}
                            placeholder="Select a timezone..."
                            options={{
                              data: [
                                ['America/New_York',               '(GMT-04:00) Eastern Time'], // (GMT-05:00) Eastern Time - New York
                                // ['America/Detroit',                '(GMT-05:00) Eastern Time - Detroit'],
                                // ['America/Kentucky/Louisville',    '(GMT-05:00) Eastern Time - Louisville'],
                                // ['America/Kentucky/Monticello',    '(GMT-05:00) Eastern Time - Monticello, Kentucky'],
                                // ['America/Indiana/Indianapolis',   '(GMT-05:00) Eastern Time - Indianapolis'],
                                // ['America/Indiana/Vincennes',      '(GMT-05:00) Eastern Time - Vincennes, Indiana'],
                                // ['America/Indiana/Winamac',        '(GMT-05:00) Eastern Time - Winamac, Indiana'],
                                // ['America/Indiana/Marengo',        '(GMT-05:00) Eastern Time - Marengo, Indiana'],
                                // ['America/Indiana/Petersburg',     '(GMT-05:00) Eastern Time - Petersburg, Indiana'],
                                // ['America/Indiana/Vevay',          '(GMT-05:00) Eastern Time - Vevay, Indiana'],
                                ['America/Chicago',                '(GMT-05:00) Central Time'], // (GMT-06:00) Central Time - Chicago
                                // ['America/Indiana/Tell_City',      '(GMT-06:00) Central Time - Tell City, Indiana'],
                                // ['America/Indiana/Knox',           '(GMT-06:00) Central Time - Knox, Indiana'],
                                // ['America/Menominee',              '(GMT-06:00) Central Time - Menominee'],
                                // ['America/North_Dakota/Center',    '(GMT-06:00) Central Time - Center, North Dakota'],
                                // ['America/North_Dakota/New_Salem', '(GMT-06:00) Central Time - New Salem, North Dakota'],
                                // ['America/North_Dakota/Beulah',    '(GMT-06:00) Central Time - Beulah, North Dakota'],
                                ['America/Denver',                 '(GMT-06:00) Mountain Time'], // (GMT-07:00) Mountain Time - Denver
                                // ['America/Boise',                  '(GMT-07:00) Mountain Time - Boise'],
                                ['America/Phoenix',                '(GMT-07:00) Arizona'], // (GMT-07:00) Mountain Standard Time - Phoenix
                                ['America/Los_Angeles',            '(GMT-07:00) Pacific Time'], // (GMT-08:00) Pacific Time - Los Angeles
                                ['America/Anchorage',              '(GMT-08:00) Alaska'], // (GMT-09:00) Alaska Time - Anchorage
                                // ['America/Juneau',                 '(GMT-09:00) Alaska Time - Juneau'],
                                // ['America/Sitka',                  '(GMT-09:00) Alaska Time - Sitka'],
                                // ['America/Metlakatla',             '(GMT-09:00) Alaska Time - Metlakatla'],
                                // ['America/Yakutat',                '(GMT-09:00) Alaska Time - Yakutat'],
                                // ['America/Nome',                   '(GMT-09:00) Alaska Time - Nome'],
                                // ['America/Adak',                   '(GMT-10:00) Hawaii-Aleutian Time'],
                                ['Pacific/Honolulu',               '(GMT-10:00) Hawaii'] // (GMT-10:00) Hawaii-Aleutian Standard Time
                              ].map((option) => {
                                return {
                                  id: option[0],
                                  data: {
                                    name: option[1]
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                    </div>
                  </div>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
