import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const practices = useTags(TagTypes.Practice);
  const userPractices = useUserTags(user, TagTypes.Practice);

  if (
    practices.state === PayloadStates.FETCHING ||
    userPractices.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Teaching Practices</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Teaching Practices</title>
      </Helmet>
      <Form
        user={user}
        practices={practices}
        userPractices={userPractices}
        navigation={user.data.isEducator ?
          <Navigation activeStep={6} steps={10}/> :
          <Navigation activeStep={6} steps={10}/>
        }
        onPrevious={(newData) => history.push(Urls.TEACHING)}
        onNext={(newData) => history.push(Urls.HOPE)}
        onSkip={() => history.push(Urls.HOPE)}
      />
    </ParentLayout>
  );
};
