import React from 'react';

export default function MissingFieldsTag(props) {
  const { resource } = props;

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cs-gray-100 text-cs-gray-900">
      MISSING FIELDS
    </span>
  );
}
