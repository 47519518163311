import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Event from './Event';
import SectionTitle from '../my-projects/SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import ProtectedCreateButton from './EmptyEvent/ProtectedCreateButton';
import getBucketParams from './getBucketParams';
import OutlineButton from '../../components/Button/OutlineButton';
import { LinkButton } from '../../components/Button';

export default function Events(props) {
  const { type = 'active' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = getBucketParams(timestamp);

  const title = {
    expired: 'Event Check-In',
    active: 'Active',
    draft: 'Draft',
    past: 'Past'
  };

  const subtitle = {
    expired: (
      <div className="space-y-2">
        <div>
          We'd love to hear about your event's progress! Please update your event
          progress (in less than a minute) so we know how to best support you!
        </div>
        <div>
          Finished with your event? That's great! Let's celebrate your achievement together.
        </div>
        <div>
          If finishing your event this year seems challenging, that's okay! Briefly tell us
          why and we'd be happy to support and help you with your next idea.
        </div>
        <div>
          Need a bit more time? No problem! Just update your event deadline and we'll make
          sure community partners can still see it.
        </div>
      </div>
    )
  };

  const emptyStates = {
    active: (
      <EmptyState
        title="No active events"
        subtitle="Get started by creating a event or exploring current events by educators."
      >
        <EmptyStateActions>
          <LinkButton
            button={OutlineButton}
            to="/events"
            label="Explore events"
          />
          <ProtectedCreateButton />
        </EmptyStateActions>
      </EmptyState>
    ),
    draft: (
      <EmptyState
        title="No draft events"
        subtitle="Events that are missing required fields will be shown here."
      />
    ),
    past: (
      <EmptyState
        title="No past events"
        subtitle={(
          <>
            Events that are no longer active will appear here, as well as any events
            that were completed or cancelled.
          </>
        )}
      />
    )
  };

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $exists: true
          },
          createdAt: {
            $lte: timestamp
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (events.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle subtitle={subtitle[type]}>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (events.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle subtitle={subtitle[type]}>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle subtitle={subtitle[type]}>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {events.data.map(function(event, index) {
          if (event.state === PayloadStates.FETCHING) {
            return (
              <Loader key={index} />
            );
          }

          return (
            <Event
              key={index}
              event={event}
            />
          );
        })}
      </div>
    </div>
  );
}
