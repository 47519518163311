import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getActivityIconMap } from '../../../maps/activityIconMap';
import Card from '../../../components/Card';

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Activity(props) {
  const {
    activity,
    onClick,
    selected = false,
    className
  } = props;

  const iconMap = getActivityIconMap(activity);

  return (
    <Card
      border={false}
      rounded={false}
      background={false}
      className={classNames(
        'flex p-4 space-x-4',
        'rounded-cs-10',
        '-m-px border',
        onClick && 'cursor-pointer hover:bg-cs-orange-50',
        selected ? 'bg-cs-yellow-50 border-cs-yellow-500' : 'border-cs-gray-100 bg-white',
        className
      )}
      onClick={onClick}
    >
      <div>
        <iconMap.icon className="w-8 h-8 text-cs-yellow-500" />
      </div>
      <div className="flex-1">
        <h2 className="text-base font-semibold text-cs-gray-900">
          {activity.data.name}
        </h2>
        <div className="text-sm text-cs-gray-500">
          {activity.data.description}
        </div>
      </div>
    </Card>
  );
}
