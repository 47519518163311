import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import LocationMap from '../../profile/organizations/LocationMap';
import classNames from 'classnames';
import Card from '../../../components/Card';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { MapPinIcon } from '@heroicons/react/24/outline';
import titleCase from '../../../utils/titleCase';

Role.propTypes = {
  role: PropTypes.object.isRequired
};

export default function Role(props) {
  const { role } = props;

  const site = useConnect('site.byId', {
    id: role.data.siteId
  });

  const launch = useDialogLauncher();

  function onUpdateRole() {
    launch(DialogConfig.RoleUpdate(role.id));
  }

  function onDestroyRole() {
    launch(DialogConfig.RoleDestroy(role.id));
  }

  if (site.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const {
    name,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    zipCode
  } = site.data;

  const googleMapsUrl = `https://www.google.com/maps/place/${addressLine1}, ${city}, ${state} ${zipCode}`;

  return (
    <Card
      rounded={false}
      className={classNames(
        'rounded-cs-10',
        'p-4 space-y-3'
      )}
    >
      <div className="w-full h-32 opacity-70 rounded-cs-10 overflow-hidden">
        <LocationMap
          location={site}
          cursor="arrow"
        />
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col w-full space-y-2">
          <div className="flex justify-between">
            <div>
              <div className="text-base font-semibold text-cs-gray-900">
                {site.data.name}
              </div>
              <div className="text-sm text-cs-gray-500">
                {role.data.title}
              </div>
            </div>
            <div className="flex text-sm space-x-1">
              <div
                className="text-cs-blue-500 cursor-pointer hover:underline"
                onClick={() => onUpdateRole(role)}
              >
                Edit
              </div>
              <div className="text-cs-gray-500">
                •
              </div>
              <div
                className="text-cs-blue-500 cursor-pointer hover:underline"
                onClick={() => onDestroyRole(role)}
              >
                Delete
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <MapPinIcon className="h-5 w-5 text-cs-gray-400" aria-hidden="true" />
            <a
              className="text-sm text-cs-gray-500 cursor-pointer underline:text-cs-blue-500 hover:underline"
              href={googleMapsUrl}
              target="_blank"
            >
              {titleCase(site.data.addressLine1)}, {titleCase(site.data.city)}, {site.data.state}, {site.data.zipCode}
            </a>
          </div>
        </div>
      </div>
    </Card>
  );
}
