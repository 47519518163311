import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Project from '../../pages/project/Project'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

ProjectDetailsDialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ProjectDetailsDialog(props) {
  const { project } = props;

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
      <Project
        project={project}
        onClose={props.onCancel}
      />
      </div>
    </DialogTemplate>
  );
};
