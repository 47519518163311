import React from 'react';

export default function CancelledTag(props) {
  const { resource } = props;

  if (!resource.data.cancelled) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
      CANCELLED
    </span>
  );
}
