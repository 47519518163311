import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Timepicker } from 'tw-elements';
// import Timepicker from 'tw-elements/dist/src/js/forms/timepicker';
import { v4 as uuidv4 } from 'uuid';
import Text from '../../../typography';

TimeField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  time: PropTypes.string
};

TimeField.defaultProps = {
  disabled: false
};

export default function TimeField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    time
  } = props;

  const currentUser = useUser();

  // onChange will update (and likely call setState(nextState) but
  // if we don't update the reference used in useEffect we will end
  // up setting data to old values for other fields
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  const [formId] = useState(`f-${uuidv4()}`);

  useEffect(() => {
    const timepicker = document.querySelector(`#${formId}`);
    const timepickerMaxMin = new Timepicker(timepicker, {
      format12: true,
      // inline: true,
      increment: true,
      defaultTime: DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE),
      // defaultTime: time
    });

    timepicker.addEventListener("input.te.timepicker", (input) => {
      const keys = input.target.value.split(' '); // ['01:15', 'AM']
      let [hour, minute] = keys[0].split(':'); // 01, 15
      const offset = keys[1]; // AM or PM

      if (
        hour === '12' &&
        offset === 'AM'
      ) {
        hour = Number(hour) - 12; // 12 AM = 00:00
      } else if (
        hour === '12' &&
        offset === 'PM'
      ) {
        hour = Number(hour); // 12 PM = 12:00
      } else if (offset === 'PM') {
        hour = Number(hour) + 12; // 1 PM = 13:00
      }

      const newDateTime = DateTime.fromISO(time).setZone(currentUser.data.timezone).set({
        hour: Number(hour),
        minute: Number(minute),
        second: 0,
        millisecond: 0
      }).toISO();

      onChangeRef.current(newDateTime);
    });
  }, []);

  return (
    <div
      id={formId}
      // className="relative"
      data-te-timepicker-init
    >
      {label ? (
        <label>
          {label}
        </label>
      ): null}
      <div className="z-0 relative flex items-center">
        <input
          type="text"
          value={DateTime.fromISO(time).setZone(currentUser.data.timezone).toLocaleString(DateTime.TIME_SIMPLE)}
          className="rounded-lg shadow border border-cs-gray-200 w-full px-5 py-5"
          // value={date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) : ''}
          onChange={() => {}}
          // data-te-toggle="timepicker-with-button"
          data-te-toggle={formId}
          data-te-timepicker-toggle-button
          data-te-timepicker-icon
        />
        {/*<input*/}
        {/*  type="text"*/}
        {/*  // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-cs-yellow-500 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-cs-yellow-500 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"*/}
        {/*  id="form1"*/}
        {/*  data-te-toggle="timepicker-with-button"*/}
        {/*  data-te-timepicker-toggle-button*/}
        {/*  data-te-timepicker-icon*/}
        {/*/>*/}
        <ClockIcon className="h-6 w-6 text-cs-gray-900 absolute right-5 z-10"/>
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      )}
      {description && (
        <Text.SmGrayMedium className="mt-2">
          {description}
        </Text.SmGrayMedium>
      )}
    </div>
  );
}
