import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Event from '../event/Event';

export default function(props) {
  const { event } = props;

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Event event={event} />
  );
};
