import React, { useState } from 'react';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Invitation from '../my-events/Invitation';
import SectionTitle from './SectionTitle';
import EventHeader from '../my-events/Event/Header-new';

export default function InvitationsConnected(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const title = 'Your Events';
  const link = (
    <Link to="/my-events" className="text-cs-blue-500 text-lg">
      View all
    </Link>
  );

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          'event.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          userId: currentUser.id,
          response: 'Connect',
          'event.completed': false,
          'event.cancelled': false
        }
      }
    },
    pagination: {
      pageSize: 2,
      order: 'createdAt desc'
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <div>
        <SectionTitle
          title={title}
          link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (invitations.data.length === 0) {
    return null;
  }

  return (
    <div>
      <SectionTitle
        title={title}
        link={link}
      />
      <div className="space-y-4">
        <div className="grid grid-cols-1 gap-4">
          {invitations.data.map(function(invitation, index) {
            return (
              <UseQueryConnect key={index} _key="event.byId" params={{ id: invitation.data.eventId }}>
                {function(event) {
                  if (event.state === PayloadStates.FETCHING) {
                    return (
                      <Loader />
                    );
                  }

                  return (
                    <Invitation
                      invitation={invitation}
                      alignChildren="top"
                    >
                      <EventHeader
                        event={event}
                        type="invitation"
                      />
                    </Invitation>
                  );
                }}
              </UseQueryConnect>
            );
          })}
        </div>
      </div>
    </div>
  );
}
