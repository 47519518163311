import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ConnectButton from './ConnectButton';
import Wrapper from './Wrapper';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

EventBanner.propTypes = {
  user: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
};

export default function EventBanner(props) {
  const {
    user,
    event,
    onClose
  } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          eventId: event.id
        }
      }
    }
  });

  function showInviteDialog() {
    launch(DialogConfig.InvitationInvite(event.id, user.id));
  }

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          ...
        </ConnectButton>
      </Wrapper>
    );
  }

  if (user.id === currentUser.id) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          This is you
        </ConnectButton>
      </Wrapper>
    );
  }

  if (invitations.data.length > 0) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          Invited
        </ConnectButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper user={user}>
      <ConnectButton onClick={showInviteDialog}>
        Invite
      </ConnectButton>
    </Wrapper>
  );
};
