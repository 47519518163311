import React from 'react';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */

import Login from './src/pages/Login';
import LoginClassLink from './src/pages/LoginClassLink';
import Signup from './src/pages/Signup';
import Logout from './src/pages/Logout';
import AuthCallback from './src/pages/AuthCallback';

import SetupNameLayout from './src/pages/setup/name/Layout';
import SetupNetworkLayout from './src/pages/setup/network/Layout';
import SetupUnavailableLayout from './src/pages/setup/unavailable/Layout';
import SetupThanksLayout from './src/pages/setup/thanks/Layout';
import SetupProfileLayout from './src/pages/setup/profile/Layout';
import SetupZipCodeLayout from './src/pages/setup/zip-code/Layout';
import SetupOrganizationLayout from './src/pages/setup/organization/Layout';
import SetupSchoolLayout from './src/pages/setup/school/Layout';
import SetupTeachingLayout from './src/pages/setup/teaching/Layout';
import SetupPracticesLayout from './src/pages/setup/practices/Layout';
import SetupAboutLayout from './src/pages/setup/about/Layout';
import SetupSkillsLayout from './src/pages/setup/skills/Layout';
import SetupInterestsLayout from './src/pages/setup/interests/Layout';
import SetupActivitiesLayout from './src/pages/setup/activities/Layout';
import SetupExperienceLayout from './src/pages/setup/experience/Layout';
import SetupMotivationLayout from './src/pages/setup/motivation/Layout';
import SetupHopeLayout from './src/pages/setup/hope/Layout';
import SetupContactLayout from './src/pages/setup/contact/Layout';
import SetupSocialLayout from './src/pages/setup/social/Layout';
import SetupDemographicsLayout from './src/pages/setup/demographics/Layout';
import SetupAvatarLayout from './src/pages/setup/avatar/Layout';
import SetupEndLayout from './src/pages/setup/end/Layout';

import PostProjectCreateLayout from './src/pages/project-create/Create';
import PostProjectSiteLayout from './src/pages/project-create/site/Layout';
import PostProjectActivityLayout from './src/pages/project-create/activity/Layout';
import PostProjectParticipantsLayout from './src/pages/project-create/participants/Layout';
import PostProjectStudentAgeLayout from './src/pages/project-create/student-age/Layout';
import PostProjectStudentSizeLayout from './src/pages/project-create/student-size/Layout';
import PostProjectStudentCountLayout from './src/pages/project-create/student-count/Layout';
import PostProjectOutcomeLayout from './src/pages/project-create/outcome/Layout';
import PostProjectSummaryLayout from './src/pages/project-create/summary/Layout';
import PostProjectTitleLayout from './src/pages/project-create/title/Layout';
import PostProjectSubjectsLayout from './src/pages/project-create/subjects/Layout';
import PostProjectDemonstrationLayout from './src/pages/project-create/demonstration/Layout';
import PostProjectDateLayout from './src/pages/project-create/date/Layout';
import PostProjectDaysLayout from './src/pages/project-create/days/Layout';
import PostProjectCommitmentLayout from './src/pages/project-create/commitment/Layout';
import PostProjectTimeLayout from './src/pages/project-create/time/Layout';
import PostProjectLocationLayout from './src/pages/project-create/location/Layout';
import PostProjectPartnerLayout from './src/pages/project-create/partner/Layout';
import PostProjectDocumentsLayout from './src/pages/project-create/documents/Layout';
import PostProjectResourcesLayout from './src/pages/project-create/resources/Layout';
import PostProjectGrantLayout from './src/pages/project-create/grant/Layout';
import PostProjectBudgetLayout from './src/pages/project-create/budget/Layout';
import PostProjectSearchExplanationLayout from './src/pages/project-create/search-explanation/Layout';

import PostEventCreateLayout from './src/pages/event-create/Create';
import PostEventSiteLayout from './src/pages/event-create/site/Layout';
import PostEventActivityLayout from './src/pages/event-create/activity/Layout';
import PostEventParticipantsLayout from './src/pages/event-create/participants/Layout';
import PostEventStudentCountLayout from './src/pages/event-create/student-count/Layout';
import PostEventStudentAgeLayout from './src/pages/event-create/student-age/Layout';
import PostEventStudentSizeLayout from './src/pages/event-create/student-size/Layout';
import PostEventSummaryLayout from './src/pages/event-create/summary/Layout';
import PostEventTitleLayout from './src/pages/event-create/title/Layout';
import PostEventSubjectsLayout from './src/pages/event-create/subjects/Layout';
import PostEventDateLayout from './src/pages/event-create/date/Layout';
import PostEventTimeLayout from './src/pages/event-create/time/Layout';
import PostEventLocationLayout from './src/pages/event-create/location/Layout';
import PostEventPartnerLayout from './src/pages/event-create/partner/Layout';
import PostEventDocumentsLayout from './src/pages/event-create/documents/Layout';
import PostEventSlotsLayout from './src/pages/event-create/slots/Layout';
import PostEventSearchExplanationLayout from './src/pages/event-create/search-explanation/Layout';

import PostOfferCreateLayout from './src/pages/offer-create/Create';
import PostOfferActivityLayout from './src/pages/offer-create/activity/Layout';

import PostOfferDescriptionLayout from './src/pages/offer-create/description/Layout';
import PostOfferAudienceLayout from './src/pages/offer-create/audience/Layout';
import PostOfferObjectivesLayout from './src/pages/offer-create/objectives/Layout';
import PostOfferTitleLayout from './src/pages/offer-create/title/Layout';
import PostOfferSubjectsLayout from './src/pages/offer-create/subjects/Layout';
import PostOfferSuppliesLayout from './src/pages/offer-create/supplies/Layout';

import PostOfferParticipantsLayout from './src/pages/offer-create/participants/Layout';
import PostOfferStudentGroupsLayout from './src/pages/offer-create/student-groups/Layout';

import PostOfferCompetenciesLayout from './src/pages/offer-create/competencies/Layout';
import PostOfferDateLayout from './src/pages/offer-create/date/Layout';
import PostOfferTimeLayout from './src/pages/offer-create/time/Layout';
import PostOfferLocationLayout from './src/pages/offer-create/location/Layout';
import PostOfferDesignLayout from './src/pages/offer-create/design/Layout';
import PostOfferDocumentsLayout from './src/pages/offer-create/documents/Layout';
import PostOfferWebsiteLayout from './src/pages/offer-create/website/Layout';
import PostOfferCostLayout from './src/pages/offer-create/cost/Layout';
import PostOfferExplanationLayout from './src/pages/offer-create/explanation/Layout';

import HomeLayout from './src/pages/home/Layout';

import ProjectsLayout from './src/pages/projects/Layout';
import ProjectsPopulateLayout from './src/pages/projects-populate/Layout';
import ProjectLayout from './src/pages/project/Layout';

import EventsLayout from './src/pages/events/Layout';
import EventsPopulateLayout from './src/pages/events-populate/Layout';
import EventLayout from './src/pages/event/Layout';

import OffersLayout from './src/pages/offers/Layout';
import OffersPopulateLayout from './src/pages/offers-populate/Layout';
import OfferLayout from './src/pages/offer/Layout';

import ProjectReviewLayout from './src/pages/project-review/Layout';
import EventReviewLayout from './src/pages/event-review/Layout';
import OfferReviewLayout from './src/pages/offer-review/Layout';

import ProjectManageLayout from './src/pages/project-manage/Layout';
import ProjectViewLayout from './src/pages/project-view/Layout';
import ProjectEditLayout from './src/pages/project-edit/Layout';
import ProjectInvitePopulateLayout from './src/pages/project-invite-populate/Layout';
import ProjectInviteLayout from './src/pages/project-invite/Layout';
import ProjectInviteUserLayout from './src/pages/project-invite-user/Layout';
import ProjectMessagesLayout from './src/pages/project-messages/Layout';
import ProjectMessagesUserLayout from './src/pages/project-messages-user/Layout';

import EventManageLayout from './src/pages/event-manage/Layout';
import EventViewLayout from './src/pages/event-view/Layout';
import EventEditLayout from './src/pages/event-edit/Layout';
import EventInvitePopulateLayout from './src/pages/event-invite-populate/Layout';
import EventInviteLayout from './src/pages/event-invite/Layout';
import EventInviteUserLayout from './src/pages/event-invite-user/Layout';
import EventMessagesLayout from './src/pages/event-messages/Layout';
import EventMessagesUserLayout from './src/pages/event-messages-user/Layout';

import OfferManageLayout from './src/pages/offer-manage/Layout';
import OfferViewLayout from './src/pages/offer-view/Layout';
import OfferEditLayout from './src/pages/offer-edit/Layout';
import OfferInvitePopulateLayout from './src/pages/offer-invite-populate/Layout';
import OfferInviteLayout from './src/pages/offer-invite/Layout';
import OfferInviteUserLayout from './src/pages/offer-invite-user/Layout';
import OfferMessagesLayout from './src/pages/offer-messages/Layout';
import OfferMessagesUserLayout from './src/pages/offer-messages-user/Layout';

import MessagesLayout from './src/pages/messages/Layout';
import MessagesUserLayout from './src/pages/messages-user/Layout';

import UserLayout from './src/pages/user/Layout';

import StoriesLayout from './src/pages/stories/Layout';
import StoryLayout from './src/pages/story/Layout';

import PeopleEducatorsLayout from './src/pages/people-educators/Layout';
import PeopleEducatorsPopulateLayout from './src/pages/people-educators-populate/Layout';
import PeoplePartnersLayout from './src/pages/people-partners/Layout';
import PeoplePartnersPopulateLayout from './src/pages/people-partners-populate/Layout';

import MyProjectsLayout from './src/pages/my-projects/Layout';
import MyEventsLayout from './src/pages/my-events/Layout';
import MyOffersLayout from './src/pages/my-offers/Layout';

import BookmarksLayout from './src/pages/bookmarks/Layout';
import MyNetworkLayout from './src/pages/my-network/Layout';

import EvaluationProjectEducatorStartLayout from './src/pages/evaluation-project-educator/Start/Layout';
import EvaluationProjectEducatorAudienceLayout from './src/pages/evaluation-project-educator/Students/Layout';
import EvaluationProjectEducatorSupportedLayout from './src/pages/evaluation-project-educator/Supported/Layout';
import EvaluationProjectEducatorEngagedLayout from './src/pages/evaluation-project-educator/Engaged/Layout';
import EvaluationProjectEducatorSkillsLayout from './src/pages/evaluation-project-educator/Skills/Layout';
import EvaluationProjectEducatorAwarenessLayout from './src/pages/evaluation-project-educator/Awareness/Layout';
import EvaluationProjectEducatorUnderstandingLayout from './src/pages/evaluation-project-educator/Understanding/Layout';
import EvaluationProjectEducatorPracticedLayout from './src/pages/evaluation-project-educator/Practiced/Layout';
import EvaluationProjectEducatorJourneyLayout from './src/pages/evaluation-project-educator/Journey/Layout';
import EvaluationProjectEducatorAdventuresLayout from './src/pages/evaluation-project-educator/Adventures/Layout';
import EvaluationProjectEducatorChallengesLayout from './src/pages/evaluation-project-educator/Challenges/Layout';
import EvaluationProjectEducatorChangesLayout from './src/pages/evaluation-project-educator/Changes/Layout';
import EvaluationProjectEducatorLessonsLayout from './src/pages/evaluation-project-educator/Lessons/Layout';
import EvaluationProjectEducatorUploadsLayout from './src/pages/evaluation-project-educator/Uploads/Layout';
import EvaluationProjectEducatorRecommendLayout from './src/pages/evaluation-project-educator/Recommend/Layout';
import EvaluationProjectEducatorEndLayout from './src/pages/evaluation-project-educator/End/Layout';

import EvaluationEventEducatorStartLayout from './src/pages/evaluation-event-educator/Start/Layout';
import EvaluationEventEducatorAudienceLayout from './src/pages/evaluation-event-educator/Students/Layout';
import EvaluationEventEducatorEngagedLayout from './src/pages/evaluation-event-educator/Engaged/Layout';
import EvaluationEventEducatorSkillsLayout from './src/pages/evaluation-event-educator/Skills/Layout';
import EvaluationEventEducatorAwarenessLayout from './src/pages/evaluation-event-educator/Awareness/Layout';
import EvaluationEventEducatorUnderstandingLayout from './src/pages/evaluation-event-educator/Understanding/Layout';
import EvaluationEventEducatorPracticedLayout from './src/pages/evaluation-event-educator/Practiced/Layout';
import EvaluationEventEducatorDescriptionLayout from './src/pages/evaluation-event-educator/Description/Layout';
import EvaluationEventEducatorChangesLayout from './src/pages/evaluation-event-educator/Changes/Layout';
import EvaluationEventEducatorUploadsLayout from './src/pages/evaluation-event-educator/Uploads/Layout';
import EvaluationEventEducatorRecommendLayout from './src/pages/evaluation-event-educator/Recommend/Layout';
import EvaluationEventEducatorEndLayout from './src/pages/evaluation-event-educator/End/Layout';

import EvaluationProjectPartnerStartLayout from './src/pages/evaluation-project-partner/Start/Layout';
import EvaluationProjectPartnerInteractionsLayout from './src/pages/evaluation-project-partner/Interactions/Layout';
import EvaluationProjectPartnerHoursLayout from './src/pages/evaluation-project-partner/Hours/Layout';
import EvaluationProjectPartnerContributionLayout from './src/pages/evaluation-project-partner/Contribution/Layout';
import EvaluationProjectPartnerOutcomeLayout from './src/pages/evaluation-project-partner/Outcome/Layout';
import EvaluationProjectPartnerRecommendLayout from './src/pages/evaluation-project-partner/Recommend/Layout';
import EvaluationProjectPartnerEndLayout from './src/pages/evaluation-project-partner/End/Layout';

import EvaluationEventPartnerStartLayout from './src/pages/evaluation-event-partner/Start/Layout';
import EvaluationEventPartnerOutcomeLayout from './src/pages/evaluation-event-partner/Outcome/Layout';
import EvaluationEventPartnerRecommendLayout from './src/pages/evaluation-event-partner/Recommend/Layout';
import EvaluationEventPartnerEndLayout from './src/pages/evaluation-event-partner/End/Layout';

import ProfileViewLayout from './src/pages/profile-view/Layout';
import ProfileEditLayout from './src/pages/profile-edit/Layout';
import ProfileEditLocationLayout from './src/pages/profile/location/Layout';
import ProfileEditSocialLayout from './src/pages/profile/social/Layout';
import ProfileEditGradesLayout from './src/pages/profile/grades/Layout';
import ProfileEditSubjectsLayout from './src/pages/profile/subjects/Layout';
import ProfileEditPracticesLayout from './src/pages/profile/practices/Layout';
import ProfileEditOrganizationsLayout from './src/pages/profile/organizations/Layout';
import ProfileEditSitesLayout from './src/pages/profile/sites/Layout';
import ProfileEditNameLayout from './src/pages/profile/name/Layout';
import ProfileEditAboutLayout from './src/pages/profile/about/Layout';
import ProfileEditAvatarLayout from './src/pages/profile/avatar/Layout';
import ProfileEditDemographicsLayout from './src/pages/profile/demographics/Layout';
import ProfileEditPhoneLayout from './src/pages/profile/phone/Layout';
import ProfileEditOpportunitiesLayout from './src/pages/profile/opportunities/Layout';
import ProfileEditAgesLayout from './src/pages/profile/ages/Layout';
import ProfileEditActivitiesLayout from './src/pages/profile/activities/Layout';
import ProfileEditGroupsLayout from './src/pages/profile/groups/Layout';
import ProfileEditSkillsLayout from './src/pages/profile/skills/Layout';
import ProfileEditExperienceLayout from './src/pages/profile/experiences/Layout';
import ProfileEditGuidanceLayout from './src/pages/profile/guidances/Layout';

import ProfileSettingsLayout from './src/pages/profile-settings/Layout';

import NotAuthorized from './src/pages/unauthorized/Layout';
import Inactive from './src/pages/inactive/Layout';
import NotFoundPage from './src/pages/NotFoundPage';
import RemoveLoadingScreen from './src/pages/RemoveLoadingScreen';

import UserIsAuthenticated from './src/decorators/UserIsAuthenticated';
import Master from './src/pages/Master';
import Layout from './src/pages/Layout';
import FlowLayout from './src/pages/FlowLayout';

/*
 * Utilities for redirecting to dialogs
 */

import { DialogConfig } from './src/dialogs-routable/_launcher/DialogKey';
import { searchParamKey } from './src/dialogs-routable/_hooks/launch';

function generateSearchDialog(dialogArray = []) {
  const searchParams = new URLSearchParams();
  searchParams.set(searchParamKey, JSON.stringify(dialogArray));
  return searchParams.toString();
}

function projectRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.ProjectManage(params.projectId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-projects?${search}`);
  }
}

function eventRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.EventManage(params.eventId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-events?${search}`);
  }
}

function offerRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.OfferManage(params.offerId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-offers?${search}`);
  }
}

function questionRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.QuestionView(params.questionId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-projects?${search}`);
  }
}

function invitationRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.InvitationView(params.invitationId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-events?${search}`);
  }
}

function claimRedirect(route) {
  return function({ params }) {
    const dialogConfig = DialogConfig.ClaimView(params.claimId, route);
    const search = generateSearchDialog([dialogConfig]);
    return redirect(`/my-offers?${search}`);
  }
}

function compositeRedirect(route, dialogArray = []) {
  const search = generateSearchDialog(dialogArray);
  return redirect(`${route}?${search}`);
}

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={(
      <>
        {/*<RemoveLoadingScreen />*/}
        <Outlet />
      </>
    )}>
      {/* Redirects */}
      <Route index loader={() => redirect('/dashboard')} />
      <Route path="/setup" loader={() => redirect('/setup/profile')} />
      <Route path="/post-project/:projectId" loader={({ params }) => redirect(`/post-project/${params.projectId}/site`)} />
      <Route path="/post-event/:eventId" loader={({ params }) => redirect(`/post-event/${params.eventId}/site`)} />
      <Route path="/post-offer/:offerId" loader={({ params }) => redirect(`/post-offer/${params.offerId}/activity`)} />
      <Route path="/project/:projectId" loader={({ params }) => redirect(`/project/${params.projectId}/view`)} />
      <Route path="/event/:eventId" loader={({ params }) => redirect(`/event/${params.eventId}/view`)} />
      <Route path="/offer/:offerId" loader={({ params }) => redirect(`/offer/${params.offerId}/view`)} />
      <Route path="/project-invite/:questionId" loader={({ params }) => redirect(`/project-invite/${params.questionId}/review`)} />
      <Route path="/event-invite/:invitationId" loader={({ params }) => redirect(`/event-invite/${params.invitationId}/review`)} />
      <Route path="/offer-invite/:claimId" loader={({ params }) => redirect(`/offer-invite/${params.claimId}/review`)} />

      <Route path="/evaluation-project-educator/:evaluationId" loader={({ params }) => redirect(`/evaluation-project-educator/${params.evaluationId}/start`)} />
      <Route path="/evaluation-project-partner/:evaluationId" loader={({ params }) => redirect(`/evaluation-project-partner/${params.evaluationId}/start`)} />
      <Route path="/evaluation-event-educator/:evaluationId" loader={({ params }) => redirect(`/evaluation-event-educator/${params.evaluationId}/start`)} />
      <Route path="/evaluation-event-partner/:evaluationId" loader={({ params }) => redirect(`/evaluation-event-partner/${params.evaluationId}/start`)} />

      {/* Routes */}
      <Route path="/login" Component={Login} />
      <Route path="/login-classlink" Component={LoginClassLink} />
      <Route path="/signup" Component={Signup} />
      <Route path="/logout" Component={Logout} />
      <Route path="/auth/callback" Component={AuthCallback} />
      <Route path="/unauthorized" Component={NotAuthorized} />

      <Route Component={UserIsAuthenticated}>
        <Route Component={Master}>
          <Route path="/inactive" Component={Inactive} />

          <Route path="/setup/name" Component={SetupNameLayout} />
          <Route path="/setup/network" Component={SetupNetworkLayout} />
          <Route path="/setup/unavailable" Component={SetupUnavailableLayout} />
          <Route path="/setup/thanks" Component={SetupThanksLayout} />
          <Route path="/setup/profile" Component={SetupProfileLayout} />
          <Route path="/setup/zip-code" Component={SetupZipCodeLayout} />
          <Route path="/setup/organization" Component={SetupOrganizationLayout} />
          <Route path="/setup/school" Component={SetupSchoolLayout} />
          <Route path="/setup/teaching" Component={SetupTeachingLayout} />
          <Route path="/setup/practices" Component={SetupPracticesLayout} />
          <Route path="/setup/about" Component={SetupAboutLayout} />
          <Route path="/setup/skills" Component={SetupSkillsLayout} />
          <Route path="/setup/interests" Component={SetupInterestsLayout} />
          <Route path="/setup/activities" Component={SetupActivitiesLayout} />
          <Route path="/setup/experience" Component={SetupExperienceLayout} />
          <Route path="/setup/motivation" Component={SetupMotivationLayout} />
          <Route path="/setup/hope" Component={SetupHopeLayout} />
          <Route path="/setup/contact" Component={SetupContactLayout} />
          <Route path="/setup/social" Component={SetupSocialLayout} />
          <Route path="/setup/demographics" Component={SetupDemographicsLayout} />
          <Route path="/setup/avatar" Component={SetupAvatarLayout} />
          <Route path="/setup/end" Component={SetupEndLayout} />

          <Route Component={FlowLayout}>
            <Route path="/profile/edit/location" Component={ProfileEditLocationLayout} />
            <Route path="/profile/edit/social" Component={ProfileEditSocialLayout} />
            <Route path="/profile/edit/grades" Component={ProfileEditGradesLayout} />
            <Route path="/profile/edit/subjects" Component={ProfileEditSubjectsLayout} />
            <Route path="/profile/edit/practices" Component={ProfileEditPracticesLayout} />
            <Route path="/profile/edit/organizations" Component={ProfileEditOrganizationsLayout} />
            <Route path="/profile/edit/sites" Component={ProfileEditSitesLayout} />
            <Route path="/profile/edit/name" Component={ProfileEditNameLayout} />
            <Route path="/profile/edit/about" Component={ProfileEditAboutLayout} />
            <Route path="/profile/edit/avatar" Component={ProfileEditAvatarLayout} />
            <Route path="/profile/edit/demographics" Component={ProfileEditDemographicsLayout} />
            <Route path="/profile/edit/phone" Component={ProfileEditPhoneLayout} />
            <Route path="/profile/edit/opportunities" Component={ProfileEditOpportunitiesLayout} />
            <Route path="/profile/edit/ages" Component={ProfileEditAgesLayout} />
            <Route path="/profile/edit/activities" Component={ProfileEditActivitiesLayout} />
            <Route path="/profile/edit/groups" Component={ProfileEditGroupsLayout} />
            <Route path="/profile/edit/skills" Component={ProfileEditSkillsLayout} />
            <Route path="/profile/edit/experience" Component={ProfileEditExperienceLayout} />
            <Route path="/profile/edit/guidance" Component={ProfileEditGuidanceLayout} />

            <Route path="/evaluation-project-educator/:evaluationId/start" Component={EvaluationProjectEducatorStartLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/students" Component={EvaluationProjectEducatorAudienceLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/supported" Component={EvaluationProjectEducatorSupportedLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/engaged" Component={EvaluationProjectEducatorEngagedLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/skills" Component={EvaluationProjectEducatorSkillsLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/awareness" Component={EvaluationProjectEducatorAwarenessLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/understanding" Component={EvaluationProjectEducatorUnderstandingLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/practiced" Component={EvaluationProjectEducatorPracticedLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/journey" Component={EvaluationProjectEducatorJourneyLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/adventures" Component={EvaluationProjectEducatorAdventuresLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/challenges" Component={EvaluationProjectEducatorChallengesLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/changes" Component={EvaluationProjectEducatorChangesLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/lessons" Component={EvaluationProjectEducatorLessonsLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/uploads" Component={EvaluationProjectEducatorUploadsLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/recommend" Component={EvaluationProjectEducatorRecommendLayout} />
            <Route path="/evaluation-project-educator/:evaluationId/end" Component={EvaluationProjectEducatorEndLayout} />

            <Route path="/evaluation-event-educator/:evaluationId/start" Component={EvaluationEventEducatorStartLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/students" Component={EvaluationEventEducatorAudienceLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/engaged" Component={EvaluationEventEducatorEngagedLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/skills" Component={EvaluationEventEducatorSkillsLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/awareness" Component={EvaluationEventEducatorAwarenessLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/understanding" Component={EvaluationEventEducatorUnderstandingLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/practiced" Component={EvaluationEventEducatorPracticedLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/description" Component={EvaluationEventEducatorDescriptionLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/changes" Component={EvaluationEventEducatorChangesLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/uploads" Component={EvaluationEventEducatorUploadsLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/recommend" Component={EvaluationEventEducatorRecommendLayout} />
            <Route path="/evaluation-event-educator/:evaluationId/end" Component={EvaluationEventEducatorEndLayout} />

            <Route path="/evaluation-project-partner/:evaluationId/start" Component={EvaluationProjectPartnerStartLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/interactions" Component={EvaluationProjectPartnerInteractionsLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/hours" Component={EvaluationProjectPartnerHoursLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/contribution" Component={EvaluationProjectPartnerContributionLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/outcome" Component={EvaluationProjectPartnerOutcomeLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/recommend" Component={EvaluationProjectPartnerRecommendLayout} />
            <Route path="/evaluation-project-partner/:evaluationId/end" Component={EvaluationProjectPartnerEndLayout} />

            <Route path="/evaluation-event-partner/:evaluationId/start" Component={EvaluationEventPartnerStartLayout} />
            <Route path="/evaluation-event-partner/:evaluationId/outcome" Component={EvaluationEventPartnerOutcomeLayout} />
            <Route path="/evaluation-event-partner/:evaluationId/recommend" Component={EvaluationEventPartnerRecommendLayout} />
            <Route path="/evaluation-event-partner/:evaluationId/end" Component={EvaluationEventPartnerEndLayout} />

            <Route path="/post-project" Component={PostProjectCreateLayout} />
            <Route path="/post-project/:projectId/site" Component={PostProjectSiteLayout} />
            <Route path="/post-project/:projectId/activity" Component={PostProjectActivityLayout} />
            <Route path="/post-project/:projectId/participants" Component={PostProjectParticipantsLayout} />
            <Route path="/post-project/:projectId/student-count" Component={PostProjectStudentCountLayout} />
            <Route path="/post-project/:projectId/student-age" Component={PostProjectStudentAgeLayout} />
            <Route path="/post-project/:projectId/student-size" Component={PostProjectStudentSizeLayout} />
            <Route path="/post-project/:projectId/summary" Component={PostProjectSummaryLayout} />
            <Route path="/post-project/:projectId/outcome" Component={PostProjectOutcomeLayout} />
            <Route path="/post-project/:projectId/title" Component={PostProjectTitleLayout} />
            <Route path="/post-project/:projectId/subjects" Component={PostProjectSubjectsLayout} />
            <Route path="/post-project/:projectId/demonstration" Component={PostProjectDemonstrationLayout} />
            <Route path="/post-project/:projectId/date" Component={PostProjectDateLayout} />
            <Route path="/post-project/:projectId/days" Component={PostProjectDaysLayout} />
            <Route path="/post-project/:projectId/commitment" Component={PostProjectCommitmentLayout} />
            <Route path="/post-project/:projectId/time" Component={PostProjectTimeLayout} />
            <Route path="/post-project/:projectId/location" Component={PostProjectLocationLayout} />
            <Route path="/post-project/:projectId/partner" Component={PostProjectPartnerLayout} />
            <Route path="/post-project/:projectId/documents" Component={PostProjectDocumentsLayout} />
            <Route path="/post-project/:projectId/resources" Component={PostProjectResourcesLayout} />
            <Route path="/post-project/:projectId/grant" Component={PostProjectGrantLayout} />
            <Route path="/post-project/:projectId/budget" Component={PostProjectBudgetLayout} />
            <Route path="/post-project/:projectId/search-explanation" Component={PostProjectSearchExplanationLayout} />

            <Route path="/post-event" Component={PostEventCreateLayout} />
            <Route path="/post-event/:eventId/site" Component={PostEventSiteLayout} />
            <Route path="/post-event/:eventId/activity" Component={PostEventActivityLayout} />
            <Route path="/post-event/:eventId/participants" Component={PostEventParticipantsLayout} />
            <Route path="/post-event/:eventId/student-count" Component={PostEventStudentCountLayout} />
            <Route path="/post-event/:eventId/student-age" Component={PostEventStudentAgeLayout} />
            <Route path="/post-event/:eventId/student-size" Component={PostEventStudentSizeLayout} />
            <Route path="/post-event/:eventId/title" Component={PostEventTitleLayout} />
            <Route path="/post-event/:eventId/summary" Component={PostEventSummaryLayout} />
            <Route path="/post-event/:eventId/subjects" Component={PostEventSubjectsLayout} />
            <Route path="/post-event/:eventId/slots" Component={PostEventSlotsLayout} />
            <Route path="/post-event/:eventId/partner" Component={PostEventPartnerLayout} />
            <Route path="/post-event/:eventId/date" Component={PostEventDateLayout} />
            <Route path="/post-event/:eventId/time" Component={PostEventTimeLayout} />
            <Route path="/post-event/:eventId/location" Component={PostEventLocationLayout} />
            <Route path="/post-event/:eventId/documents" Component={PostEventDocumentsLayout} />
            <Route path="/post-event/:eventId/search-explanation" Component={PostEventSearchExplanationLayout} />

            <Route path="/post-offer" Component={PostOfferCreateLayout} />
            <Route path="/post-offer/:offerId/activity" Component={PostOfferActivityLayout} />
            <Route path="/post-offer/:offerId/description" Component={PostOfferDescriptionLayout} />
            <Route path="/post-offer/:offerId/audience" Component={PostOfferAudienceLayout} />
            <Route path="/post-offer/:offerId/objectives" Component={PostOfferObjectivesLayout} />
            <Route path="/post-offer/:offerId/title" Component={PostOfferTitleLayout} />
            <Route path="/post-offer/:offerId/subjects" Component={PostOfferSubjectsLayout} />
            <Route path="/post-offer/:offerId/supplies" Component={PostOfferSuppliesLayout} />

            <Route path="/post-offer/:offerId/participants" Component={PostOfferParticipantsLayout} />
            <Route path="/post-offer/:offerId/student-groups" Component={PostOfferStudentGroupsLayout} />

            <Route path="/post-offer/:offerId/competencies" Component={PostOfferCompetenciesLayout} />
            <Route path="/post-offer/:offerId/date" Component={PostOfferDateLayout} />
            <Route path="/post-offer/:offerId/time" Component={PostOfferTimeLayout} />
            <Route path="/post-offer/:offerId/location" Component={PostOfferLocationLayout} />
            <Route path="/post-offer/:offerId/design" Component={PostOfferDesignLayout} />
            <Route path="/post-offer/:offerId/documents" Component={PostOfferDocumentsLayout} />
            <Route path="/post-offer/:offerId/website" Component={PostOfferWebsiteLayout} />
            <Route path="/post-offer/:offerId/cost" Component={PostOfferCostLayout} />
            <Route path="/post-offer/:offerId/explanation" Component={PostOfferExplanationLayout} />
          </Route>

          <Route Component={Layout}>

            <Route path="/profile">
              <Route index loader={() => redirect('view')} />
              <Route path="view" Component={ProfileViewLayout} />
              <Route path="edit" Component={ProfileEditLayout} />
              <Route path="settings" Component={ProfileSettingsLayout} />
            </Route>

            <Route path="/dashboard" Component={HomeLayout} />

            <Route path="/messages" Component={MessagesLayout} />
            <Route path="/messages/:userId" Component={MessagesUserLayout} />

            <Route path="/my-projects" Component={MyProjectsLayout} />
            <Route path="/my-events" Component={MyEventsLayout} />
            <Route path="/my-offers" Component={MyOffersLayout} />

            <Route path="/project/:projectId/manage" loader={projectRedirect('/manage')} />
            <Route path="/project/:projectId/view" loader={projectRedirect('/view')} />
            <Route path="/project/:projectId/edit" loader={projectRedirect('/edit')} />
            <Route path="/project/:projectId/invite-populate" loader={projectRedirect('/invite-populate')} />
            <Route path="/project/:projectId/invite" loader={projectRedirect('/invite')} />
            <Route path="/project/:projectId/invite/:userId" Component={ProjectInviteUserLayout} />
            <Route path="/project/:projectId/messages" loader={projectRedirect('/manage')} />
            <Route path="/project/:projectId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-projects', [
                DialogConfig.ProjectManage(params.projectId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />

            <Route path="/event/:eventId/manage" loader={eventRedirect('/manage')} />
            <Route path="/event/:eventId/view" loader={eventRedirect('/view')} />
            <Route path="/event/:eventId/edit" loader={eventRedirect('/edit')} />
            <Route path="/event/:eventId/invite-populate" loader={eventRedirect('/invite-populate')} />
            <Route path="/event/:eventId/invite" loader={eventRedirect('/invite')} />
            <Route path="/event/:eventId/invite/:userId" Component={EventInviteUserLayout} />
            <Route path="/event/:eventId/messages" loader={eventRedirect('/manage')} />
            <Route path="/event/:eventId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-events', [
                DialogConfig.EventManage(params.eventId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />

            <Route path="/offer/:offerId/manage" loader={offerRedirect('/manage')} />
            <Route path="/offer/:offerId/view" loader={offerRedirect('/view')} />
            <Route path="/offer/:offerId/edit" loader={offerRedirect('/edit')} />
            <Route path="/offer/:offerId/invite-populate" loader={offerRedirect('/invite-populate')} />
            <Route path="/offer/:offerId/invite" loader={offerRedirect('/invite')} />
            <Route path="/offer/:offerId/invite/:userId" Component={OfferInviteUserLayout} />
            <Route path="/offer/:offerId/messages" loader={offerRedirect('/manage')} />
            <Route path="/offer/:offerId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-offers', [
                DialogConfig.OfferManage(params.offerId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />

            <Route path="/project-invite/:questionId/review" loader={questionRedirect('/')} />
            <Route path="/event-invite/:invitationId/review" loader={invitationRedirect('/')} />
            <Route path="/offer-invite/:claimId/review" loader={claimRedirect('/')} />

            <Route path="/project-invite/:questionId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-projects', [
                DialogConfig.QuestionView(params.questionId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />
            <Route path="/event-invite/:invitationId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-events', [
                DialogConfig.InvitationView(params.invitationId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />
            <Route path="/offer-invite/:claimId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-offers', [
                DialogConfig.ClaimView(params.claimId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />

            <Route path="/projects/:projectId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-projects', [
                DialogConfig.Project(params.projectId, '/'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />
            <Route path="/events/:eventId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-events', [
                DialogConfig.Event(params.eventId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />
            <Route path="/offers/:offerId/messages/:userId" loader={function({ params }) {
              return compositeRedirect('/my-offers', [
                DialogConfig.Offer(params.offerId, '/manage'),
                DialogConfig.Messages(params.userId)
              ]);
            }} />

            {/*<Route path="/project-invite/:questionId/review" Component={ProjectReviewLayout} />*/}
            {/*<Route path="/event-invite/:invitationId/review" Component={EventReviewLayout} />*/}
            {/*<Route path="/offer-invite/:claimId/review" Component={OfferReviewLayout} />*/}

            <Route path="/projects" Component={ProjectsLayout} />
            <Route path="/projects-populate" Component={ProjectsPopulateLayout} />
            {/*<Route path="/projects/:projectId" Component={ProjectLayout} />*/}
            <Route path="/projects/:projectId" loader={function({ params }) {
              return compositeRedirect('/projects', [
                DialogConfig.Project(params.projectId, '/')
              ]);
            }} />

            <Route path="/events" Component={EventsLayout} />
            <Route path="/events-populate" Component={EventsPopulateLayout} />
            {/*<Route path="/events/:eventId" Component={EventLayout} />*/}
            <Route path="/events/:eventId" loader={function({ params }) {
              return compositeRedirect('/events', [
                DialogConfig.Event(params.eventId, '/')
              ]);
            }} />

            <Route path="/offers" Component={OffersLayout} />
            <Route path="/offers-populate" Component={OffersPopulateLayout} />
            {/*<Route path="/offers/:offerId" Component={OfferLayout} />*/}
            <Route path="/offers/:offerId" loader={function({ params }) {
              return compositeRedirect('/offers', [
                DialogConfig.Offer(params.offerId, '/')
              ]);
            }} />

            <Route path="/users/:userId" Component={UserLayout} />
            {/*<Route path="/users/:userId" loader={function({ params }) {*/}
            {/*  return compositeRedirect('/users', [*/}
            {/*    DialogConfig.User(params.userId, '/')*/}
            {/*  ]);*/}
            {/*}} />*/}

            <Route path="/stories" Component={StoriesLayout} />
            {/*<Route path="/stories/:storyId" Component={StoryLayout} />*/}
            <Route path="/stories/:storyId" loader={function({ params }) {
              return compositeRedirect('/stories', [
                DialogConfig.Story(params.storyId, '/')
              ]);
            }} />

            <Route path="/educators" Component={PeopleEducatorsLayout} />
            <Route path="/educators-populate" Component={PeopleEducatorsPopulateLayout} />
            <Route path="/partners" Component={PeoplePartnersLayout} />
            <Route path="/partners-populate" Component={PeoplePartnersPopulateLayout} />

            <Route path="/bookmarks" Component={BookmarksLayout} />
            <Route path="/my-network" Component={MyNetworkLayout} />
          </Route>
        </Route>
      </Route>

      <Route path="*" Component={NotFoundPage} />
    </Route>
  )
);
