import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import { useConnect } from '@lore/query-connect';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import { useUser } from '@lore/auth';

Claim.propTypes = {
  claim: PropTypes.object.isRequired
};

export default function Claim(props) {
  const { claim, selected } = props;

  const currentUser = useUser();

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const recipient = useConnect('user.byId', {
    id: claim.data.userId
  });

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  }, {
    enabled: !!offer.data.creatorId
  });

  const claimCreator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  if (
    offer.state === PayloadStates.FETCHING ||
    recipient.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    claimCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const user = currentUser.id === creator.id ?
    recipient :
    creator;

  const isStewardGenerated = (
    claimCreator.id !== offer.data.creatorId &&
    claim.data.userId !== claim.data.creatorId
  );

  return (
    <div className={classNames(
      `flex flex-col rounded-lg p-4x w-full relative`,
      // selected ? 'bg-cs-orange-50 border-2 border-cs-orange-500 shadow-lg' : 'border-2 border-transparent bg-white',
      'bg-white shadow',
      'hover:bg-cs-orange-50'
    )}>
      {isStewardGenerated && (
        <div className="px-4 py-1 bg-cs-orange-100">
          <span className="text-sm text-cs-gray-500">
            Connection made by {claimCreator.data.firstName} {claimCreator.data.lastName} (Steward)
          </span>
        </div>
      )}
      <div className={classNames(
        `flex rounded-lg p-4 w-full relative`,
        selected ? 'bg-cs-orange-50 border-2 border-cs-orange-500 shadow-lg' : 'border-2 border-transparent bg-white',
        'hover:bg-cs-orange-50'
      )}>
        <Link to={`/offer/${claim.data.offerId}/messages/${user.id}`} className="absolute h-full w-full" />
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={10} src={user.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              {DateTime.fromISO(claim.data.updatedAt).toRelative()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
