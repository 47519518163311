import React from 'react';
import Activity from './Activity';
import Description from './Description';
import Students from './Students';
import Commitment from './Commitment';
import Creator from './Creator';
import Section from '../../../components_profiles/Section';
import Participation from './Participation';
import Uploads from './Uploads';
import Experience from './Experience';
import Skills from './Skills';
import Subjects from './Subjects';
import Banner from './Banner';

export default function Project(props) {
  const { project, onClose } = props;

  return (
    <div className="space-y-8">
      <Banner
        project={project}
        onClose={onClose}
      />
      <div className="flex flex-col md:flex-row space-x-8">
        <div className="w-full md:w-2/3 space-y-8">
          <Section title="Activity">
            <Activity project={project} />
            <div className="w-full space-y-4 block md:hidden">
              <Commitment project={project} />
              <Participation project={project} />
            </div>
          </Section>
          <Section title="Details">
            <Description project={project} />
            <Subjects project={project} />
          </Section>
          <Section
            title="Audience"
            subtitle="Learners the activity is designed for."
          >
            <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
              <div className="w-full">
                <Creator project={project} />
              </div>
              <div className="w-full">
                <Students project={project} />
              </div>
            </div>
          </Section>
          <Section
            title="Ideal Partner"
            subtitle="Do you have experience with some or all of these topics?"
          >
            <Skills project={project} />
            <Experience project={project} />
          </Section>
          <Uploads project={project} />
        </div>
        <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
          <Commitment project={project} />
          <Participation project={project} />
        </div>
      </div>
    </div>
  );
};
