import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as filestack from 'filestack-js';
import { useConfig } from '@lore/config';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import UserAvatar from '../../../components/UserAvatar';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const config = useConfig();

  const [data, setData] = useState(_.pick(user.data, [
    'avatarUrl'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onEditAvatar() {
    const {
      apiKey,
      imageMax,
      acceptImages
    } = config.filestack;

    const client = filestack.init(apiKey);
    client.picker({
      accept: acceptImages,
      imageMax: imageMax,
      uploadInBackground: false,
      onUploadDone: function(result) {
        // console.log(JSON.stringify(result.filesUploaded));
        const uploadedFile = result.filesUploaded[0];

        if (uploadedFile) {
          const avatarUrl = uploadedFile.url;
          onChange('avatarUrl', avatarUrl);
        }
      }
    }).open();
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // firstName: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                title="Upload a photo."
                subtitle="Click the pencil icon to select a photo for your profile. We recommend uploading a headshot, but you'll also be able to search for one if you'd prefer."
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div
                    className="text-center"
                    // onClick={onEditAvatar}
                  >
                    <UserAvatar
                      size={48}
                      user={{
                        data: data
                      }}
                      avatarClassName="hover:opacity-90"
                      onChange={onEditAvatar}
                    />
                  </div>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
