import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useConnect } from '@lore/query-connect';
import Avatar from '../../components/Avatar';
import Card from '../../components/Card';

export default function Evaluation(props) {
  const { to, resource, type, result, evaluation, children } = props;

  const resourceCreator = useConnect('user.byId', {
    id: resource.data.creatorId
  });

  const diff = DateTime.fromISO(evaluation.data.updatedAt).toMillis() - DateTime.fromISO(evaluation.data.createdAt).toMillis();

  return (
    <div className="relative">
      {to && (
        <Link to={to} className="absolute w-full h-full" />
      )}
      {/*<div className="bg-white shadow-md rounded-xl overflow-hidden divide-y divide-gray-200 relative">*/}
      <Card className="divide-y divide-gray-200 relativex">
        {children}
        <div className="flex flex-col sm:flex-row justify-between p-4">
          <div className="hidden sm:flex flex-1 items-center space-x-4">
            <Avatar size={12} src={resourceCreator.data.avatarUrl}/>
            <div className="flex flex-col">
              <div className="text-lg font-semibold text-cs-gray-900">
                {resourceCreator.data.firstName} {resourceCreator.data.lastName}
              </div>
              <div className="text-sm text-cs-gray-500 space-x-1">
                <span>{type} Creator</span>
                {/*<span>·</span>*/}
                {/*<span>Evaluation created {DateTime.fromISO(evaluation.data.createdAt).toRelative()}</span>*/}
              </div>
            </div>
          </div>
          {evaluation.data.completed ? (
            <div className="flex flex-col text-left sm:text-right">
              <div className="text-lg font-semibold text-green-600 space-x-1">
                <span>Complete</span>
              </div>
              <div className="flex text-sm text-cs-gray-500 space-x-1">
                {result && (
                  <>
                    <span>{result}</span>
                    <span>·</span>
                  </>
                )}
                <span>Completed {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}</span>
              </div>
            </div>
          ) : diff > 100 ? (
            <div className="flex flex-col text-left sm:text-right">
              <div className="text-lg font-semibold text-cs-yellow-700 space-x-1">
                <span>Started</span>
                <span>·</span>
                <span>Incomplete</span>
              </div>
              <div className="flex text-sm text-cs-gray-500 space-x-6">
                {result && (
                  <>
                    <span>{result}</span>
                    <span>·</span>
                  </>
                )}
                <span>Abandoned {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}</span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-left sm:text-right">
              {/*<div className="text-lg font-semibold text-cs-yellow-700 space-x-1">*/}
              {/*  <span>Not Started</span>*/}
              {/*</div>*/}
              <div className="text-lg font-semibold text-cs-orange-500 space-x-1">
                <span>Start now!</span>
              </div>
              <div className="text-sm text-cs-gray-500 space-x-1">
                {result && (
                  <>
                    <span>{result}</span>
                    <span>·</span>
                  </>
                )}
                <span>Evaluation created {DateTime.fromISO(evaluation.data.createdAt).toRelative()}</span>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
