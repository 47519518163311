import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const user = useUser();

  const jobs = useConnect('job.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  if (jobs.state === PayloadStates.FETCHING) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Organization</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Organization</title>
      </Helmet>
      <Form
        user={user}
        jobs={jobs}
        navigation={user.data.isEducator ?
          <Navigation activeStep={4} steps={10}/> :
          <Navigation activeStep={4} steps={12}/>
        }
        onPrevious={(newData) => history.push(Urls.ZIP_CODE)}
        onNext={() => history.push(Urls.SKILLS)}
        onSkip={() => user.data.isEducator ?
          history.push(Urls.TEACHING) :
          history.push(Urls.SKILLS)
        }
      />
    </ParentLayout>
  );
};
