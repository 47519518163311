import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Project from '../my-projects/Project';
import SectionTitle from './SectionTitle';
import EmptyProject from '../my-projects/EmptyProject';
import getBucketParams from '../my-projects/getBucketParams';

export default function Projects(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = getBucketParams(timestamp);

  const title = 'Your Projects';
  const subtitle = undefined; //'Projects you created that are in active or draft state.';
  const link = (
    <Link to="/my-projects" className="text-cs-blue-500 text-lg">
      View all
    </Link>
  );

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: [
            { ...params.active },
            { ...params.draft }
          ]
        }
      }
    },
    pagination: {
      pageSize: 2,
      order: 'createdAt desc'
    }
  });

  if (projects.state === PayloadStates.FETCHING) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (
    currentUser.data.isEducator &&
    projects.data.length === 0
  ) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div className="space-y-4">
          <EmptyProject />
        </div>
      </div>
    );
  }

  if (projects.data.length > 0) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          link={link}
        />
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            {projects.data.map(function(project, index) {
              if (project.state === PayloadStates.FETCHING) {
                return (
                  <Loader key={index}/>
                );
              }

              return (
                <Project
                  key={index}
                  project={project}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return null;
}
