import React from 'react';
import PropTypes from 'prop-types';
import avatar from '../../assets/images/avatar.svg';
import classNames from 'classnames';
import useFilestackImage from '../hooks/useFilestackImage';

Avatar.propTypes = {
  avatarUrl: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.number
};

Avatar.defaultProps = {
  src: avatar,
  size: 40
};

export default function Avatar(props) {
  const { src, size, className } = props;

  const srcOverride = useFilestackImage(src);

  const height = {
     8: 'h-8',
    10: 'h-10',
    12: 'h-12',
    14: 'h-14',
    16: 'h-16',
    20: 'h-20',
    24: 'h-24',
    28: 'h-28',
    32: 'h-32',
    36: 'h-36',
    40: 'h-40',
    44: 'h-44',
    48: 'h-48'
  };

  const width = {
     8: 'w-8',
    10: 'w-10',
    12: 'w-12',
    14: 'w-14',
    16: 'w-16',
    20: 'w-20',
    24: 'w-24',
    28: 'w-28',
    32: 'w-32',
    36: 'w-36',
    40: 'w-40',
    44: 'w-44',
    48: 'w-48'
  }

  return (
    <img
      className={classNames(
        'rounded-full object-center object-cover',
        height[size],
        width[size],
        className
      )}
      src={srcOverride || avatar}
      alt="Avatar"
    />
  );
};
