import React from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function HelpNotification(props) {
  const { onClick } = props;

  return (
    <div
      className={classNames(
        'flex bg-cs-blue-50 border border-cs-blue-100 rounded-lg py-4 px-6 space-x-4',
        'shadow-cs-flat', // shadow-md
        onClick && 'hover:bg-cs-blue-100 cursor-pointer'
      )}
      onClick={onClick}
    >
      <div className="mt-1">
        <UsersIcon className="h-10 w-10 text-cs-blue-500" />
      </div>
      <div className="flex-1 max-w-screen-md">
        <div className="text-lg font-semibold text-cs-gray-900">
          Ready to connect with community partners?
        </div>
        <div className="text-base text-cs-gray-500 mb-1">
          Have a question your students want to explore? Rough idea of a project or event
          you'd like to try? We'll walk you through broadcasting your idea to partners and
          help you invite some folks to join!
        </div>
        <div className="inline-block text-cs-blue-500 text-base font-semibold">
          Let's get started together <span aria-hidden="true"> &rarr;</span>
        </div>
      </div>
    </div>
  );
}
