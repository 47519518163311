import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

InvitedButtons.propTypes = {
  event: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired
};

export default function InvitedButtons(props) {
  const { event, invitation, creator, onClose } = props;

  const launch = useDialogLauncher();

  function showInterestedDialog(onChange) {
    launch(DialogConfig.InvitationYes(invitation.id));
  }

  function showDeclineDialog(onChange) {
    launch(DialogConfig.InvitationNo(invitation.id));
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          You are MAYBE attending!
        </>
      )}
      subtitle={(
        <>
          Once you let {creator.data.firstName} know you're interested, you can start
          asking questions and offering ideas or even refer somebody else who might be
          a good fit.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => {
          launch(DialogConfig.Messages(creator.id, { eventId: invitation.data.eventId }));
        }}>
          Message {creator.data.firstName || 'Educator'}
        </ConnectButton>
        <ConnectButton outline={true} onClick={showDeclineDialog}>
          Sorry, I can't help right now
        </ConnectButton>
        <ConnectButton onClick={showInterestedDialog}>
          Yes, I will attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
