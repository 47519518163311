import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import { useConnect } from '@lore/query-connect';
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import Card from '../../components/Card';

Question.propTypes = {
  question: PropTypes.object.isRequired
};

export default function Question(props) {
  const { question, selected } = props;

  const currentUser = useUser();

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  }, {
    enabled: !!project.data.creatorId
  });

  const questionCreator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    recipient.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    questionCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const user = currentUser.id === creator.id ?
    recipient :
    creator;

  const isStewardGenerated = (
    questionCreator.id !== project.data.creatorId &&
    question.data.userId !== question.data.creatorId
  );

  return (
    <Card className={classNames(
      `flex flex-col relative`,
      // 'rounded-lg',
      'hover:bg-cs-orange-50'
    )}>
      {isStewardGenerated && (
        <div className="px-4 py-1 bg-cs-orange-100">
          <span className="text-sm text-cs-gray-500">
            Connection made by {questionCreator.data.firstName} {questionCreator.data.lastName} (Steward)
          </span>
        </div>
      )}
      <div className="flex p-4">
        <Link to={`/project/${question.data.projectId}/messages/${user.id}`} className="absolute h-full w-full" />
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={10} src={user.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              {DateTime.fromISO(question.data.updatedAt).toRelative()}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
