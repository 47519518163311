import React from 'react';
import { stringify } from 'query-string';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';
import Callback from '../project-messages/Callback';
import { useUser } from '@lore/auth';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { event } = props;

  const currentUser = useUser()
  const router = useRouter();

  const eventExpertiseTypes = useEventTags(event, TagTypes.ExpertiseType);

  if (
    event.state === PayloadStates.FETCHING ||
    eventExpertiseTypes.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <Callback
      callback={() => router.navigate({
        pathname: `/invite`,
        search: stringify({
          expertiseTypes: eventExpertiseTypes.data.map(eventExpertiseType => eventExpertiseType._tag.id).join(','),
          locationCondition: JSON.stringify({
            address: currentUser.data.zipCode,
            latitude: currentUser.data.latitude,
            longitude: currentUser.data.longitude,
            radius: 50
          })
        })
      }, { replace: true })}
    />
  );
}
