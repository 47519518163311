import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

PendingBanner.propTypes = {
  event: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired
};

export default function PendingBanner(props) {
  const { event, invitation, creator, onClose } = props;

  const launch = useDialogLauncher();

  function showYesDialog(onChange) {
    launch(DialogConfig.InvitationYes(invitation.id));
  }

  function showNoDialog(onChange) {
    launch(DialogConfig.InvitationNo(invitation.id));
  }

  function showMaybeDialog(onChange) {
    launch(DialogConfig.InvitationMaybe(invitation.id));
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          You've been invited! Are you available?
        </>
      )}
      subtitle={(
        <>
          Let {creator.data.firstName} know whether you plan to attend, have questions,
          or have to send your regrets this time.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showNoDialog}>
          No, with regrets
        </ConnectButton>
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          Maybe, but I have some questions first
        </ConnectButton>
        <ConnectButton onClick={showYesDialog}>
          Yes, I will attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
