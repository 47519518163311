import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  if (event.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Summary"
      title="What will Partners do at this event?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: Partners respond best to short
          descriptions that start with a verb. You can always edit this later.
        </>
      )}
      examples={(
        <div className="bg-white bg-opacity-100 text-cs-gray-900 max-w-lg">
          <div className="text-base font-semibold mb-1">
            Examples
          </div>
          <ul className="list-disc ml-6 text-base space-y-0">
            {[
              // 'Teach 25 third-graders how to compost',
              'Speak to Algebra 1 students interested in professionals that use math on the job',
              'Speak to a whole-school assembly',
              'Host a lunchtime booth for 9-12th graders interested in fulfilling community service hours to learn about local nonprofits',
              'Interview 11th graders for practice before internship interviews',
              'Judge annual middle school science fair',
              // 'Design a lesson with a 10th-grade Chemistry teacher on volatility'
            ].map(function(text, index) {
              return (
                <li key={index}>
                  {text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    >
      <Form
        user={user}
        event={event}
        navigation={(
          <Navigation v5={true} activeStep={6} steps={13}/>
        )}
        onPrevious={(newData) => history.push(Urls(event).TITLE)}
        onNext={(newData) => history.push(Urls(event).SUBJECTS)}
        onSkip={() => history.push(Urls(event).SUBJECTS)}
        onExit={() => history.push(Urls(event).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
