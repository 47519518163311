import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import BubbleLayout from '../BubbleLayout';
import SolidButton from '../../../components/Button/SolidButton';
import Loader from '../../../components/Loader';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <BubbleLayout
      event={event}
      page="Search Explanation"
    >
      <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
        <div className="text-3xl font-semibold text-cs-gray-900 text-center mb-4">
          Congratulations! You've shared your idea with the world.
        </div>
        <div className="text-base text-cs-gray-700 text-center space-y-2 mb-12">
          <div>
            On the next screen, we'll show you some Partners who might be a good fit for your project or event.
          </div>
          <div>
            It's a great idea to take a few minutes now to invite at least a couple of them to start a conversation.
          </div>
          <div>
            You're not alone! CommunityShare and your local steward will begin promoting your idea to potential partners.
          </div>
        </div>
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton onClick={() => history.push(Urls(event).PROJECT_INVITE)}>
            Next
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 font-semibold" to={Urls(event).DOCUMENTS}>
            Back
          </Link>
        </div>
      </div>
    </BubbleLayout>
  );
};
