import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import Avatar from '../../components/Avatar';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    user,
    emailPreview
  } = props;

  const promptMap = {
    // 0: `Great! What's your question?`,
    1: `Awesome! Many Partners are happy to offer their thoughts or feedback. Try to be as specific as possible to help them give you the feedback you need.`,
    2: `Exciting! Partners are much more likely to respond when they understand your need. Let's make it easy for them to say "yes"!`,
    3: `Networking is a great way to build relationships! Why do you think this partner might have the right connections for you?`
  };

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={user.data.avatarUrl} />
        </div>
      )}
      title={`Connect with ${user.data.firstName} ${user.data.lastName}`}
      // subtitle="Don't worry--you can always change things later."
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            title={`${user.data.firstName} looks amazing!`}
            subtitle="Which of these best describes why you want to connect with them?"
          >
            <FormField name="reason" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="reason"
                    // label="Does this event have a specific date?"
                    value={field.value}
                    error={field.error}
                    // placeholder="Select a date..."
                    options={{
                      data: [
                        `I'd like some feedback on a project I'm planning with my students.`,
                        `I'd like to know if they're interested in working with me and/or my students.`,
                        `I'd like to know if they can refer me to someone that could help.`
                      ].map((option, index) => {
                        return {
                          id: index + 1,
                          data: {
                            name: option
                          }
                        }
                      })
                    }}
                    columns={1}
                    optionLabel="name"
                    onChange={field.onChange}
                  />
                </>
              )}
            </FormField>
          </FormSection>
          {data.reason && (
            <>
              <FormSection
                title={promptMap[data.reason]}
              >
                <FormField name="customMessage" data={data} errors={showErrors && errors} onChange={onChange}>
                  {(field) => (
                    <TextAreaField
                      //label="Description*"
                      // placeholder="Custom message..."
                      rows={6}
                      value={field.value}
                      error={field.error}
                      onChange={field.onChange}
                    />
                  )}
                </FormField>
              </FormSection>
              <FormSection
                title="This is what we'll send to the partner."
                subtitle="Need to make changes? Adjust the text in the box above."
              >
                <div className="bg-white border border-cs-gray-200 shadow-cs-flat rounded-lg h-96 overflow-auto">
                  {emailPreview}
                </div>
              </FormSection>
            </>
          )}
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.reason} onClick={onSubmit}>
            Send invite
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
