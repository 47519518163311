import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../components/Tag';
import StoryBookmark from '../../../components/StoryBookmark';
import { useUser } from '@lore/auth';
import Image from './Image';
import classNames from 'classnames';
import useStoryTags from '../../../hooks/useStoryTags';
import TagTypes from '../../../constants/TagTypes';
import Text from '../../../typography';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import Card from '../../../components/Card';

Story.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Story(props) {
  const { story } = props;

  const user = useUser();
  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogConfig.Story(story.id));
  }

  const storyActivities = useStoryTags(story, TagTypes.Activity);

  return (
    <Card className={classNames(
        'p-4 relative',
      'hover:bg-cs-orange-50 cursor-pointer'
    )}>
      <div className="absolute z-10 top-6 right-6">
        <StoryBookmark
          story={story}
          user={user}
        />
      </div>
      <div className="space-y-2" onClick={onClick}>
        <div className="h-48 rounded-cs-10 overflow-hidden">
          <Image story={story} />
        </div>
        <div className="space-y-1">
          <Text.BaseSemibold className="line-clamp-2">
            {story.data.title}
          </Text.BaseSemibold>
          <Text.SmGrayMedium className="line-clamp-5">
            {story.data.description}
          </Text.SmGrayMedium>
        </div>
        <div>
          {storyActivities.data.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {storyActivities.data.map(function(storyActivity) {
                // return (
                //   <TagChip
                //     key={storyActivity.id}
                //     tag={storyActivity}
                //     small={true}
                //   />
                // );

                return (
                  <Tag
                    key={storyActivity.id}
                    label={storyActivity.data.name}
                    tooltip={storyActivity.data.description}
                    color="gray"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
