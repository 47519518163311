import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import useRouter from '../hooks/useRouter';

function isHidden(location) {
  const { pathname } = location;

  const tokens = pathname.split('/');

  return (
    pathname.indexOf('/messages') === 0 || (
      // pathname.indexOf('/post-project/') === 0 ||
      tokens[1] === 'post-project' && tokens[3] && tokens[3] !== 'activity'
    ) || (
      // pathname.indexOf('/post-project/') === 0 ||
      tokens[1] === 'post-event' && tokens[3] && tokens[3] !== 'activity'
    ) || (
      // pathname.indexOf('/post-offer/') === 0
      tokens[1] === 'post-offer' && tokens[3] && tokens[3] !== 'activity'
    ) || (
      tokens[1] === 'project' && tokens[3] === 'messages'
    ) || (
      tokens[1] === 'event' && tokens[3] === 'messages'
    ) || (
      tokens[1] === 'offer' && tokens[3] === 'messages'
    ) || (
      pathname.indexOf('/evaluation-project-educator') === 0
    ) || (
      pathname.indexOf('/evaluation-project-partner') === 0
    ) || (
      pathname.indexOf('/evaluation-event-educator') === 0
    ) || (
      pathname.indexOf('/evaluation-event-partner') === 0
    )
  );
}

export default function useIntercom() {
  const { location, history } = useRouter();
  const currentUser = useUser();
  const config = useConfig();

  const { enabled, appId, companyId, companyName } = config.intercom;
  const createdAt = Math.round(DateTime.fromISO(currentUser.data.createdAt).toSeconds());

  useEffect(() => {
    if (enabled) {
      window.Intercom('boot', {
        app_id: appId,
        name: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
        email: currentUser.data.email,
        username: currentUser.data.username,
        created_at: createdAt,
        company: {
          id: companyId,
          name: companyName
        },
        hide_default_launcher: isHidden(location)
      });
    }
  }, []);

  // each time the route changes, check to see if the messenger should be visible
  useEffect(() => {
    if (enabled) {
      window.Intercom('update', {
        hide_default_launcher: isHidden(location)
      });
    }
  }, [location.pathname]);


  return null;
};
