import React from 'react';
import Activity from './Activity';
import School from './School';
import ProjectSummary from './ProjectSummary';
import ProjectGoals from './ProjectGoals';
import ProjectTitle from './ProjectTitle';
import StudentCount from './StudentCount';
import StudentAges from './StudentAges';
import StudentSize from './StudentSize';
import Participants from './Participants';
import Subjects from './Subjects';
import Skills from './Skills';
import Commitment from './Commitment';
import Location from './Location';
import Documents from './Documents';
import Date from './Date';
import Time from './Time';
import ProjectDemonstration from './ProjectDemonstration';
import Days from './Days';
import Resources from './Resources';
import Grant from './Grant';
import Budget from './Budget';
import Divider from '../../forms/_fields_v2/Divider';
import Section from '../profile-edit/Section';
import useNetwork from '../../hooks/useNetwork';

export default function Layout(props) {
  const { project } = props;

  const network = useNetwork();

  return (
    <div className="space-y-8">
      <div className="text-2xl font-semibold text-cs-gray-900">
        Edit Project
      </div>
      <Divider />
      <Section
        title="School"
        subtitle={(
          <>
            Where are your learners located?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <School project={project} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Vision"
        subtitle={(
          <>
            What do you want to happen and what needs do you have?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Activity project={project} />
          <ProjectTitle project={project} />
          <ProjectGoals project={project} />
          <ProjectDemonstration project={project} />
          <ProjectSummary project={project} />
          <Resources project={project} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Audience"
        subtitle={(
          <>
            Who will partners work with?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Participants project={project} />
          <StudentCount project={project} />
          <StudentAges project={project} />
          <StudentSize project={project} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Partner"
        subtitle={(
          <>
            What subject matter experience will your partner need?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Subjects project={project} />
          <Skills project={project} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Timing & Logistics"
        subtitle={(
          <>
            When do you need a partner? How will you meet?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Date project={project} />
          <Days project={project} />
          <Commitment project={project} />
          <Time project={project} />
          <Location project={project} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Documents"
        subtitle={(
          <>
            Do you have any supporting documents to help Partners understand your idea?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Documents project={project} />
        </div>
      </Section>
      {network.data.grants === 'Yes' && (
        <>
          <Divider />
          <Section
            title="Support needed"
            subtitle={(
              <>
                Do you need funding for this project?
              </>
            )}
          >
            <div className="grid grid-cols-1 gap-6">
              <Grant project={project} />
              {project.data.grant === 'Yes' && (
                <Budget project={project} />
              )}
            </div>
          </Section>
        </>
      )}
    </div>
  );
};
