import React from 'react';
import HeaderNew from './Header-new';
import Invitations from './Invitations';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function Event(props) {
  const { event, className } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const to = currentUser.id === event.data.creatorId ? `/manage` : `/view`;

  function onClick() {
    launch(DialogConfig.EventManage(event.id, to));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white shadow-cs-flat -m-0.5 border-2 border-cs-gray-100 rounded-cs-10 relative overflow-hidden',
      className
    )}>
      <div onClick={onClick} className="cursor-pointer absolute w-full h-full" />
      <HeaderNew event={event} />
      <div className="w-full bg-cs-gray-50 -mt-px border-t border-cs-gray-100 py-4 px-4">
        <Invitations event={event}/>
      </div>
    </div>
  );
}
