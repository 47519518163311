import React from 'react';
import { Helmet } from 'react-helmet-async';
import Partners from './Partners';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const {
    project,
    event
  } = props;

  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Partners</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Explore Partners
      </div>
      <Partners
        project={project}
        event={event}
        router={router}
      />
    </div>
  );
}
