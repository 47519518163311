import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import BaseConversation from './BaseConversation';

Conversation.propTypes = {
  conversation: PropTypes.object.isRequired
};

export default function Conversation(props) {
  const { conversation, selected } = props;

  const launch = useDialogLauncher();

  const user = useConnect('user.byId', {
    id: conversation.data.userId
  });

  function onClick() {
    launch(DialogConfig.Messages(user.id));
  }

  if (user.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <BaseConversation
      user={user}
      onClick={onClick}
      updatedAt={conversation.data.updatedAt}
      messageCount={conversation.data.messageCount}
      selected={selected}
    />
  );
};
