import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

CreatorBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function CreatorBanner(props) {
  const { event, creator, onClose } = props;

  const launch = useDialogLauncher();

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          Your event is active!
        </>
      )}
      subtitle={(
        <>
          Go find Partners to invite or message those who have already responded.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => {
          // history.push(`/event/${event.id}/messages`);
          // onClose && onClose();
          launch(DialogConfig.EventManage(event.id, '/manage'));
        }}>
          Go to messages
        </ConnectButton>
        <ConnectButton onClick={() => {
          // history.push(`/event/${event.id}/invite-populate`);
          // onClose && onClose();
          launch(DialogConfig.EventManage(event.id, '/invite-populate'));
        }}>
          Find Partners
        </ConnectButton>
        {/*<ConnectButton disabled={true}>*/}
        {/*  This is you*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
