import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import UserBookmark from '../../../components/UserBookmark';
import useRouter from '../../../hooks/useRouter';
import VirtualAvatar from '../../../components/User/VirtualAvatar';
import CollaborationOptions from './CollaborationOptions';
import SocialLinks from './SocialLinks';
import Organizations from './Organizations';
import Sites from './Sites';
import Banner from './Banner';
import About from './About';
import Location from './Location';
import TagsToPills from './TagsToPills';
import TagTypes from '../../../constants/TagTypes';
import TagNodesToTree from './TagNodesToTree';
import TagsToText from './TagsToText';
import useUserTags from '../../../hooks/useUserTags';
import Impersonate from './Impersonate';
import Stories from '../Stories';
import ContentCard from '../../../components_profiles/ContentCard';
import Text, { TextWeight } from '../../../typography';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import ExperiencesBanner from './ExperiencesBanner';
import ActiveExperiences from './ActiveExperiences';
import PastExperiences from './PastExperiences';
import ActiveOffers from './ActiveOffers';
import PastOffers from './PastOffers';
import ActiveInvitations from './ActiveInvitations';
import PastInvitations from './PastInvitations';
import TagsToList from './TagsToList';

UserProfile.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserProfile(props) {
  const {
    user,
    project,
    event,
    offer,
    onClose
  } = props;

  const { location } = useRouter();
  const launch = useDialogLauncher();

  useEffect(() => {
    const query = parse(location.search);

    if (query.welcome) {
      launch(DialogConfig.Welcome());
    }
  }, []);

  const userGrades = useUserTags(user, TagTypes.Grade);
  const userActivities = useUserTags(user, TagTypes.Activity);
  const userAges = useUserTags(user, TagTypes.Age);
  const userSubjects = useUserTags(user, TagTypes.Subject);

  const userExpertises = useUserTags(user, TagTypes.Expertise);
  const userExperiences = useUserTags(user, TagTypes.Experience);
  const userGuidances = useUserTags(user, TagTypes.Guidance);
  const userGroups = useUserTags(user, TagTypes.Group);

  function mapHybridToTagStruct(hybrid) {
    return {
      state: hybrid.state,
      data: hybrid.data.map(function(datum) {
        return {
          id: datum.id,
          state: datum.state,
          data: {
            name: datum._tag.data.name,
            description: datum._tagNode.data.description
          }
        }
      })
    };
  }

  return (
    <div className="space-y-8">
      <Banner
        user={user}
        project={project}
        event={event}
        offer={offer}
        onClose={onClose}
      />
      <div className="flex flex-col md:flex-row space-x-0 space-y-8 md:space-x-8 md:space-y-0">
        <div className="w-full md:w-80 space-y-8">
          <ContentCard className="relative" padding="extra">
            <div className="absolute z-20 top-2 right-4">
              <UserBookmark
                user={user}
              />
            </div>
            <div className="p-6x">
              <div className="mb-4">
                <VirtualAvatar user={user} />
              </div>
              <div className="space-y-6">
                <div className="space-y-1">
                  <Text.XlSemibold className="text-center">
                    {user.data.firstName} {user.data.lastName}
                  </Text.XlSemibold>
                  <SocialLinks user={user} />
                </div>
                <div className="space-y-2">
                  <Location user={user} />
                  <Organizations user={user} />
                  <Sites user={user} />
                </div>
                <div className="">
                  <CollaborationOptions user={user} />
                </div>
                <Impersonate user={user} />
              </div>
            </div>
          </ContentCard>
          {user.data.isEducator && (
            <TagsToPills
              title="Grade levels I teach"
              tags={userGrades}
            />
          )}
          {!user.data.isEducator && (
            userActivities.data.length > 0 ||
            userAges.data.length > 0
          ) && (
            <ContentCard className="space-y-8">
              <TagsToList
                title="Activity types I want to collaborate on"
                tags={userActivities}
              />
              <TagsToList
                title="Age groups I want to work with"
                tags={userAges}
              />
            </ContentCard>
          )}
          {/*{!user.data.isEducator && (*/}
          {/*  <>*/}
          {/*    <TagsToPills*/}
          {/*      title="Activity types I want to collaborate on"*/}
          {/*      tags={userActivities}*/}
          {/*    />*/}
          {/*    <TagsToPills*/}
          {/*      title="Age groups I want to work with"*/}
          {/*      tags={userAges}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
        <div className="flex-1 space-y-8 overflow-x-hiddenx px-1 pb-4">
          <ExperiencesBanner user={user} />
          <About user={user} />
          {user.data.isEducator && (
            <>
              <TagsToText
                title="Subject Areas"
                description="I teach the following subject areas."
                tags={mapHybridToTagStruct(userSubjects)}
              />
              <ActiveExperiences user={user} />
              <PastExperiences user={user} />
            </>
          )}
          {!user.data.isEducator && (
            <>
              <TagNodesToTree
                title="Skills & Experiences"
                description="I can share my work experience and interests related to the topics below with youth and educators."
                nodes={userExpertises}
              />
              <TagsToText
                title="Life Experiences"
                description="Excited to talk about..."
                tags={mapHybridToTagStruct(userExperiences)}
              />
              <TagsToText
                title="Life Skills"
                description="Excited to advise about..."
                tags={mapHybridToTagStruct(userGuidances)}
              />
              <TagsToText
                title="Special Interest In"
                description="Especially interested in working with these youth groups."
                tags={mapHybridToTagStruct(userGroups)}
              />
              <ActiveInvitations user={user} />
              <PastInvitations user={user} />
              <ActiveOffers user={user} />
              <PastOffers user={user} />
            </>
          )}
          <Stories
            id="stories"
            user={user}
          />
        </div>
      </div>
    </div>
  );
}
