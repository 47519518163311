import React, { useState } from 'react';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Invitation from './Invitation';
import EventHeader from './Event/Header-new';
import SectionTitle from '../my-projects/SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { LinkButton, SolidButton } from '../../components/Button';

export default function Invitations(props) {
  const { type = 'attending' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = {
    invited: {
      'event.cancelled': false,
      response: 'Pending'
    },
    active: {
      'event.cancelled': false,
      'event.endDate': {
        $gte: timestamp
      },
      response: {
        $in: [
          'Yes',
          'Maybe'
        ]
      }
    },
    past: {
      $and: {
        $or: {
          'event.cancelled': true,
          'event.endDate': {
            $lte: timestamp
          },
          response: {
            $in: [
              // 'Yes',
              // 'Maybe',
              'No'
            ]
          }
        }
      }
    }
  };

  const title = {
    invited: 'Invitations',
    active: 'Active',
    past: 'Past'
  };

  const emptyStates = {
    invited: (
      <EmptyState
        title="No invitations"
        subtitle="You have no current invitations to connect from educators."
      />
    ),
    active: (
      <EmptyState
        title="No active events"
        subtitle="You have not expressed interest in connecting about any active events."
      >
        <EmptyStateActions>
          <LinkButton
            button={SolidButton}
            to="/events"
            label="Explore Events"
          />
        </EmptyStateActions>
      </EmptyState>
    ),
    past: (
      <EmptyState
        title="No past events"
        subtitle={(
          <>
            Events that are no longer active will appear here, as well as any events
            that were completed, cancelled, or that you declined as not being a good fit.
          </>
        )}
      />
    )
  };

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          'event.creatorId': {
            $not: currentUser.id
          },
          'event.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: {
            userId: currentUser.id
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (invitations.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {invitations.data.map(function(invitation, index) {
          return (
            <UseQueryConnect key={index} _key="event.byId" params={{ id: invitation.data.eventId }}>
              {function(event) {
                if (event.state === PayloadStates.FETCHING) {
                  return (
                    <Loader />
                  );
                }

                return (
                  <Invitation
                    invitation={invitation}
                    alignChildren="top"
                  >
                    <EventHeader
                      event={event}
                      type="invitation"
                    />
                  </Invitation>
                );
              }}
            </UseQueryConnect>
          );
        })}
      </div>
    </div>
  );
}
