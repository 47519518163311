import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import { OutlineButton, SolidButton } from '../../../components/Button';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import useRouter from '../../../hooks/useRouter';
import DuplicateIconWrapper from '../DuplicateIconWrapper';
import FormTemplateButtonFooter from '../../../dialogs_common/FormTemplateButtonFooter';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { data, onCancel } = props;

  const { event } = data;

  const { history } = useRouter();
  const launch = useDialogLauncher();

  function onNavigate() {
    // onCancel();
    // history.push(`/event/${event.id}/edit`);
    launch(DialogConfig.EventManage(event.id, '/edit'));
  }

  return (
    <DialogTemplate>
      <FormTemplate
        icon={(
          <DuplicateIconWrapper />
        )}
        title="Event duplicated!"
        // subtitle="Our community support person will reach out soon via email to discuss."
        onClose={onCancel}
        body={(
          <div className="font-light text-cs-gray-500 text-center text-lg">
            The next step is to review the duplicated event, make any relevant changes
            (such as changing the date and time window) and then start inviting partners!
          </div>
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onCancel}>
              Close
            </OutlineButton>
            <SolidButton onClick={onNavigate}>
              Review duplicated Event
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    </DialogTemplate>
  );
};
