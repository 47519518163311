import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import Section from '../../components/Section';
import Projects from './Projects';
import Events from './Events';
import ExpiredEvents from '../my-events/Events';
import ExpiredProjects from '../my-projects/Projects';
import Messages from './Messages';
import Evaluations from './Evaluations';
import StewardNotification from './StewardNotification';
import HelpNotification from './HelpNotification';
import QuestionsPending from './QuestionsPending';
import QuestionsConnected from './QuestionsConnected';
import InvitationsPending from './InvitationsPending';
import InvitationsConnected from './InvitationsConnected';
import ClaimsPending from './ClaimsPending';
import PrimaryHeader from '../../components/PrimaryHeader';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

function Header(props) {
  const { currentUser, children } = props;

  const launch = useDialogLauncher();

  function onChooseProjectEvent() {
    launch(DialogConfig.SelectorProjectEvent());
  }

  return (
    <div className="">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PrimaryHeader
        style="simple"
        title={(
          <>
            <div className="text-lg font-semibold text-cs-gray-900">
              Hi{currentUser.data.firstName ? ` ${currentUser.data.firstName}` : ''},
            </div>
            <div className="text-4xl font-semibold text-cs-gray-900 space-x-4">
              <span>Welcome back</span>
              <span>🎉</span>
            </div>
          </>
        )}
        textClassName="text-cs-orange-500"
      >
        {/*{!currentUser.data.isEducator && (*/}
        {/*  <ProtectedButton*/}
        {/*    button={LinkButton}*/}
        {/*    to="/post-offer"*/}
        {/*    label="Create Offer"*/}
        {/*  />*/}
        {/*)}*/}
      </PrimaryHeader>
      <div className="px-8 mb-24 w-full lg:mx-auto">
        <Section className="space-y-12">
          {currentUser.data.isEducator ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <StewardNotification audience="educator" />
              <HelpNotification onClick={onChooseProjectEvent} />
            </div>
          ) : (
            <div className="space-y-4">
              <StewardNotification audience="partner" />
            </div>
          )}
          {children}
        </Section>
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <Header currentUser={currentUser}>
      <ExpiredProjects type="expired" />
      <ExpiredEvents type="expired" />
      <Evaluations />
      {currentUser.data.isEducator ? (
        <>
          <ClaimsPending />
          <Projects />
          <Events />
        </>
      ) : (
        <>
          <QuestionsPending />
          <InvitationsPending />
          <QuestionsConnected />
          <InvitationsConnected />
        </>
      )}
      <div className="flex">
        <div className="w-full">
          <Messages />
        </div>
      </div>
    </Header>
  );
};
