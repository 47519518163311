import React from 'react';
import HeaderNew from './Header-new';
import Claims from './Claims';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function Offer(props) {
  const { offer, className } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  // const to = currentUser.id === offer.data.creatorId ?
  //   `/offer/${offer.id}/manage` :
  //   `/offer/${offer.id}/view`;

  const to = currentUser.id === offer.data.creatorId ? `/manage` : `/view`;

  function onClick() {
    launch(DialogConfig.OfferManage(offer.id, to));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white shadow-md rounded-xl relative overflow-hidden',
      className
    )}>
      <div onClick={onClick} className="cursor-pointer absolute w-full h-full" />
      <HeaderNew offer={offer} />
      <div className="w-full bg-cs-gray-100 py-4 px-4">
        <Claims offer={offer}/>
      </div>
    </div>
  );
}
