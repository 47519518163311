import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

DiscoverBanner.propTypes = {
  event: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired
};

export default function DiscoverBanner(props) {
  const { event, creator, onClose } = props;

  const [timestamp] = useState(new Date().toISOString());

  const launch = useDialogLauncher();

  function showYesDialog(onChange) {
    launch(DialogConfig.InvitationDiscoverYes(event.id));
  }

  function showMaybeDialog(onChange) {
      launch(DialogConfig.InvitationDiscoverMaybe(event.id));
  }

  if (
    event.data.endDate &&
    DateTime.fromISO(timestamp).diff(DateTime.fromISO(event.data.endDate)).milliseconds > 0
  ) {
    return (
      <Wrapper
        creator={creator}
        title="Want to help?"
        subtitle={(
          <>
            Great! Connect with {creator.data.firstName} to express interest in participating,
            ask questions, offer ideas, or to refer someone you think might be a good fit.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton disabled={true}>
            Window to connect has closed
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      creator={creator}
      title="Available to help?"
      subtitle={(
        <>
          Great! Let {creator.data.firstName} know and ask any questions you
          have. You can always adjust your response later.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          Maybe, but I have some questions first
        </ConnectButton>
        <ConnectButton onClick={showYesDialog}>
          Yes, I will attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
