import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function MissingField(props) {
  const { name, description, to } = props;

  return (
    <Link
      to={to}
      className="flex bg-cs-orange-50 shadow-lg rounded-lg py-4 px-6 space-x-4 hover:bg-cs-orange-100"
    >
      <div>
        <ExclamationTriangleIcon className="h-12 w-12 text-cs-orange-500" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-base font-semibold text-cs-gray-900">
          Missing Field: {name}
        </div>
        <div className="text-base text-cs-gray-500">
          {description}
        </div>
      </div>
    </Link>
  );
}
