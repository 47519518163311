import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ActionBar from '../event-manage/ActionBar';
import Header from '../event-manage/Header';
import EventViewLayout from '../event-view/Dialog';
import EventEditLayout from '../event-edit/Dialog';
import EventManageLayout from '../event-manage/Dialog';
import EventInvitePopulateLayout from '../event-invite-populate/Dialog';
import EventInviteLayout from '../event-invite/Dialog';
// import EventMessagesLayout from '../event-messages/Dialog';
import useDialogConfig from '../../dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../dialogs-routable/_hooks/useDialogRouter';

export default function EventManageDialogLayout(props) {
  const { event } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/manage');
    }
  }, []);

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/manage': 'Manage',
    '/view': 'View',
    '/edit': 'Edit',
    '/invite-populate': 'Invite',
    '/invite': 'Invite',
    '/messages': 'Messages'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {event.data.title ? `${event.data.title}: ${page}` : `Event #${event.id}: ${page}`}
        </title>
      </Helmet>
      <Header event={event} />
      <div className="-mx-6">
        <ActionBar event={event} />
      </div>
      <div className="space-y-12">
        {pathname === `/view` && (
          <EventViewLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/edit` && (
          <EventEditLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite-populate` && (
          <EventInvitePopulateLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite` && (
          <EventInviteLayout
            event={event}
            router={router}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/manage` && (
          <EventManageLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
      {/*  {pathname === `/messages` && (*/}
      {/*    <EventMessagesLayout*/}
      {/*      event={event}*/}
      {/*      onClose={props.onCancel}*/}
      {/*    />*/}
      {/*  )}*/}
      </div>
    </div>
  );
};
