import React from 'react';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function StewardNotification(props) {
  const { audience = 'educator' } = props;

  if (audience === 'partner') {
    return (
      <Link
        to="/my-network"
        className={classNames(
          'flex bg-cs-blue-50 border border-cs-blue-100 rounded-lg py-4 px-6 space-x-4',
          'shadow-cs-flat', // shadow-md
          'hover:bg-cs-blue-100 cursor-pointer'
        )}
      >
        <div className="mt-1">
          <ChatBubbleLeftRightIcon className="h-10 w-10 text-cs-blue-500" />
        </div>
        <div className="flex-1 max-w-screen-md">
          <div className="text-lg font-semibold text-cs-gray-900">
            Your community has people who are ready to help you get started and throughout your journey.
          </div>
          <div className="text-base text-cs-gray-500 mb-1">
            Not sure where to start? Need to talk through an idea? Want help documenting or celebrating your collaboration? Don't hesitate to reach out!
          </div>
          <div className="inline-block text-cs-blue-500 text-base font-semibold">
            Meet your support team <span aria-hidden="true"> &rarr;</span>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to="/my-network"
      className={classNames(
        'flex bg-cs-blue-50 border border-cs-blue-100 rounded-lg py-4 px-6 space-x-4',
        'shadow-cs-flat', // shadow-md
        'hover:bg-cs-blue-100 cursor-pointer'
      )}
    >
      <div className="mt-1">
        <ChatBubbleLeftRightIcon className="h-10 w-10 text-cs-blue-500" />
      </div>
      <div className="flex-1 max-w-screen-md">
        <div className="text-lg font-semibold text-cs-gray-900">
          Your community has people who are ready to help you get started and throughout your journey.
        </div>
        <div className="text-base text-cs-gray-500 mb-1">
          Not sure where to start? Need to talk through your idea? Can't find the right partner? Want help documenting or celebrating your collaboration?
        </div>
        <div className="inline-block text-cs-blue-500 text-base font-semibold">
          Meet your support team <span aria-hidden="true"> &rarr;</span>
        </div>
      </div>
    </Link>
  );
}
