import React from 'react';
import Activity from './Activity';
import Description from './Description';
import Students from './Students';
import Commitment from './Commitment';
import Creator from './Creator';
import Section from '../../../components_profiles/Section';
import Participation from './Participation';
import Uploads from './Uploads';
import Skills from './Skills';
import Subjects from './Subjects';
import Banner from './Banner';

export default function Event(props) {
  const { event, onClose } = props;

  return (
    <div className="space-y-8">
      <Banner
        event={event}
        onClose={onClose}
      />
      <div className="flex flex-col md:flex-row space-x-8">
        <div className="w-full md:w-2/3 space-y-8">
          <Section title="Activity">
            <Activity event={event} />
            <div className="w-full space-y-4 block md:hidden">
              <Commitment event={event} />
              <Participation event={event} />
            </div>
          </Section>
          <Section title="Details">
            <Description event={event} />
            <Subjects event={event} />
          </Section>
          <Section
            title="Audience"
            subtitle="Learners the activity is designed for."
          >
            <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
              <div className="w-full">
                <Creator event={event} />
              </div>
              <div className="w-full">
                <Students event={event} />
              </div>
            </div>
          </Section>
          <Section
            title="Ideal Partner"
            subtitle="Do you have experience with some or all of these topics?"
          >
            <Skills event={event} />
          </Section>
          <Uploads event={event} />
        </div>
        <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
          <Commitment event={event} />
          <Participation event={event} />
        </div>
      </div>
    </div>
  );
};
