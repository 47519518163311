import { v4 as uuidv4 } from 'uuid';

function format(type, params = {}, route = '/', options = {}) {
  return {
    id: uuidv4(),
    type: type,
    params: params,
    route: route,
    options: options
  };
}

const DialogKey = {
  Project: 'project',
  Story: 'story',
  Event: 'event',
  Offer: 'offer',
  ProjectManage: 'project_manage',
  ProjectClose: 'project_close',
  ProjectDuplicate: 'project_duplicate',
  ProjectVisibility: 'project_visibility',
  EventManage: 'event_manage',
  EventClose: 'event_close',
  EventDuplicate: 'event_duplicate',
  EventVisibility: 'event_visibility',
  OfferManage: 'offer_manage',
  OfferCancel: 'offer_cancel',
  OfferDuplicate: 'offer_duplicate',
  OfferVisibility: 'offer_visibility',
  Messages: 'messages',
  User: 'user',
  SiteCreate: 'site_create',
  SiteMissing: 'site_missing',
  RoleUpdate: 'role_update',
  RoleDestroy: 'role_destroy',
  JobUpdate: 'job_update',
  JobDestroy: 'job_destroy',
  TagNodeUpdate: 'tagNode_update',
  TagNodeDestroy: 'tagNode_destroy',
  TagNodeCreate: 'tagNode_create',
  OrganizationCreate: 'organization_create',
  BiographySuggestions: 'biography_suggestions',
  ExpertiseSuggestions: 'expertise_suggestions',
  Welcome: 'welcome',
  SelectorProjectEvent: 'selector_project_event',
  FiltersPartner: 'filters_partner',
  FiltersEducator: 'filters_educator',
  FiltersProject: 'filters_project',
  FiltersEvent: 'filters_event',
  FiltersOffer: 'filters_offer',
  FiltersStory: 'filters_story',
  QuestionDiscover: 'question_discover',
  QuestionConnect: 'question_connect',
  QuestionDecline: 'question_decline',
  QuestionInvite: 'question_invite',
  QuestionView: 'question_view',
  ClaimDiscover: 'claim_discover',
  ClaimConnect: 'claim_connect',
  ClaimDecline: 'claim_decline',
  ClaimInvite: 'claim_invite',
  ClaimView: 'claim_view',
  InvitationDiscoverMaybe: 'invitation_discover_maybe',
  InvitationDiscoverYes: 'invitation_discover_yes',
  InvitationInvite: 'invitation_invite',
  InvitationMaybe: 'invitation_maybe',
  InvitationNo: 'invitation_no',
  InvitationYes: 'invitation_yes',
  InvitationView: 'invitation_view',
  RedirectConnect: 'redirect_connect',
  ChangeEmail: 'change_email',
  ProfileDelete: 'profile_delete',
  ProfileMissing: 'profile_missing',
  ProfileReactivate: 'profile_reactivate',
}

export const DialogConfig = {
  Project: function(id) {
    return format(DialogKey.Project, {
      projectId: id
    }, undefined, { topAlign: true });
  },
  Event: function(id) {
    return format(DialogKey.Event, {
      eventId: id
    }, undefined, { topAlign: true });
  },
  Offer: function(id) {
    return format(DialogKey.Offer, {
      offerId: id
    }, undefined, { topAlign: true });
  },
  Story: function(id) {
    return format(DialogKey.Story, {
      storyId: id
    }, undefined, { topAlign: true });
  },
  ProjectManage: function(id, route = '/manage') {
    return format(DialogKey.ProjectManage, {
      projectId: id
    }, route, { topAlign: true });
  },
  ProjectClose: function(id) {
    return format(DialogKey.ProjectClose, {
      projectId: id
    });
  },
  ProjectDuplicate: function(id) {
    return format(DialogKey.ProjectDuplicate, {
      projectId: id
    });
  },
  ProjectVisibility: function(id) {
    return format(DialogKey.ProjectVisibility, {
      projectId: id
    });
  },
  EventManage: function(id, route = '/manage') {
    return format(DialogKey.EventManage, {
      eventId: id
    }, route, { topAlign: true });
  },
  EventClose: function(id) {
    return format(DialogKey.EventClose, {
      eventId: id
    });
  },
  EventDuplicate: function(id) {
    return format(DialogKey.EventDuplicate, {
      eventId: id
    });
  },
  EventVisibility: function(id) {
    return format(DialogKey.EventVisibility, {
      eventId: id
    });
  },
  OfferManage: function(id, route = '/manage') {
    return format(DialogKey.OfferManage, {
      offerId: id
    }, route, { topAlign: true });
  },
  OfferCancel: function(id) {
    return format(DialogKey.OfferCancel, {
      offerId: id
    });
  },
  OfferDuplicate: function(id) {
    return format(DialogKey.OfferDuplicate, {
      offerId: id
    });
  },
  OfferVisibility: function(id) {
    return format(DialogKey.OfferVisibility, {
      offerId: id
    });
  },
  Messages: function(id, extra = {}) {
    return format(DialogKey.Messages, {
      userId: id,
      ...extra
    });
  },
  User: function(id, extra = {}) {
    return format(DialogKey.User, {
      userId: id,
      ...extra
    }, undefined, { topAlign: true });
  },
  SiteCreate: function(id) {
    return format(DialogKey.SiteCreate, {
      // userId: id
    });
  },
  SiteMissing: function(id) {
    return format(DialogKey.SiteMissing, {
      // userId: id
    });
  },
  BiographySuggestions: function(id) {
    return format(DialogKey.BiographySuggestions, {
      // userId: id
    });
  },
  ExpertiseSuggestions: function(id) {
    return format(DialogKey.ExpertiseSuggestions, {
      // userId: id
    });
  },
  Welcome: function(id) {
    return format(DialogKey.Welcome, {
      // userId: id
    });
  },
  SelectorProjectEvent: function(id) {
    return format(DialogKey.SelectorProjectEvent, {
      // userId: id
    });
  },
  OrganizationCreate: function(id) {
    return format(DialogKey.OrganizationCreate, {
      // userId: id
    });
  },
  RoleUpdate: function(id) {
    return format(DialogKey.RoleUpdate, {
      roleId: id
    });
  },
  RoleDestroy: function(id) {
    return format(DialogKey.RoleDestroy, {
      roleId: id
    });
  },
  JobUpdate: function(id) {
    return format(DialogKey.JobUpdate, {
      jobId: id
    });
  },
  JobDestroy: function(id) {
    return format(DialogKey.JobDestroy, {
      jobId: id
    });
  },
  TagNodeUpdate: function(id) {
    return format(DialogKey.TagNodeUpdate, {
      tagNodeId: id
    });
  },
  TagNodeDestroy: function(id) {
    return format(DialogKey.TagNodeDestroy, {
      tagNodeId: id
    });
  },
  TagNodeCreate: function(id, extra = {}) {
    return format(DialogKey.TagNodeCreate, {
      tagId: id,
      ...extra
    });
  },
  FiltersPartner: function(id) {
    return format(DialogKey.FiltersPartner, {
      parentDialogId: id,
    });
  },
  FiltersEducator: function(id) {
    return format(DialogKey.FiltersEducator, {
      parentDialogId: id,
    });
  },
  FiltersProject: function(id) {
    return format(DialogKey.FiltersProject, {
      parentDialogId: id,
    });
  },
  FiltersEvent: function(id) {
    return format(DialogKey.FiltersEvent, {
      parentDialogId: id,
    });
  },
  FiltersOffer: function(id) {
    return format(DialogKey.FiltersOffer, {
      parentDialogId: id,
    });
  },
  FiltersStory: function(id) {
    return format(DialogKey.FiltersStory, {
      parentDialogId: id,
    });
  },
  QuestionDiscover: function(id) {
    return format(DialogKey.QuestionDiscover, {
      projectId: id,
    });
  },
  QuestionConnect: function(id) {
    return format(DialogKey.QuestionConnect, {
      questionId: id,
    });
  },
  QuestionDecline: function(id) {
    return format(DialogKey.QuestionDecline, {
      questionId: id,
    });
  },
  QuestionInvite: function(projectId, userId) {
    return format(DialogKey.QuestionInvite, {
      projectId: projectId,
      userId: userId,
    });
  },
  QuestionView: function(id) {
    return format(DialogKey.QuestionView, {
      questionId: id,
    }, undefined, { topAlign: true });
  },
  ClaimDiscover: function(id) {
    return format(DialogKey.ClaimDiscover, {
      offerId: id,
    });
  },
  ClaimConnect: function(id) {
    return format(DialogKey.ClaimConnect, {
      claimId: id,
    });
  },
  ClaimDecline: function(id) {
    return format(DialogKey.ClaimDecline, {
      claimId: id,
    });
  },
  ClaimInvite: function(offerId, userId) {
    return format(DialogKey.ClaimInvite, {
      offerId: offerId,
      userId: userId,
    });
  },
  ClaimView: function(id) {
    return format(DialogKey.ClaimView, {
      claimId: id,
    });
  },
  InvitationDiscoverMaybe: function(id) {
    return format(DialogKey.InvitationDiscoverMaybe, {
      eventId: id,
    });
  },
  InvitationDiscoverYes: function(id) {
    return format(DialogKey.InvitationDiscoverYes, {
      eventId: id,
    });
  },
  InvitationInvite: function(eventId, userId) {
    return format(DialogKey.InvitationInvite, {
      eventId: eventId,
      userId: userId,
    });
  },
  InvitationMaybe: function(id) {
    return format(DialogKey.InvitationMaybe, {
      invitationId: id,
    });
  },
  InvitationNo: function(id) {
    return format(DialogKey.InvitationNo, {
      invitationId: id,
    });
  },
  InvitationYes: function(id) {
    return format(DialogKey.InvitationYes, {
      invitationId: id,
    });
  },
  InvitationView: function(id) {
    return format(DialogKey.InvitationView, {
      invitationId: id,
    });
  },
  RedirectConnect: function(id) {
    return format(DialogKey.RedirectConnect, {
      userId: id,
    });
  },
  ChangeEmail: function(id) {
    return format(DialogKey.ChangeEmail, {
      // userId: id,
    });
  },
  ProfileDelete: function(id) {
    return format(DialogKey.ProfileDelete, {
      // userId: id,
    });
  },
  ProfileMissing: function(id) {
    return format(DialogKey.ProfileMissing, {
      // userId: id,
    });
  },
  ProfileReactivate: function(id) {
    return format(DialogKey.ProfileReactivate, {
      // userId: id,
    });
  },
}

export default DialogKey;
