import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  })

  const eventParticipants = useEventTags(event, TagTypes.Participant);

  if (
    event.state === PayloadStates.FETCHING ||
    eventParticipants.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Title"
      title="What do you want to call this event?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: Keep it short, descriptive, and
          memorable. Partners are more likely to explore more if they understand the title.
        </>
      )}
      examples={(
        <div className="bg-white bg-opacity-100 text-cs-gray-900 max-w-lg">
          <div className="text-base font-semibold mb-1">
            Examples
          </div>
          <ul className="list-disc ml-6 text-base">
            {[
              'First Robotics Mentor',
              'State Science Fair Judge',
              'DECA Advisory Member',
              'PowerHour Tutor'
            ].map(function(text, index) {
              return (
                <li key={index} className="mb-1">
                  {text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    >
      <Form
        user={user}
        event={event}
        navigation={(
          <Navigation v5={true} activeStep={5} steps={13}/>
        )}
        onPrevious={(newData) => {
          _.find(eventParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(event).STUDENT_SIZE) :
            history.push(Urls(event).PARTICIPANTS);
        }}
        onNext={(newData) => history.push(Urls(event).SUMMARY)}
        onSkip={() => history.push(Urls(event).SUMMARY)}
        onExit={() => history.push(Urls(event).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
