import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import PendingBanner from './PendingBanner';
import ConnectBanner from './ConnectBanner';
import DeclineBanner from './DeclineBanner';
import LoadingBanner from '../../../_banner/LoadingBanner';

Banner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { project, onClose } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: currentUser.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === project.data.creatorId;
  const question = questions.data[0];

  if (isCreator) {
    return (
      <CreatorBanner
        project={project}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (!question) {
    return (
      <DiscoverBanner
        project={project}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (question.data.response === 'Pending') {
    return (
      <PendingBanner
        project={project}
        question={question}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (question.data.response === 'Connect') {
    return (
      <ConnectBanner
        project={project}
        creator={creator}
        question={question}
        onClose={onClose}
      />
    );
  }

  if (question.data.response === 'Decline') {
    return (
      <DeclineBanner
        project={project}
        creator={creator}
        question={question}
        onClose={onClose}
      />
    );
  }

  return null;
}
