export default {
    PROFILE:  '/setup/profile',
    NAME:  '/setup/name',
    ZIP_CODE: '/setup/zip-code',
    ORGANIZATION:  '/setup/organization',
    SCHOOL:  '/setup/school',
    TEACHING:  '/setup/teaching',
    PRACTICES:  '/setup/practices',
    CONTACT: '/setup/contact',
    ABOUT: '/setup/about',
    SKILLS: '/setup/skills',
    INTERESTS: '/setup/interests',
    ACTIVITY_TYPES: '/setup/activities',
    EXPERIENCE: '/setup/experience',
    MOTIVATION: '/setup/motivation',
    HOPE: '/setup/hope',
    SOCIAL: '/setup/social',
    DEMOGRAPHICS: '/setup/demographics',
    AVATAR: '/setup/avatar',
    END: '/setup/end',
    NETWORK: '/setup/network',
    THANKS: '/setup/thanks',
    UNAVAILABLE: '/setup/unavailable',
    END_VIEW_PROFILE: (user) => `/profile/view?welcome=show`,
    DASHBOARD: '/'
};
