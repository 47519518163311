/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import {
  BookmarkIcon,
  CalendarIcon,
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  FolderIcon,
  HomeIcon,
  NewspaperIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Network from '../components/Network';
import Account from './Account';
import useRouter from '../hooks/useRouter';
import logo from '../../assets/images/logo.png';
import classNames from 'classnames';
import { useConfig } from '@lore/config';

const myNavigation = [
  { name: 'Home', icon: HomeIcon, to: '/dashboard', matches: ['dashboard'] },
  { name: 'Bookmarks', icon: BookmarkIcon, to: '/bookmarks', matches: ['bookmarks'] },
  { name: 'Projects', icon: FolderIcon, to: '/my-projects', matches: ['my-projects', 'project'] },
  { name: 'Events', icon: CalendarIcon, to: '/my-events', matches: ['my-events', 'event'] },
  { name: 'Offers', icon: NewspaperIcon, to: '/my-offers', matches: ['my-offers', 'offer'] },
  { name: 'Conversations', icon: EnvelopeIcon, to: '/messages', matches: ['messages'] }
];

const exploreNavigation = [
  { name: 'Partners', icon: UsersIcon, to: '/partners-populate', matches: ['partners'] },
  { name: 'Educators', icon: UsersIcon, to: '/educators-populate', matches: ['educators'] },
  { name: 'Projects', icon: FolderIcon, to: '/projects-populate', matches: ['projects'] },
  { name: 'Events', icon: CalendarIcon, to: '/events-populate', matches: ['events'] },
  { name: 'Offers', icon: NewspaperIcon, to: '/offers-populate', matches: ['offers'] },
  { name: 'Impact Stories', icon: ClipboardDocumentListIcon, to: '/stories', matches: ['stories'] }
];

function NavigationLabel(props) {
  const { children } = props;

  return (
    <h3 className="text-cs-gray-900 text-xs font-semibold tracking-wider">
      {children}
    </h3>
  );
}

function NavigationGroup(props) {
  const { children } = props;

  return (
    <div className="px-8 space-y-3">
      {children}
    </div>
  );
}

function NavigationLink(props) {
  const { item } = props;

  const { location } = useRouter();

  const currentRoute = location.pathname;

  function isActive(currentRoute, matches) {
    let isActive = false;

    matches.forEach(function(match) {
      if (isActive) return;

      if (currentRoute.split('/')[1] === match) {
        isActive = true;
      }
    });

    return isActive;
  }

  const current = isActive(currentRoute, item.matches);

  return (
    <div className={classNames(
      // current && '-mx-4'
      '-mx-4'
    )}>
      <Link
        to={item.to}
        className={classNames(
          current ?
            // 'bg-cs-orange-500 bg-opacity-10 text-cs-orange-500' :
            'bg-cs-orange-100 text-cs-orange-500' :
            'bg-white text-cs-gray-500 hover:bg-cs-gray-50 hover:text-cs-gray-500 font-normal',
          'group flex items-center px-4 py-2.5 rounded-cs-10 text-base'
        )}
      >
        <item.icon
          className={classNames(
            current ? 'text-cs-gray-500x text-cs-orange-500' : 'text-cs-gray-500 group-hover:text-cs-gray-500',
            'mr-4 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {item.name}
      </Link>
    </div>
  )
}

export default function Navigation() {
  const config = useConfig();

  return (
    <div className="space-y-12 bg-white">
      <NavigationGroup>
        <Link to="/">
          <img
            className={classNames(
              'h-14',
              config.flags.grayscale && 'grayscale'
            )}
            src={logo}
            alt="logo"
          />
        </Link>
      </NavigationGroup>
      <div className="space-y-8">
        <NavigationGroup>
          <NavigationLabel>
            Your Network
          </NavigationLabel>
          <Network />
        </NavigationGroup>
        <NavigationGroup>
          <NavigationLabel>
            Your Account
          </NavigationLabel>
          <div className="-mx-3">
            <Account />
          </div>
        </NavigationGroup>
        <NavigationGroup>
          <NavigationLabel>
            Your Stuff
          </NavigationLabel>
          <div className="space-y-1">
            {myNavigation.map((item) => (
              <NavigationLink
                key={item.name}
                item={item}
              />
            ))}
          </div>
        </NavigationGroup>
        <NavigationGroup className="flex-grow flex flex-col">
          <NavigationLabel>
            Explore
          </NavigationLabel>
          <div className="space-y-1">
            {exploreNavigation.map((item) => (
              <NavigationLink
                key={item.name}
                item={item}
              />
            ))}
          </div>
        </NavigationGroup>
      </div>
    </div>
  )
}
