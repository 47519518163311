import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import Avatar from '../../components/Avatar';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    creator,
    emailPreview
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={creator.data.avatarUrl} />
        </div>
      )}
      title={`Interested in this offer?`}
      subtitle="Don't worry--you're only committing to talking further about the offer!"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            title={`Tell ${creator.data.firstName} why you want to connect.`}
            subtitle={(
              <>
                For example: to express interest, ask questions, or provide feedback on how
                the offer might be more valuable to you and other educators.
              </>
            )}
          >
            <FormField name="customMessage" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <TextAreaField
                  //label="Description*"
                  placeholder="Custom message..."
                  rows={4}
                  value={field.value}
                  error={field.error}
                  onChange={field.onChange}
                />
              )}
            </FormField>
          </FormSection>
          <FormSection
            title="This is what we'll send to the partner."
            subtitle="Need to make changes? Adjust the text in the box above."
          >
            <div className="bg-white border border-cs-gray-200 shadow-cs-flat rounded-lg h-96 overflow-auto">
              {emailPreview}
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Express Interest
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
