import React from 'react';
import { ArrowRightIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function Option(props) {
  const { title, subtitle, linkText, link, className } = props;

  const temp = {
    icon: props.icon || EnvelopeIcon
  };

  return (
    <div className={classNames(
      link && 'hover:bg-cs-gray-50 cursor-pointer',
      'relative',
      className
    )}>
      {link && (
        <a href={link} target="_blank" className="absolute w-full h-full" />
      )}
      <div className="flex flex-col sm:flex-row p-4 space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
        <div className="flex flex-row sm:flex-col">
          <div className="p-2 bg-cs-blue-500 bg-opacity-10 rounded-lg">
            <temp.icon className="h-8 w-8 text-cs-blue-500" />
          </div>
        </div>
        <div className="flex-1 text-cs-gray-900">
          <div>
            <div className="text-base font-semibold">
              {title}
            </div>
            <div className="text-sm text-cs-gray-500 max-w-3xl">
              {subtitle}
            </div>
          </div>
        </div>
        {linkText && (
          <div className="flex items-start">
            <div className="flex items-center text-cs-blue-500 space-x-1">
              <div className="text-sm">
                {linkText}
              </div>
              <ArrowRightIcon className="h-5 w-5" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
