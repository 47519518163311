import React, { useState } from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import User from '../people-partners/User';
import Loader from '../../components/Loader';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import SectionHeader from '../../components_profiles/SectionHeader';
import { LinkButton, OutlineButton } from '../../components/Button';

export default function Partners(props) {
  const [timestamp] = useState(new Date().toISOString());

  const currentUser = useUser();

  const userBookmarks = useConnect('bookmark.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          'user.isEducator': false,
          // createdAt: {
          //   $lt: timestamp
          // }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (userBookmarks.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Partners"
        subtitle={`${userBookmarks.data.length} bookmarked partners`}
      />
      {userBookmarks.data.length === 0 ? (
        <EmptyState
          title="You have no bookmarked partners"
          subtitle="After bookmarking partners, you will see a list of them here."
        >
          <EmptyStateActions>
            <LinkButton
              button={OutlineButton}
              to="/partners"
              label="Explore partners"
            />
          </EmptyStateActions>
        </EmptyState>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-10">
          {userBookmarks.data.map(function(userBookmark, index) {
            return (
              <UseQueryConnect key={index} _key="user.byId" params={{ id: userBookmark.data.userId }}>
                {function(user) {
                  if (user.state === PayloadStates.FETCHING) {
                    return (
                      <Loader key={index} />
                    );
                  }

                  return (
                    <User
                      user={user}
                    />
                  );
                }}
              </UseQueryConnect>
            );
          })}
        </div>
      )}
    </div>
  );
};
