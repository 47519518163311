import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

DeclineBanner.propTypes = {
  project: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
};

export default function DeclineBanner(props) {
  const { project, question, creator, onClose } = props;

  const launch = useDialogLauncher();
  const color = 'gray';

  function showInterestedDialog(onChange) {
    launch(DialogConfig.QuestionConnect(question.id));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          Have you changed your mind?
        </>
      )}
      subtitle={(
        <>
          You told {creator.data.firstName} you can't help with this project, but click the
          button below if you've changed your mind and would like to help.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton color={color} onClick={showInterestedDialog}>
          I'd like to help
        </ConnectButton>
        {/*<ConnectButton color={color} onClick={() => history.push(`/project/${question.data.projectId}/messages`)}>*/}
        {/*  Go to messages*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
