import React, { Fragment } from 'react';
import _ from 'lodash';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, DocumentDuplicateIcon, EyeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

export default function ActionBarMenu(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  function onChangeVisibility() {
    launch(DialogConfig.EventVisibility(event.id));
  }

  function onDuplicate() {
    launch(DialogConfig.EventDuplicate(event.id));
  }

  const links = _.remove([
    // {
    //   type: 'button',
    //   icon: CheckIcon,
    //   label: event.data.completed ? 'Done' : 'Mark Done',
    //   onClick: onMarkComplete,
    //   active: event.data.completed
    // },
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Duplicate Event',
      onClick: onDuplicate,
      active: false
    },
    // {
    //   type: 'button',
    //   icon: TrashIcon,
    //   label: event.data.cancelled ? 'Cancelled' : 'Cancel',
    //   onClick: onCancel,
    //   active: event.data.cancelled,
    //   activeColor: 'red'
    // },
    {
      type: 'button',
      icon: EyeIcon,
      label: event.data.published ? 'Set Event Visibility' : 'Set Event Visibility',
      onClick: onChangeVisibility,
      active: event.data.published,
      activeColor: 'green'
    }
  ], entry => entry);

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          className={classNames(
            'flex space-x-2 items-center shadow-cs-flat px-4 py-3 rounded-cs-10 cursor-pointer',
            '-m-px border border-cs-gray-200',
            'text-cs-gray-500 bg-white hover:bg-cs-gray-50',
            'relative w-full pr-10'
          )}
        >
          {/*<DotsVerticalIcon className="h-5 w-5" />*/}
          <span className="whitespace-nowrap">
            More actions
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="h-5 w-5 text-cs-gray-400" aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 origin-top absolute right-0 mt-1 w-60 left-0x mt-1x rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1 px-1">
            {links.map(function(link, index) {
              return (
                <Menu.Item key={index}>
                  {({ active }) => {
                    const colorMap = {
                      green: 'text-green-600 bg-green-50 border border-green-500 -m-px',
                      red: 'text-red-600 bg-red-50 border border-red-500 -m-px',
                    };

                    return (
                      <div
                        onClick={link.onClick}
                        className={classNames(
                          // active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                          // 'block px-4 py-2 text-base',
                          active ? 'text-white bg-cs-blue-500 rounded-md' : 'text-cs-gray-500',
                          'cursor-pointer select-none relative py-2 pl-3 pr-9',
                          'flex space-x-2 items-start px-4 py-3',
                        )}
                      >
                        <link.icon className="h-5 w-5 mt-0.5" />
                        <span className="flex-1">
                          {link.label}
                        </span>
                        {/*<div className={classNames(*/}
                        {/*  'h-4 w-4 rounded-full mt-1',*/}
                        {/*  link.active ? colorMap[link.activeColor] || colorMap['green'] : ''*/}
                        {/*)}/>*/}
                      </div>
                    );
                  }}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
};
