import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import MissingFields from './MissingFields';
import Visibility from './Visibility';
import { formatDate, formatDateRange } from '../../utils/dateTimeUtils';

export default function Header(props) {
  const { offer } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const isCreator = currentUser.id === offer.data.creatorId;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-1">
        <div className="text-3xl font-semibold text-cs-gray-900 leading-tight max-w-3xl">
          {offer.data.title || activity.data.name || '...'}
        </div>
        <div className="flex space-x-4">
          <div className="flex space-x-1 items-center text-cs-gray-500">
            <CalendarIcon className="h-6 w-6" />
            {offer.data.specificDate === 'Yes' ? (
              <span>
                {formatDate(offer.data.date, currentUser.data.timezone)}
              </span>
            ) : (
              <span>
                {formatDateRange(offer.data.startDate, offer.data.endDate, currentUser.data.timezone)}
              </span>
            )}
          </div>
          {isCreator && (
            <Visibility offer={offer} />
          )}
        </div>
      </div>
      {isCreator && (
        <MissingFields offer={offer} />
      )}
    </div>
  );
};
