import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import TagCheckboxCards from '../_common/TagCheckboxCards';

Guidances.propTypes = {
  userGuidances: PropTypes.object
};

export default function Guidances(props) {
  const {
    user,
    guidances,
    userGuidances,
    selectable
  } = props;

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleGuidance(guidance) {
    const userGuidance = _.find(userGuidances.data, function(userGuidance) {
      return userGuidance._tag.id === guidance.id;
    });

    if (!userGuidance) {
      mutation_create.mutate({
        userId: user.id,
        tagId: guidance.id
      }, {
        onSuccess: payload => userGuidances._add(payload)
      });
    } else {
      mutation_destroy.mutate(userGuidance._tagNode, {
        onSuccess: payload => userGuidances._remove(payload)
      });
    }
  }

  function onCreateUserGuidance(guidance, userGuidance) {
    launch(DialogConfig.TagNodeCreate(guidance.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserGuidance(guidance, userGuidance) {
    launch(DialogConfig.TagNodeUpdate(userGuidance._tagNode.id));
  }

  function onDestroyUserGuidance(guidance, userGuidance) {
    launch(DialogConfig.TagNodeDestroy(userGuidance._tagNode.id));
  }

  return (
    <Overlay isVisible={saving}>
      <TagCheckboxCards
        tags={guidances}
        facadeTagNodes={userGuidances}
        onChange={function(tag, facadeTagNode) {
          if (facadeTagNode?._tagNode.data.description) {
            return onDestroyUserGuidance(tag, facadeTagNode);
          }

          return onToggleGuidance(tag);
        }}
        onUpdateDescription={onUpdateUserGuidance}
        selectable={selectable}
        describable={false}
      />
    </Overlay>
  );
}
