import React, { useState } from 'react';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import SectionTitle from '../my-projects/SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import Claim from './Claim';
import OfferHeader from './Offer/Header-new';
import { SolidButton } from '../../components/Button';
import LinkButton from '../../components/Button/LinkButton';

export default function Claims(props) {
  const { type = 'attending' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = {
    invited: {
      'offer.cancelled': false,
      response: 'Pending'
    },
    active: {
      'offer.cancelled': false,
      'offer.endDate': {
        $gte: timestamp
      },
      response: {
        $in: [
          'Connect'
        ]
      }
    },
    past: {
      $and: {
        $or: {
          'offer.cancelled': true,
          'offer.endDate': {
            $lte: timestamp
          },
          response: {
            $in: [
              // 'Connect',
              'Decline'
            ]
          }
        }
      }
    }
  };

  const title = {
    invited: 'Invitations',
    active: 'Active',
    past: 'Past'
  };

  const emptyStates = {
    invited: (
      <EmptyState
        title="No invitations"
        subtitle="You have no current invitations to connect from partners."
      />
    ),
    active: (
      <EmptyState
        title="No active offers"
        subtitle="You have not expressed interest in connecting about any active offers."
      >
        <EmptyStateActions>
          <LinkButton
            button={SolidButton}
            to="/offers"
            label="Explore Offers"
          />
        </EmptyStateActions>
      </EmptyState>
    ),
    past: (
      <EmptyState
        title="No past offers"
        subtitle={(
          <>
            Offers that are no longer active will appear here, as well as any offers
            that were completed, cancelled, or that you declined as not being a good fit.
          </>
        )}
      />
    )
  };

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          'offer.creatorId': {
            $not: currentUser.id
          },
          'offer.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: {
            userId: currentUser.id
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (claims.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {claims.data.map(function(claim, index) {
          return (
            <UseQueryConnect key={index} _key="offer.byId" params={{ id: claim.data.offerId }}>
              {function(offer) {
                if (offer.state === PayloadStates.FETCHING) {
                  return (
                    <Loader />
                  );
                }

                return (
                  <Claim
                    claim={claim}
                    alignChildren="top"
                  >
                    <OfferHeader
                      offer={offer}
                      type="claim"
                    />
                  </Claim>
                );
              }}
            </UseQueryConnect>
          );
        })}
      </div>
    </div>
  );
}
