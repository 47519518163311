import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../EditLayout';
import Form from './Form';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const currentUser = useUser();

  return (
    <ParentLayout
      page="Demographics"
      title="Your demographic story."
      description={(
        <div className="space-y-2">
          <div>
            As part of our commitment to diversity, equity, and inclusion, we're seeking to
            better understand the rich diversity of backgrounds of our CommunityShare partners
            and educators.
          </div>
          <div>
            Your privacy is our priority. Any information you share will be kept private and
            secure. We will not share your information unless you give permission to include
            this information on your profile by clicking the checkbox below.
          </div>
          <div>
            You can change this information in the future by revisiting your CommunityShare profile.
          </div>
        </div>
      )}
    >
      <Form
        user={currentUser}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={16}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        // onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        // onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(currentUser).PROFILE_EDIT)}
      />
    </ParentLayout>
  );
};
