import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import Avatar from '../../components/Avatar';
import Loader from '../../components/Loader';
import Card from '../../components/Card';
import OutlineButton from '../../components/Button/OutlineButton.blue';
import mapResponse from '../../utils/mapResponse';

export default function Question(props) {
  const { question, onViewMessages } = props;

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    recipient.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  const isStewardGenerated = (
    creator.id !== project.data.creatorId &&
    question.data.userId !== question.data.creatorId
  );

  return (
    <Card>
      {isStewardGenerated && (
        <div className="px-4 py-1 bg-cs-orange-100">
          <span className="text-sm text-cs-gray-500">
            Connection made by {creator.data.firstName} {creator.data.lastName} (Steward)
          </span>
        </div>
      )}
      <div className="flex p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={recipient.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {recipient.data.firstName} {recipient.data.lastName}
            </div>
            {/*<div className="text-sm text-cs-gray-500">*/}
            {/*  {DateTime.fromISO(question.data.createdAt).toRelative()} • <span className="text-cs-orange-500 font-medium">{question.data.response === 'Connect' ? 'Connected' : question.data.response}</span>*/}
            {/*</div>*/}
            <div className="text-sm text-cs-gray-500 space-x-1">
              <span>{DateTime.fromISO(question.data.createdAt).toRelative()}</span>
              {/*<span>•</span>*/}
              <span>·</span>
              <span className="text-cs-orange-500 font-medium">
                {mapResponse(question.data.response)}
              </span>
              {question.data.response === 'Decline' && (
                <>
                  <span>·</span>
                  <span>{question.data.responseMessage}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="flex items-center">
            {onViewMessages ? (
              <OutlineButton
                label="View messages"
                onClick={() => onViewMessages(recipient)}
              />
            ) : (
              <Link
                to={`/project/${question.data.projectId}/messages/${recipient.id}`}
                className="text-cs-blue-500 border border-cs-blue-500 py-2 px-3 rounded-lg hover:bg-cs-blue-100"
              >
                View messages
              </Link>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
