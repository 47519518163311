import React from 'react';
import { PayloadStates } from '@lore/utils';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { LinkButton, OutlineButton } from '../../components/Button';
import Loader from '../../components/Loader';
import Questions from './Questions';
import SectionTitle from './SectionTitle';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';

export default function ProjectManageDialogLayout(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogConfig.Messages(user.id));
  }

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <SectionTitle
        title="Connected"
        subtitle="Partners who expressed positive interest in the project."
      >
        <div className="grid grid-cols-1 gap-4">
          <Questions
            project={project}
            responses={[
              'Connect'
            ]}
            emptyState={(
              <EmptyState
                title="No partners have expressed interest yet."
                subtitle="The more people you invite, the better your chances of finding a match!"
              >
                <EmptyStateActions>
                  <LinkButton
                    button={OutlineButton}
                    to={`/project/${project.id}/invite-populate`}
                    label="Find Partners"
                  />
                </EmptyStateActions>
              </EmptyState>
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
      <SectionTitle
        title="Invited"
        subtitle="Partners who were invited to connect but have not yet responded."
      >
        <div className="grid grid-cols-1 gap-4">
          <Questions
            project={project}
            responses={[
              'Pending'
            ]}
            emptyState={(
              <EmptyState
                title="You haven't invited anybody yet."
                subtitle="Explore your community and find some Partners to invite to your Project."
              >
                <EmptyStateActions>
                  <LinkButton
                    button={OutlineButton}
                    to={`/project/${project.id}/invite-populate`}
                    label="Find Partners"
                  />
                </EmptyStateActions>
              </EmptyState>
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
      <SectionTitle
        title="Declined"
        subtitle="Partners who are either uninterested or unable to participate."
      >
        <div className="grid grid-cols-1 gap-4">
          <Questions
            project={project}
            responses={[
              'Decline'
            ]}
            emptyState={(
              <EmptyState
                title="Partners who turn down your invitation will appear here."
                // subtitle="Partners who are unable to participate will show up here."
              />
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
    </>
  );
};
