import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Event from '../../pages/event/Event'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

EventDetailsDialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default function EventDetailsDialog(props) {
  const { event } = props;

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
      {/*<div className="px-6 py-6">*/}
        <Event
          event={event}
          onClose={props.onCancel}
        />
      </div>
    </DialogTemplate>
  );
};
