import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import BubbleLayout from '../BubbleLayout';
import SolidButton from '../../../components/Button/SolidButton';
import Loader from '../../../components/Loader';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useNetwork from '../../../hooks/useNetwork';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const launch = useDialogLauncher();

  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const network = useNetwork();

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const backLink = (
    network.data.grants === 'Yes' && project.data.grant === 'Yes' ? Urls(project).BUDGET :
    network.data.grants === 'Yes' && project.data.grant === 'No' ? Urls(project).GRANT :
    Urls(project).RESOURCES
  );

  return (
    <BubbleLayout
      project={project}
      page="Search Explanation"
    >
      <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
        <div className="text-3xl text-cs-gray-900 font-semibold text-center mb-4">
          Congratulations! You've shared your idea with the world.
        </div>
        <div className="text-base text-cs-gray-700 text-center space-y-2 mb-12">
          <div>
            On the next screen, we'll show you some Partners who might be a good fit for your project or event.
          </div>
          <div>
            It's a great idea to take a few minutes now to invite at least a couple of them to start a conversation.
          </div>
          <div>
            You're not alone! CommunityShare and your local steward will begin promoting your idea to potential partners.
          </div>
        </div>
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton onClick={function() {
            // history.push(Urls(project).PROJECT_INVITE);
            history.push(Urls(project).MY_PROJECTS);
            launch(DialogConfig.ProjectManage(project.id, '/invite-populate'))
          }}>
            Next
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 font-semibold" to={backLink}>
            Back
          </Link>
        </div>
      </div>
    </BubbleLayout>
  );
};
