import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

export default function Creator(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
      <UserCircleIcon className="h-6 w-6" />
      <span>
          {creator.data.firstName} {creator.data.lastName}
        </span>
    </div>
  );
};
