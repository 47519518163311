import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '@lore/auth';

export default function Breadcrumbs(props) {
  const currentUser = useUser();

  return (
    <div className="flex w-full pt-3 px-8 -mb-3 bg-white text-cs-gray-500 items-center space-x-1">
      <div>
        Your Stuff
      </div>
      <div>
        {'>'}
      </div>
      <Link to={`/users/${currentUser.id}`} className="text-cs-gray-500 hover:text-cs-orange-500 hover:underline">
        Profile
      </Link>
    </div>
  );

  return (
    <div className="flex w-full py-3 px-8 bg-gradient-to-r from-cs-orange-500 to-cs-yellow-500 text-white items-center space-x-1">
      <div>
        Your Stuff
      </div>
      <div>
        {'>'}
      </div>
      <div>
        Profile
      </div>
    </div>
  );
};
