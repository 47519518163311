import React from 'react';
import PropTypes from 'prop-types';
import useActiveProjectsForUser from '../useActiveProjectsForUser';
import usePastProjectsForUser from '../usePastProjectsForUser';
import useActiveEventsForUser from '../useActiveEventsForUser';
import usePastEventsForUser from '../usePastEventsForUser';
import useActiveOffersForUser from '../useActiveOffersForUser';
import usePastOffersForUser from '../usePastOffersForUser';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Project from '../../projects/Project';
import Event from '../../events/Event';
import ProjectImages from './ProjectImages';
import EventImages from './EventImages';

PastExperiences.propTypes = {
  user: PropTypes.object.isRequired
};

export default function PastExperiences(props) {
  const { user } = props;

  const activeProjects = useActiveProjectsForUser(user);
  const pastProjects = usePastProjectsForUser(user);
  const activeEvents = useActiveEventsForUser(user);
  const pastEvents = usePastEventsForUser(user);
  const activeOffers = useActiveOffersForUser(user);
  const pastOffers = usePastOffersForUser(user);

  if (
    // activeProjects.data.length > 0 ||
    pastProjects.data.length > 0 ||
    // activeEvents.data.length > 0
    pastEvents.data.length > 0
    // activeOffers.data.length > 0 ||
    // pastOffers.data.length > 0 ||
  ) {
    return (
      <div className="space-y-4">
        <SectionHeader
          title="Past Experiences"
          subtitle="Completed projects and events that I've done in the past."
        />
        <div className="grid grid-cols-1 gap-4">
          {pastProjects.data.map(function(project) {
            return (
              <Project
                key={project.id || project.cid}
                project={project}
              >
                <ProjectImages project={project} />
              </Project>
            );
          })}
          {pastEvents.data.map(function(event) {
            return (
              <Event
                key={event.id || event.cid}
                event={event}
              >
                <EventImages event={event} />
              </Event>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
