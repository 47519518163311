import React from 'react';
import ProtectedCreateButton from './ProtectedCreateButton';
import EmptyState, { EmptyStateActions } from '../../../components/EmptyState';
import { LinkButton, OutlineButton } from '../../../components/Button';

export default function EmptyEvent(props) {
  return (
    <EmptyState
      title="You don't have any Events yet!"
      subtitle="Create a event or explore current events from other Educators for inspiration."
    >
      <EmptyStateActions>
        <LinkButton
          button={OutlineButton}
          to="/events"
          label="Explore events"
        />
        <ProtectedCreateButton />
      </EmptyStateActions>
    </EmptyState>
  );
}
