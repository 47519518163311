import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Event from '../../pages/event/Event';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';

EventViewDialog.propTypes = {
  invitation: PropTypes.object.isRequired
};

export default function EventViewDialog(props) {
  const { invitation } = props;

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-lg">
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
      <Event
        event={event}
        onClose={props.onCancel}
      />
      </div>
    </DialogTemplate>
  );
};
