import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import QuestionViewDialog from '../../dialogs/question.view';

export default function Launcher(props) {
  const config = useDialogConfig();

  const question = useConnect('question.byId', {
    id: config.params.questionId
  });

  if (question.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <QuestionViewDialog
      question={question}
      onCancel={props.onClose}
    />
  );
};
