import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Project from './Project';
import SectionTitle from './SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import ProtectedCreateButton from './EmptyProject/ProtectedCreateButton';
import getBucketParams from './getBucketParams';
import OutlineButton from '../../components/Button/OutlineButton';
import LinkButton from '../../components/Button/LinkButton';

export default function Projects(props) {
  const { type = 'active' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = getBucketParams(timestamp);

  const title = {
    expired: 'Project Check-In',
    active: 'Active',
    draft: 'Draft',
    past: 'Past'
  };

  const subtitle = {
    expired: (
      <div className="space-y-2">
        <div>
          We'd love to hear about your project's progress! Please update your project
          progress (in less than a minute) so we know how to best support you!
        </div>
        <div>
          Finished with your project? That's great! Let's celebrate your achievement together.
        </div>
        <div>
          If finishing your project this year seems challenging, that's okay! Briefly tell us
          why and we'd be happy to support and help you with your next idea.
        </div>
        <div>
          Need a bit more time? No problem! Just update your project deadline and we'll make
          sure community partners can still see it.
        </div>
      </div>
    )
  };

  const emptyStates = {
    expired: null,
    active: (
      <EmptyState
        title="No active projects"
        subtitle="Get started by creating a project or exploring current projects by educators."
      >
        <EmptyStateActions>
          <LinkButton
            button={OutlineButton}
            to="/projects"
            label="Explore projects"
          />
          <ProtectedCreateButton />
        </EmptyStateActions>
      </EmptyState>
    ),
    draft: (
      <EmptyState
        title="No draft projects"
        subtitle="Projects that are missing required fields will be shown here."
      />
    ),
    past: (
      <EmptyState
        title="No past projects"
        subtitle={(
          <>
            Projects that are no longer active will appear here, as well as any projects
            that were completed or cancelled.
          </>
        )}
      />
    )
  };

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $exists: true
          },
          createdAt: {
            $lte: timestamp
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (projects.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle subtitle={subtitle[type]}>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (projects.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle subtitle={subtitle[type]}>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle subtitle={subtitle[type]}>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {projects.data.map(function(project, index) {
          if (project.state === PayloadStates.FETCHING) {
            return (
              <Loader key={index} />
            );
          }

          return (
            <Project
              key={index}
              project={project}
            />
          );
        })}
      </div>
    </div>
  );
}
