import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

NoBanner.propTypes = {
  event: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired
};

export default function NoBanner(props) {
  const { event, invitation, creator, onClose } = props;

  const launch = useDialogLauncher();
  const color = 'gray';

  function showYesDialog(onChange) {
    launch(DialogConfig.InvitationYes(invitation.id));
  }

  function showMaybeDialog(onChange) {
      launch(DialogConfig.InvitationMaybe(invitation.id));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          Have you changed your mind?
        </>
      )}
      subtitle={(
        <>
          You told {creator.data.firstName} you can't help with this event, but click the
          button below if you've changed your mind and would like to help.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          Maybe, but I have some questions first
        </ConnectButton>
        <ConnectButton gray={true} onClick={showYesDialog}>
          Yes, I will attend
        </ConnectButton>
        {/*<ConnectButton color={color} onClick={() => history.push(`/event/${invitation.data.eventId}/messages`)}>*/}
        {/*  Go to messages*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
