import React from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

export default function Organization(props) {
  const { project } = props;

  const site = useConnect('site.byId', {
    id: project.data.siteId
  }, {
    enabled: !!project.data.siteId
  });

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
      <BuildingOfficeIcon className="h-6 w-6" />
      <span>
        {site.data.name || '[missing]'}
      </span>
    </div>
  );
};
