import React from 'react';
import { useConnect } from '@lore/query-connect';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useRouter from '../../hooks/useRouter';
import Conversation from './Conversation';
import EmptyState from '../../components/EmptyState';
import useConversations from './useConversations';
import { useUser } from '@lore/auth';
import Text from '../../typography';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Messages</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Your Conversations
      </div>
      {children}
    </div>
  )
}

export default function Layout(props) {
  const { match, history } = useRouter();

  const currentUser = useUser();

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          $or: {
            senderId: currentUser.id,
            recipientId: currentUser.id
          }
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  const conversations = useConversations();

  if (
    messages.state === PayloadStates.FETCHING ||
    conversations.state === PayloadStates.FETCHING
  ) {
    return (
      <Header>
        <Loader />
      </Header>
    );
  }

  if (conversations.data.length === 0) {
    return (
      <Header>
        <EmptyState
          title="You have no message history"
          subtitle="Once you connect with educators and partners your conversations will appear here"
        />
      </Header>
    );
  }

  return (
    <Header>
      <div className="flex flex-col space-y-4">
        <Text.Lg>
          All conversations
        </Text.Lg>
        <div className="w-full grid grid-cols-1 gap-3">
          {conversations.data.map(function renderConversation(conversation) {
            const selected = conversation.data.userId === Number(match.params.userId);

            return (
              <Conversation
                key={conversation.data.key}
                conversation={conversation}
                selected={selected}
              />
            );
          })}
        </div>
        {props.children}
      </div>
    </Header>
  );
};
