import React from 'react';
import { useUser } from '@lore/auth';
import WelcomeBanner from '../../components/WelcomeBanner';
import ParentLayout from '../profile/ProfileLayout';
import Sleeping from './Sleeping';
import ProfileType from './ProfileType';
import DeleteAccount from './DeleteAccount';
import ChangeEmail from './ChangeEmail';
import Community from './Community';

export default function Layout() {
  const user = useUser();

  return (
    <ParentLayout page="Settings">
      <div className="p-8 space-y-8">
        <WelcomeBanner
          title="Settings"
          subtitle="Sleep your profile, change your profile type, or delete your account."
        />
        <ChangeEmail user={user} />
        <Sleeping user={user} />
        <ProfileType user={user} />
        <Community user={user} />
        <DeleteAccount user={user} />
      </div>
    </ParentLayout>
  );
};
