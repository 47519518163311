import Project from '../_launchers/project';
import Event from '../_launchers/event';
import Offer from '../_launchers/offer';
import Story from '../_launchers/story';
import ProjectManage from '../_launchers/project_manage';
import ProjectClose from '../_launchers/project_close';
import ProjectDuplicate from '../_launchers/project_duplicate';
import ProjectVisibility from '../_launchers/project_visibility';
import EventManage from '../_launchers/event_manage';
import EventClose from '../_launchers/event_close';
import EventDuplicate from '../_launchers/event_duplicate';
import EventVisibility from '../_launchers/event_visibility';
import OfferManage from '../_launchers/offer_manage';
import OfferCancel from '../_launchers/offer_cancel';
import OfferDuplicate from '../_launchers/offer_duplicate';
import OfferVisibility from '../_launchers/offer_visibility';
import Messages from '../_launchers/messages';
import User from '../_launchers/user';
import SiteCreate from '../_launchers/site_create';
import SiteMissing from '../_launchers/site_missing';
import OrganizationCreate from '../_launchers/organization_create';
import BiographySuggestions from '../_launchers/biography_suggestions';
import ExpertiseSuggestions from '../_launchers/expertise_suggestions';
import RoleUpdate from '../_launchers/role_update';
import RoleDestroy from '../_launchers/role_destroy';
import JobUpdate from '../_launchers/job_update';
import JobDestroy from '../_launchers/job_destroy';
import TagNodeUpdate from '../_launchers/tagNode_update';
import TagNodeDestroy from '../_launchers/tagNode_destroy';
import TagNodeCreate from '../_launchers/tagNode_create';
import FiltersPartner from '../_launchers/filters_partner';
import FiltersEducator from '../_launchers/filters_educator';
import FiltersProject from '../_launchers/filters_project';
import FiltersEvent from '../_launchers/filters_event';
import FiltersOffer from '../_launchers/filters_offer';
import FiltersStory from '../_launchers/filters_story';
import Welcome from '../_launchers/welcome';
import SelectorProjectEvent from '../_launchers/selector_project_event';
import QuestionDiscover from '../_launchers/question_discover';
import QuestionConnect from '../_launchers/question_connect';
import QuestionDecline from '../_launchers/question_decline';
import QuestionInvite from '../_launchers/question_invite';
import QuestionView from '../_launchers/question_view';
import ClaimDiscover from '../_launchers/claim_discover';
import ClaimConnect from '../_launchers/claim_connect';
import ClaimDecline from '../_launchers/claim_decline';
import ClaimInvite from '../_launchers/claim_invite';
import ClaimView from '../_launchers/claim_view';
import InvitationDiscoverMaybe from '../_launchers/invitation_discover_maybe';
import InvitationDiscoverYes from '../_launchers/invitation_discover_yes';
import InvitationInvite from '../_launchers/invitation_invite';
import InvitationMaybe from '../_launchers/invitation_maybe';
import InvitationNo from '../_launchers/invitation_no';
import InvitationYes from '../_launchers/invitation_yes';
import InvitationView from '../_launchers/invitation_view';
import RedirectConnect from '../_launchers/redirect_connect';
import ChangeEmail from '../_launchers/change_email';
import ProfileDelete from '../_launchers/profile_delete';
import ProfileMissing from '../_launchers/profile_missing';
import ProfileReactivate from '../_launchers/profile_reactivate';
import DialogKey from './DialogKey';

export default {
  [DialogKey.Project]: Project,
  [DialogKey.Event]: Event,
  [DialogKey.Offer]: Offer,
  [DialogKey.ProjectManage]: ProjectManage,
  [DialogKey.ProjectClose]: ProjectClose,
  [DialogKey.ProjectDuplicate]: ProjectDuplicate,
  [DialogKey.ProjectVisibility]: ProjectVisibility,
  [DialogKey.EventManage]: EventManage,
  [DialogKey.EventClose]: EventClose,
  [DialogKey.EventDuplicate]: EventDuplicate,
  [DialogKey.EventVisibility]: EventVisibility,
  [DialogKey.OfferManage]: OfferManage,
  [DialogKey.OfferCancel]: OfferCancel,
  [DialogKey.OfferDuplicate]: OfferDuplicate,
  [DialogKey.OfferVisibility]: OfferVisibility,
  [DialogKey.Story]: Story,
  [DialogKey.Messages]: Messages,
  [DialogKey.User]: User,
  [DialogKey.SiteCreate]: SiteCreate,
  [DialogKey.SiteMissing]: SiteMissing,
  [DialogKey.OrganizationCreate]: OrganizationCreate,
  [DialogKey.RoleUpdate]: RoleUpdate,
  [DialogKey.RoleDestroy]: RoleDestroy,
  [DialogKey.JobUpdate]: JobUpdate,
  [DialogKey.JobDestroy]: JobDestroy,
  [DialogKey.TagNodeUpdate]: TagNodeUpdate,
  [DialogKey.TagNodeDestroy]: TagNodeDestroy,
  [DialogKey.TagNodeCreate]: TagNodeCreate,
  [DialogKey.BiographySuggestions]: BiographySuggestions,
  [DialogKey.ExpertiseSuggestions]: ExpertiseSuggestions,
  [DialogKey.Welcome]: Welcome,
  [DialogKey.SelectorProjectEvent]: SelectorProjectEvent,
  [DialogKey.FiltersPartner]: FiltersPartner,
  [DialogKey.FiltersEducator]: FiltersEducator,
  [DialogKey.FiltersProject]: FiltersProject,
  [DialogKey.FiltersEvent]: FiltersEvent,
  [DialogKey.FiltersOffer]: FiltersOffer,
  [DialogKey.FiltersStory]: FiltersStory,
  [DialogKey.QuestionDiscover]: QuestionDiscover,
  [DialogKey.QuestionConnect]: QuestionConnect,
  [DialogKey.QuestionDecline]: QuestionDecline,
  [DialogKey.QuestionInvite]: QuestionInvite,
  [DialogKey.QuestionView]: QuestionView,
  [DialogKey.ClaimDiscover]: ClaimDiscover,
  [DialogKey.ClaimConnect]: ClaimConnect,
  [DialogKey.ClaimDecline]: ClaimDecline,
  [DialogKey.ClaimInvite]: ClaimInvite,
  [DialogKey.ClaimView]: ClaimView,
  [DialogKey.InvitationDiscoverMaybe]: InvitationDiscoverMaybe,
  [DialogKey.InvitationDiscoverYes]: InvitationDiscoverYes,
  [DialogKey.InvitationInvite]: InvitationInvite,
  [DialogKey.InvitationMaybe]: InvitationMaybe,
  [DialogKey.InvitationNo]: InvitationNo,
  [DialogKey.InvitationYes]: InvitationYes,
  [DialogKey.InvitationView]: InvitationView,
  [DialogKey.RedirectConnect]: RedirectConnect,
  [DialogKey.ChangeEmail]: ChangeEmail,
  [DialogKey.ProfileDelete]: ProfileDelete,
  [DialogKey.ProfileMissing]: ProfileMissing,
  [DialogKey.ProfileReactivate]: ProfileReactivate,
}
