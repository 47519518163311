import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import LoadingBanner from '../../../_banner/LoadingBanner';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import PendingBanner from './PendingBanner';
import ConnectBanner from './ConnectBanner';
import DeclineBanner from './DeclineBanner';

Banner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { offer, onClose } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          userId: currentUser.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === offer.data.creatorId;
  const claim = claims.data[0];

  if (isCreator) {
    return (
      <CreatorBanner
        offer={offer}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (!claim) {
    return (
      <DiscoverBanner
        offer={offer}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (claim.data.response === 'Pending') {
    return (
      <PendingBanner
        offer={offer}
        claim={claim}
        creator={creator}
        onClose={onClose}
      />
    );
  }

  if (claim.data.response === 'Connect') {
    return (
      <ConnectBanner
        offer={offer}
        creator={creator}
        claim={claim}
        onClose={onClose}
      />
    );
  }

  if (claim.data.response === 'Decline') {
    return (
      <DeclineBanner
        offer={offer}
        creator={creator}
        claim={claim}
        onClose={onClose}
      />
    );
  }

  return null;
}
