import React from 'react';
import classNames from 'classnames';

export default function BannerActionTemplate(props) {
  const {
    label,
    onClick,
    theme = 'purple'
  } = props;

  const textColor = {
    green: 'text-green-600',
    red: 'text-red-600',
    purple: 'text-cs-purple-600'
  }[theme];

  return (
    <div className={classNames(
      'text-base font-semibold',
      textColor,
      onClick && 'cursor-pointer hover:text-opacity-70'
    )} onClick={onClick}>
      {label}
    </div>
  );
}
