import React, { useState } from 'react';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Question from './Question';
import ProjectHeader from './Project/Header-new';
import SectionTitle from './SectionTitle';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import LinkButton from '../../components/Button/LinkButton';
import { SolidButton } from '../../components/Button';

export default function Projects(props) {
  const { type = 'attending' } = props;

  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const params = {
    invited: {
      'project.cancelled': false,
      response: 'Pending'
    },
    active: {
      'project.cancelled': false,
      'project.endDate': {
        $gte: timestamp
      },
      response: {
        $in: [
          'Connect'
        ]
      }
    },
    past: {
      $and: {
        $or: {
          'project.cancelled': true,
          'project.endDate': {
            $lte: timestamp
          },
          response: {
            $in: [
              // 'Connect',
              'Decline'
            ]
          }
        }
      }
    }
  };

  const title = {
    invited: 'Invitations',
    active: 'Active',
    past: 'Past'
  };

  const emptyStates = {
    invited: (
      <EmptyState
        title="No invitations"
        subtitle="You have no current invitations to connect from educators."
      />
    ),
    active: (
      <EmptyState
        title="No active projects"
        subtitle="You have not expressed interest in connecting about any active projects."
      >
        <EmptyStateActions>
          <LinkButton
            button={SolidButton}
            to="/projects"
            label="Explore Projects"
          />
        </EmptyStateActions>
      </EmptyState>
    ),
    past: (
      <EmptyState
        title="No past projects"
        subtitle={(
          <>
            Projects that are no longer active will appear here, as well as any projects
            that were completed, cancelled, or that you declined as not being a good fit.
          </>
        )}
      />
    )
  };

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          'project.creatorId': {
            $not: currentUser.id
          },
          'project.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: {
            userId: currentUser.id
          },
          ...params[type]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (questions.data.length === 0) {
    return emptyStates[type] ? (
      <div className="space-y-4">
        <SectionTitle>
          {title[type]}
        </SectionTitle>
        {emptyStates[type]}
      </div>
    ) : null;
  }

  return (
    <div className="space-y-4">
      <SectionTitle>
        {title[type]}
      </SectionTitle>
      <div className="grid grid-cols-1 gap-4">
        {questions.data.map(function(question, index) {
          return (
            <UseQueryConnect key={index} _key="project.byId" params={{ id: question.data.projectId }}>
              {function(project) {
                if (project.state === PayloadStates.FETCHING) {
                  return (
                    <Loader />
                  );
                }

                return (
                  <Question
                    question={question}
                    alignChildren="top"
                  >
                    <ProjectHeader
                      project={project}
                      type="question"
                    />
                  </Question>
                );
              }}
            </UseQueryConnect>
          );
        })}
      </div>
    </div>
  );
}
