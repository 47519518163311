import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Project from '../../pages/project/Project';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';

QuestionViewDialog.propTypes = {
  question: PropTypes.object.isRequired
};

export default function QuestionViewDialog(props) {
  const { question } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-lg">
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
      <Project
        project={project}
        onClose={props.onCancel}
      />
      </div>
    </DialogTemplate>
  );
};
