import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

PendingBanner.propTypes = {
  project: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
};

export default function PendingBanner(props) {
  const { project, question, creator, onClose } = props;

  const launch = useDialogLauncher();

  function showInterestedDialog(onChange) {
    launch(DialogConfig.QuestionConnect(question.id));
  }

  function showDeclineDialog(onChange) {
    launch(DialogConfig.QuestionDecline(question.id));
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          You've been invited!
        </>
      )}
      subtitle={(
        <>
          Once you let {creator.data.firstName} know you're interested, you can start
          asking questions and offering ideas or even refer somebody else who might be
          a good fit.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showDeclineDialog}>
          Sorry, I can't help right now
        </ConnectButton>
        <ConnectButton onClick={showInterestedDialog}>
          I'm interested
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
