import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';

export default function Type(props) {
  const { title, description, icon = SparklesIcon } = props;

  return (
    <div
      className="flex bg-white shadow rounded-xl overflow-hidden p-6 space-x-5"
    >
      <div>
        <div className="bg-cs-yellow-500 p-3 rounded-full">
          <props.icon className="w-8 h-8 text-white" />
        </div>
      </div>
      <div className="flex-1">
        <h2 className="font-semibold text-cs-gray-900">
          {title}
        </h2>
        <div className="text-sm font-light">
          {description}
        </div>
      </div>
    </div>
  );
}
