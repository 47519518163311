import React from 'react';
import ProtectedCreateButton from './ProtectedCreateButton';
import EmptyState, { EmptyStateActions } from '../../../components/EmptyState';
import OutlineButton from '../../../components/Button/OutlineButton';
import { LinkButton } from '../../../components/Button';

export default function EmptyProject(props) {
  return (
    <EmptyState
      title="You don't have any Projects yet!"
      subtitle="Create a project or explore current projects from other Educators for inspiration."
    >
      <EmptyStateActions>
        <LinkButton
          button={OutlineButton}
          to="/projects"
          label="Explore projects"
        />
        <ProtectedCreateButton />
      </EmptyStateActions>
    </EmptyState>
  );
}
