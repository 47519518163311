import React from 'react';
import { DateTime } from 'luxon';

export default function ExpiredTag(props) {
  const { resource } = props;

  const diff = DateTime.now().diff(DateTime.fromISO(resource.data.endDate)).as('days');

  if (
    resource.data.completed ||
    resource.data.cancelled ||
    diff < 0
  ) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cs-blue-100 text-cs-blue-800">
      PAST
    </span>
  );
}
