import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Student Count"
      title="How many students will participate in or be impacted by this project?"
      // description={(
      //   <>
      //     <span className="font-semibold italic">TIP</span>: Some Partners are passionate about working
      //     with students who have certain life experiences. This is another way to find the best match
      //     for your students.
      //   </>
      // )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={6} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).PARTICIPANTS)}
        onNext={(newData) => history.push(Urls(project).STUDENT_AGE)}
        onSkip={() => history.push(Urls(project).STUDENT_AGE)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
