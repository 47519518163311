import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import SectionTitle from './SectionTitle';
import EvaluationProjectEducator from './EvaluationProjectEducator';
import EvaluationProjectPartner from './EvaluationProjectPartner';
import EvaluationEventEducator from './EvaluationEventEducator';
import EvaluationEventPartner from './EvaluationEventPartner';

export default function Evaluations(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const title = 'Your Feedback & Evaluations';
  const subtitle = (
    <>
      Your project may be complete, but the impact continues! Take a moment to reflect
      on your experience and share your insights through our evaluation survey (it only
      takes about 15 minutes!). This is your chance to share your learning journey and
      contribute your experience and ideas to our community. Your insights are essential
      in helping us continue this important work and build a stronger program that supports
      educators, students, and the community for years to come. Whether your project was a
      remarkable success or a valuable learning experience, your thoughts are valuable!
    </>
  );
  // const link = (
  //   <Link to="/messages" className="text-cs-blue-500 text-lg">
  //     Inbox
  //   </Link>
  // );

  const params = {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          createdAt: {
            $lte: timestamp
          },
          completed: false
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  };

  const evaluationProjectEducators = useConnect('evaluationProjectEducator.find', params);
  const evaluationProjectPartners = useConnect('evaluationProjectPartner.find', params);
  const evaluationEventEducators = useConnect('evaluationEventEducator.find', params);
  const evaluationEventPartners = useConnect('evaluationEventPartner.find', params);

  if (
    evaluationProjectEducators.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.state === PayloadStates.FETCHING ||
    evaluationEventEducators.state === PayloadStates.FETCHING ||
    evaluationEventPartners.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          // link={link}
        />
        <div>
          <Loader className="mx-7" />
        </div>
      </div>
    );
  }

  if (
    evaluationProjectEducators.data.length === 0 &&
    evaluationProjectPartners.data.length === 0 &&
    evaluationEventEducators.data.length === 0 &&
    evaluationEventPartners.data.length === 0
  ) {
    return null;
    // return (
    //   <div>
    //     <SectionTitle
    //       title={title}
    //       subtitle={subtitle}
    //     />
    //     <EmptyState
    //       title="You have no pending evaluations."
    //     />
    //   </div>
    // );
  }

  return (
    <div>
      <SectionTitle
        title={title}
        subtitle={subtitle}
        // link={link}
      />
      <div className="grid grid-cols-1 gap-4">
        {evaluationProjectEducators.data.map(function(evaluation) {
          return (
            <EvaluationProjectEducator
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationProjectPartners.data.map(function(evaluation) {
          return (
            <EvaluationProjectPartner
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationEventEducators.data.map(function(evaluation) {
          return (
            <EvaluationEventEducator
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationEventPartners.data.map(function(evaluation) {
          return (
            <EvaluationEventPartner
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
      </div>
    </div>
  );
}
