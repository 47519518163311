import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import TagCheckboxCards from '../_common/TagCheckboxCards';

Experiences.propTypes = {
  userExperiences: PropTypes.object
};

export default function Experiences(props) {
  const {
    user,
    experiences,
    userExperiences,
    selectable
  } = props;

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleExperience(experience) {
    const userExperience = _.find(userExperiences.data, function(userExperience) {
      return userExperience._tag.id === experience.id;
    });

    if (!userExperience) {
      mutation_create.mutate({
        userId: user.id,
        tagId: experience.id
      }, {
        onSuccess: payload => userExperiences._add(payload)
      });
    } else {
      mutation_destroy.mutate(userExperience._tagNode, {
        onSuccess: payload => userExperiences._remove(payload)
      });
    }
  }

  function onCreateUserExperience(experience, userExperience) {
    launch(DialogConfig.TagNodeCreate(experience.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserExperience(experience, userExperience) {
    launch(DialogConfig.TagNodeUpdate(userExperience._tagNode.id));
  }

  function onDestroyUserExperience(experience, userExperience) {
    launch(DialogConfig.TagNodeDestroy(userExperience._tagNode.id));
  }

  return (
    <Overlay isVisible={saving}>
      <TagCheckboxCards
        tags={experiences}
        facadeTagNodes={userExperiences}
        onChange={function(tag, facadeTagNode) {
          if (facadeTagNode?._tagNode.data.description) {
            return onDestroyUserExperience(tag, facadeTagNode);
          }

          return onToggleExperience(tag);
        }}
        onUpdateDescription={onUpdateUserExperience}
        selectable={selectable}
      />
    </Overlay>
  );
}
