import React from 'react';
import VirtualAvatar from '../../components/User/VirtualAvatar';
import classNames from 'classnames';

export default function Wrapper(props) {
  const {
    title,
    subtitle,
    creator,
    children,
    gray = false,
    color = 'accent'
  } = props;

  const colorMap = {
    bg: {
      accent: 'bg-cs-orange-500',
      primary: 'bg-cs-yellow-500',
      blue: 'bg-cs-blue-500',
      green: 'bg-cs-green-500',
      purple: 'bg-cs-purple-500',
      gray: 'bg-cs-gray-500'
    }
  };

  return (
    <div className={classNames(
      'flex flex-col sm:flex-row items-center rounded-xl shadow-lg p-8 space-x-0 space-y-6 sm:space-x-10 sm:space-y-0',
      // gray ? 'bg-cs-gray-500' : 'bg-cs-orange-500',
      gray ? colorMap.bg['gray'] : colorMap.bg[color] || 'bg-cs-orange-500',
    )}>
      <div>
        <VirtualAvatar size={36} user={creator} />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-3xl text-white font-semibold mb-2">
          {title}
        </div>
        <div className="text-white mb-4 max-w-xl">
          {subtitle}
        </div>
        {children}
      </div>
    </div>
  );
}
