import React from 'react';
import Partners from './Partners';
import DateRange from './DateRange';
import Creator from './Creator';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import Cost from './Cost';
import classNames from 'classnames';

export default function Header(props) {
  const { offer, children, type = 'offer', onClick, showTags = true } = props;

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {showTags && (
          <StatusTags offer={offer} />
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(offer) : undefined}
        >
          <Title offer={offer} />
          <Description offer={offer} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <DateRange offer={offer} />
          {type === 'claim' && (
            <Creator offer={offer} />
          )}
          <Cost offer={offer} />
          {/*<Organization offer={offer} />*/}
        </div>
        {type === 'offer' && (
          <Partners offer={offer} />
        )}
      </div>
    </div>
  );
}
