import React, { Fragment } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { CameraIcon, ChatBubbleLeftRightIcon, MapIcon, TvIcon, UsersIcon } from '@heroicons/react/24/outline';
import useNetwork from '../../hooks/useNetwork';
import Loader from '../../components/Loader';
import Section from './Section';
import Option from './Option';
import { useUser } from '@lore/auth';
import Card from '../../components/Card';
import Divider from '../../forms/_fields_v2/Divider';

export default function Services(props) {
  const network = useNetwork();

  const currentUser = useUser();

  const stewards = useConnect('steward.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id
        }
      }
    }
  });

  const services = useConnect('service.find', {
    pagination: {
      pageSize: 100,
      order: 'index asc'
    }
  });

  const stewardServices = useConnect('stewardService.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id,
          stewardId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (
    stewards.state === PayloadStates.FETCHING ||
    services.state === PayloadStates.FETCHING ||
    stewardServices.state === PayloadStates.FETCHING
  ) {
    return (
      <Section
        title="What can we help you with today?"
      >
        <Loader />
      </Section>
    );
  }

  if (stewardServices.data.length === 0) {
    return null;
  }

  const icons = {
    ChatBubbleLeftRightIcon,
    MapIcon,
    UsersIcon,
    CameraIcon,
    TvIcon
  };

  return (
    <Section
      title="What can we help you with today?"
    >
      <Card>
        {services.data.map(function(service, index) {
          const stewardService = _.find(stewardServices.data, stewardService => stewardService.data.serviceId === service.id);

          if (!stewardService) {
            return null;
          }

          const steward = _.find(stewards.data, steward => steward.id === stewardService.data.stewardId);

          if (!steward) {
            return null;
          }

          // using the icons to map the email template with fallback provided below
          const placeholder = {
            ChatBubbleLeftRightIcon: {
              subject: `Getting Started with CommunityShare`,
              body: [
                `Dear ${steward.data.firstName},`,
                `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I heard about CommunityShare from...`,
                `I'm not quite sure how to start and would like to talk with you about next steps. Some convenient times for me to talk are...`
              ].join('\n\n')
            },
            MapIcon: {
              subject: `CommunityShare Idea Consultation`,
              body: [
                `Dear ${steward.data.firstName},`,
                `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I have an idea for collaborating with community members that I'd like to talk over with you. Some convenient times for me to talk are...`
              ].join('\n\n')
            },
            UsersIcon: {
              subject: `CommunityShare Partner Recruitment Desired`,
              body: [
                `Dear ${steward.data.firstName},`,
                `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I am looking for a partner who...`
              ].join('\n\n')
            },
            CameraIcon: {
              subject: `CommunityShare Photographer/Videographer Desired`,
              body: [
                `Dear ${steward.data.firstName},`,
                `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I am looking to document a project I have planned. Ideally, I'd need someone to photograph/take video at location on the following date and time...`
              ].join('\n\n')
            },
            TvIcon: {
              subject: `CommunityShare Media Coverage Desired`,
              body: [
                `Dear ${steward.data.firstName},`,
                `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I am looking to publicize a project. Ideally, I'm interested in getting media coverage of my planned project at location on the following date and time...`
              ].join('\n\n')
            }
          };

          const subject = placeholder[service.data.icon]?.subject || 'Question about CommunityShare';
          const body = placeholder[service.data.icon]?.body || [
            `Dear ${steward.data.firstName},`,
            `My name is ${currentUser.data.firstName} ${currentUser.data.lastName} and I have a question about CommunityShare.`
          ].join('\n\n');

          const placeholderLink = [
            `mailto:${steward.data.email}`,
            `?subject=`,
            encodeURIComponent(subject),
            `&body=`,
            encodeURIComponent(body)
          ].join('');

          return (
            <Fragment key={index}>
              <Option
                icon={icons[service.data.icon]}
                title={service.data.title}
                subtitle={service.data.description}
                linkText={stewardService.data.title || `Email ${steward.data.firstName}`}
                link={stewardService.data.url || placeholderLink}
              />
              {(
                (index + 1) < services.data.length
              ) && (
                <div className="px-4">
                  <Divider />
                </div>
              )}
            </Fragment>
          );
        })}
      </Card>
    </Section>
  );
};
