import React from 'react';
import { Helmet } from 'react-helmet-async';
import Offers from './Offers';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Offers</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Explore Offers
      </div>
      <Offers
        router={router}
      />
    </div>
  );
}
