import React from 'react';
import { useUser } from '@lore/auth';
import useRouter from '../../hooks/useRouter';
import Name from './Name';
import About from './About';
import ZipCode from './ZipCode';
import Timezone from './Timezone';
import Organizations from './Organizations';
import Sites from './Sites';
import Subjects from './Subjects';
import Website from './Website';
import LinkedIn from './LinkedIn';
import Twitter from './Twitter';
import Grades from './Grades';
import Practices from './Practices';
import Demographics from './Demographics';
import Phone from './Phone';
import Opportunities from './Opportunities';
import Avatar from './Avatar';
import Skills from './Skills';
import Ages from './Ages';
import Activities from './Activities';
import Groups from './Groups';
import Experiences from './Experiences';
import ExperienceCards from './ExperienceCards';
import Guidances from './Guidances';
import Section from './Section';
import Divider from '../../forms/_fields_v2/Divider';
import { Helmet } from 'react-helmet-async';
import MissingFields from '../profile/MissingFields';
import GuidanceCards from './GuidanceCards';
import ExpertiseCards from './ExpertiseCards';

export default function Layout(props) {
  const { match, history } = useRouter();

  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="text-3xl font-semibold text-cs-gray-900">
        Edit Profile
      </div>
      <MissingFields user={currentUser} />
      <Divider />
      <div className="space-y-8">
        <Section
          title="Basic Information"
          subtitle={(
            <>
              Increase the likelihood of connecting by adding a photo and introduction of yourself.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            <Name user={currentUser} />
            <About user={currentUser} />
            <Avatar user={currentUser} />
            <Phone user={currentUser} />
            <Opportunities user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Location and timezone"
          subtitle={(
            <>
              Match with people and opportunities near you and show the right times for your location.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            <ZipCode user={currentUser} />
            <Timezone user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Website and social media"
          subtitle={(
            <>
              {currentUser.data.isEducator ?
                'Allow potential partners to learn more about you.' :
                'Allow students and educators to learn more about you.'
              }
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            <Website user={currentUser} />
            <LinkedIn user={currentUser} />
            <Twitter user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Demographics"
          subtitle={(
            <>
              Help measure our progress towards being representative of our broader community's diversity.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            <Demographics user={currentUser} />
          </div>
        </Section>
        <Divider />
        {currentUser.data.isEducator ? (
          <Section
            title="Workplace"
            subtitle="Add your school(s) or learning site here to make sharing a project faster."
          >
            <div className="grid grid-cols-1 gap-4">
              <Sites user={currentUser} />
            </div>
          </Section>
        ) : (
          <Section
            title="Affiliations"
            subtitle="Identify organizations where you work or serve to help educators find you."
          >
            <div className="grid grid-cols-1 gap-4">
              <Organizations user={currentUser} />
            </div>
          </Section>
        )}
        {!currentUser.data.isEducator && (
          <>
            <Divider />
            <Section
              title="Past and present work experiences"
              subtitle={(
                <>
                  Identify topics and experiences you are willing to share or explore with students or educators.
                </>
              )}
            >
              <div className="grid grid-cols-1 gap-4">
                {/*<Skills user={currentUser} />*/}
                <ExpertiseCards user={currentUser} />
              </div>
            </Section>
            <Divider />
            <Section
              title="Engagement preferences"
              subtitle={(
                <>
                  Specify if you are passionate about serving particular audiences.
                </>
              )}
            >
              <div className="grid grid-cols-1 gap-4">
                <Ages user={currentUser} />
                <Activities user={currentUser} />
                <Groups user={currentUser} />
              </div>
            </Section>
            <Divider />
            {/*<Section*/}
            {/*  title="Life experience"*/}
            {/*  subtitle={(*/}
            {/*    <>*/}
            {/*      Educators are frequently looking to connect learners with the opportunity*/}
            {/*      to build the following skill sets. Think about all parts of yourself and*/}
            {/*      your life that have shaped who you are today.*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*>*/}
            {/*  <div className="grid grid-cols-1 gap-4">*/}
            {/*    <Experiences user={currentUser} />*/}
            {/*    <Guidances user={currentUser} />*/}
            {/*  </div>*/}
            {/*</Section>*/}
            {/*<Divider />*/}
            <Section
              title="Life experiences"
              subtitle={(
                <>
                  Identify experiences and aspects of yourself you are willing to share with students and educators.
                </>
              )}
            >
              <div className="grid grid-cols-1">
                <ExperienceCards user={currentUser} />
              </div>
            </Section>
            <Divider />
            <Section
              title="Professional competencies"
              subtitle={(
                <>
                  Share if you're able to connect with students on frequently needed topics.
                </>
              )}
            >
              <div className="grid grid-cols-1">
                <GuidanceCards user={currentUser} />
              </div>
            </Section>
          </>
        )}
        {currentUser.data.isEducator && (
          <>
            <Divider />
            <Section
              title="Teaching"
              subtitle={(
                <>
                  Get opportunities most relevant to your subject, grades, and professional learning interests.
                </>
              )}
            >
              <div className="grid grid-cols-1 gap-4">
                <Subjects user={currentUser} />
                <Grades user={currentUser} />
                <Practices user={currentUser} />
              </div>
            </Section>
          </>
        )}
      </div>
    </div>
  );
};
