import React from 'react';
import PropTypes from 'prop-types';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import validButtons from './validButtons';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

ProtectedButton.propTypes = {
  button: validButtons,
  onClick: PropTypes.func
};

export default function ProtectedButton(props) {
  const {
    button,
    onClick,
    ...other
  } = props;

  const missingFields = useUserProfileMissingFields();

  const launch = useDialogLauncher();

  if (!missingFields) {
    return null;
  }

  const fieldCount = missingFields.profile.length + missingFields.interests.length;

  function onCompleteProfile() {
    launch(DialogConfig.ProfileMissing());
  }

  if (fieldCount > 0) {
    return (
      <props.button
        onClick={onCompleteProfile}
        { ...other}
      />
    );
  }

  return (
    <props.button
      onClick={onClick}
      { ...other}
    />
  );
}
