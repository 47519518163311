import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import usePageRouter from '../../../../dialogs-routable/_hooks/usePageRouter';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import LoadingBanner from '../../../_banner/LoadingBanner';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

export default function YesBanner(props) {
  const { invitation, creator, onClose } = props;

  const [timestamp] = useState(new Date().toISOString());

  const launch = useDialogLauncher();
  const { history } = usePageRouter();
  const color = 'green';

  const evaluations = useConnect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          // invitationId: invitation.id,
          eventId: invitation.data.eventId,
          userId: invitation.data.userId,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  if (
    event.state === PayloadStates.FETCHING ||
    evaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner
        color={color}
        creator={creator}
      />
    );
  }

  function showMaybeDialog(onChange) {
    launch(DialogConfig.InvitationMaybe(invitation.id));
  }

  function showNoDialog(onChange) {
    launch(DialogConfig.InvitationNo(invitation.id));
  }

  const title = `Thanks for attending!`;
  const subtitle = (
    <>
      Go to your messages to keep the conversation going. When you're done with the
      event, fill out an evaluation to let us know about your experience.
    </>
  );

  const evaluation = evaluations.data[0];

  if (!evaluation) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title="You are attending! Need to change your RSVP?"
        subtitle={subtitle}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton outline={true} onClick={showNoDialog}>
            I can no longer attend
          </ConnectButton>
          <ConnectButton outline={true} onClick={showMaybeDialog}>
            I am unsure if I can attend
          </ConnectButton>
          <ConnectButton color={color} onClick={() => {
            // history.push(`/event/${invitation.data.eventId}/messages/${event.data.creatorId}`);
            // onClose && onClose();
            launch(DialogConfig.Messages(creator.id, { eventId: invitation.data.eventId }));
          }}>
            Go to messages
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  if (evaluation.data.completed) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title={(
          <>
            Thanks for being amazing!
          </>
        )}
        subtitle={(
          <>
            This event is complete and you finished your evaluation. Thank you for
            making CommunityShare stronger! We have more events waiting for you to
            help. If you're ready for another one, see what's out there.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color={color} onClick={() => {
            history.push('/events');
            onClose && onClose();
          }}>
            Explore Events
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  const to = `/evaluation-event-partner/${evaluation.id}`;

  const diff = DateTime.fromISO(evaluation.data.updatedAt).diff(DateTime.fromISO(evaluation.data.createdAt)).milliseconds;

  if (diff > 100) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title={(
          <>
            Have 5 minutes? Complete your evaluation.
          </>
        )}
        subtitle={(
          <>
            Evaluations promote great partnerships and help us make future events
            even better. Let's finish reviewing your experience.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color={color} onClick={() => {
            history.push(to);
            onClose && onClose();
          }}>
            Finish evaluation
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={title}
      subtitle={subtitle}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => {
          // history.push(`/event/${invitation.data.eventId}/messages/${event.data.creatorId}`);
          // onClose && onClose();
          launch(DialogConfig.Messages(creator.id, { eventId: invitation.data.eventId }));
        }}>
          Go to messages
        </ConnectButton>
        <ConnectButton color={color} onClick={() => {
          history.push(to);
          onClose && onClose();
        }}>
          Start evaluation
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
