import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const expertiseTypes = useTags(TagTypes.ExpertiseType);
  const userExpertises = useUserTags(user, TagTypes.Expertise);

  if (
    expertiseTypes.state === PayloadStates.FETCHING ||
    userExpertises.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Skills</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Skills</title>
      </Helmet>
      <Form
        user={user}
        expertiseTypes={expertiseTypes}
        userExpertises={userExpertises}
        navigation={user.data.isEducator ?
          <Navigation activeStep={5} steps={10}/> :
          <Navigation activeStep={5} steps={12}/>
        }
        onPrevious={(newData) => history.push(Urls.ORGANIZATION)}
        onNext={(newData) => history.push(Urls.EXPERIENCE)}
        onSkip={() => history.push(Urls.EXPERIENCE)}
      />
    </ParentLayout>
  );
};
