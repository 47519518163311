import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';

DeclineBanner.propTypes = {
  offer: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired
};

export default function DeclineBanner(props) {
  const { offer, claim, creator, onClose } = props;

  const launch = useDialogLauncher();

  function showInterestedDialog(onChange) {
    launch(DialogConfig.ClaimConnect(claim.id));
  }

  return (
    <Wrapper
      color="gray"
      creator={creator}
      title={(
        <>
          Have you changed your mind?
        </>
      )}
      subtitle={(
        <>
          You declined this Offer, but you can always change your mind and tell
          the Partner you're interested.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {/*<ConnectButton outline={true} onClick={() => history.push(`/offer/${offer.id}/messages`)}>*/}
        {/*  Go to messages*/}
        {/*</ConnectButton>*/}
        <ConnectButton color="gray" onClick={showInterestedDialog}>
          I'm interested!
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
