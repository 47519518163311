import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useUser } from '@lore/auth';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';
import Card from '../../../components/Card';

export default function Invitation(props) {
  const { invitation, children, alignChildren = 'bottom' } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: invitation.data.userId
  });

  const creator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const eventCreator = useConnect('user.byId', {
    id: event.data.creatorId
  }, {
    enabled: !!event.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    event.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    eventCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  const to = currentUser.id === event.data.creatorId ?
    `/event/${event.id}/manage` :
    `/event/${event.id}/view`;

  function onClick() {
    launch(DialogConfig.InvitationView(invitation.id));
  }

  return (
    <Card border={false} className={classNames(
      // 'bg-white shadow-md rounded-xl overflow-hidden divide-y divide-gray-200 relative',
      'divide-y divide-gray-200 relative',
      'cursor-pointer',
      invitation.data.response === 'Pending' ?
        '-m-0.5 border-2 border-cs-orange-500' :
        '-m-0.5 border-2 border-cs-gray-100'
      //   'border border-cs-gray-200'
    )} onClick={onClick}>
      <Link to={to} className="absolute w-full h-full" />
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={eventCreator.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {eventCreator.data.firstName} {eventCreator.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              <span>Event Creator</span>
            </div>
          </div>
        </div>
        {invitation.data.response === 'Yes' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-green-600">
              Attending
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>You reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {event.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(invitation.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {invitation.data.response === 'Maybe' && (
            <div className="flex flex-col text-right">
              <div className="text-lg font-semibold text-cs-blue-500">
                Maybe
              </div>
              <div className="text-sm text-cs-gray-500 space-x-1">
                {creator.id === recipient.id ? (
                  <span>You reached out</span>
                ) : (
                  <span>Invited by {creator.data.firstName} {creator.data.lastName} {event.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
                )}
                <span>·</span>
                <span>{DateTime.fromISO(invitation.data.createdAt).toRelative()}</span>
              </div>
            </div>
        )}
        {invitation.data.response === 'Pending' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-cs-orange-500">
              Awaiting response
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>Your reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {event.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(invitation.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {invitation.data.response === 'No' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500">
              Declined
            </div>
            <div className="flex text-sm text-cs-gray-500">
              {invitation.data.responseMessage}
            </div>
          </div>
        )}
      </div>
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
