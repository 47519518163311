import { useEffect } from 'react';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

export default function Launcher(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  useEffect(() => {
    launch(DialogConfig.Messages(user.id));
  }, []);

  return null;
}
