import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../dialogs-routable/_launcher/DialogKey';

export default function Question(props) {
  const { question, children, alignChildren = 'bottom' } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const projectCreator = useConnect('user.byId', {
    id: project.data.creatorId
  }, {
    enabled: !!project.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    projectCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  const to = currentUser.id === project.data.creatorId ?
    `/project/${project.id}/manage` :
    `/project/${project.id}/view`;

  function onClick() {
    launch(DialogConfig.QuestionView(question.id));
  }

  return (
    <Card border={false} className={classNames(
      // 'bg-white shadow-md rounded-xl overflow-hidden divide-y divide-gray-200 relative',
        'divide-y divide-gray-200 relative',
        'cursor-pointer',
      question.data.response === 'Pending' ?
        '-m-0.5 border-2 border-cs-orange-500' :
        '-m-0.5 border-2 border-cs-gray-100'
      //   'border border-cs-gray-200'
    )} onClick={onClick}>
      {/*<Link to={to} className="absolute w-full h-full" />*/}
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={projectCreator.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-cs-gray-900">
              {projectCreator.data.firstName} {projectCreator.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              <span>Project Creator</span>
            </div>
          </div>
        </div>
        {question.data.response === 'Connect' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-green-600">
              Connected
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>You reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(question.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {question.data.response === 'Pending' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-cs-orange-500">
              Awaiting response
            </div>
            <div className="text-sm text-cs-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>Your reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{DateTime.fromISO(question.data.createdAt).toRelative()}</span>
            </div>
          </div>
        )}
        {question.data.response === 'Decline' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500">
              Declined
            </div>
            <div className="flex text-sm text-cs-gray-500">
              {question.data.responseMessage}
            </div>
          </div>
        )}
      </div>
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
    </Card>
  );
};
