import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../components/Tag';
import typeColorMap from '../../../maps/typeColorMap';
import ContentCard from '../../../components_profiles/ContentCard';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';

TagsToPills.propTypes = {
  tags: PropTypes.object.isRequired
};

export default function TagsToPills(props) {
  const { title, tags } = props;

  if (tags.data.length === 0) {
    return null;
  }

  return (
    <ContentCard className="space-y-2">
      <SubSectionHeader title={title} />
      {tags.data.map(function(tag) {
        return (
          <Tag
            key={tag.id}
            label={tag.data.name}
            tooltip={tag.data.description}
            color={typeColorMap[tag.data.type]}
          />
        );
      })}
    </ContentCard>
  );
};
