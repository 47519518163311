import React from 'react';
import _ from 'lodash';
import { CogIcon, LinkIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import useRouter from '../../hooks/useRouter';
import classNames from 'classnames';

export default function ActionBar(props) {
  const { location } = useRouter();

  const links = _.remove([
    {
      icon: LinkIcon,
      label: 'View Profile',
      to: `/profile/view`
    },
    {
      icon: PencilIcon,
      label: 'Edit Profile',
      to: `/profile/edit`
    },
    {
      icon: CogIcon,
      label: 'Settings',
      to: `/profile/settings`
    }
  ], entry => entry);

  return (
    <div className="flex items-center justify-center px-8 py-4 bg-cs-gray-100 border-t border-b border-cs-gray-200">
      <div className="flex flex-wrap gap-4 justify-center">
        {links.map(function(link, index) {
          if (link.type === 'button') {
            const colorMap = {
              green: 'text-green-600 bg-green-50 border border-green-500 -m-px',
              red: 'text-red-600 bg-red-50 border border-red-500 -m-px',
            };

            return (
              <div
                key={index}
                onClick={link.onClick}
                className={classNames(
                  'flex space-x-2 items-center shadow-lg px-4 py-3 rounded-lg cursor-pointer',
                  link.active ?
                    colorMap[link.activeColor] || colorMap['green'] :
                    'text-cs-gray-500 bg-white hover:bg-cs-gray-50'
                )}
              >
                <link.icon className="h-5 w-5" />
                <span>
                  {link.label}
                </span>
              </div>
            );
          }

          return (
            <Link
              key={index}
              to={link.to}
              className={classNames(
                'flex space-x-2 items-center shadow-lg px-4 py-3 rounded-lg cursor-pointer',
                (
                  location.pathname.indexOf(link.to) === 0 ||
                  link.to.indexOf(location.pathname) === 0
                ) ?
                  'text-cs-orange-500 bg-cs-orange-50' :
                  'text-cs-gray-500 bg-white hover:bg-cs-gray-50'
              )}
            >
              <link.icon className="h-5 w-5" />
              <span>
                {link.label}
              </span>
            </Link>
          );
        })}
        {/*<ActionBarMenu />*/}
      </div>
    </div>
  );
};
