import React from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useMissingFields from './useMissingFields';
import { DialogConfig } from '../../dialogs-routable/_launcher/DialogKey';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

export default function Visibility(props) {
  const { offer } = props;

  const launch = useDialogLauncher();
  const { missingFields } = useMissingFields(offer);

  if (
    missingFields.state === PayloadStates.FETCHING ||
    missingFields.data.length > 0
  ) {
    return null;
  }

  function onChangeVisibility() {
    launch(DialogConfig.OfferVisibility(offer.id));
  }

  if (offer.data.published) {
    return (
      <div className="flex space-x-1 items-center text-cs-gray-500">
        <EyeIcon className="h-6 w-6" />
        <span className="text-cs-orange-500 cursor-pointer" onClick={onChangeVisibility}>
          Visible
        </span>
      </div>
    );
  }

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500">
      <EyeSlashIcon className="h-6 w-6" />
      <span className="text-cs-orange-500 cursor-pointer" onClick={onChangeVisibility}>
        Hidden
      </span>
    </div>
  );
};
