import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { ChartBarIcon } from '@heroicons/react/24/outline';

export default function Filled(props) {
  const { event } = props;

  const [timestamp] = useState(new Date().toISOString());

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          response: 'Yes',
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
        <ChartBarIcon className="h-6 w-6" />
        <span>
          ...
        </span>
      </div>
    );
  }

  if (!event.data.slots) {
    return (
      <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
        <ChartBarIcon className="h-6 w-6" />
        <span>
          ?
        </span>
      </div>
    );
  }
  const percent = Math.floor(invitations.data.length / event.data.slots * 100);

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
      <ChartBarIcon className="h-6 w-6" />
      <span>
        {percent}%
      </span>
    </div>
  );
}
