import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Student Size"
      title="How many students will Partners work with at one time?"
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={8} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).STUDENT_AGE)}
        onNext={(newData) => history.push(Urls(project).SUBJECTS)}
        onSkip={() => history.push(Urls(project).SUBJECTS)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
