import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import {
  AcademicCapIcon,
  CalendarIcon,
  CameraIcon,
  ChatBubbleLeftRightIcon,
  MapIcon,
  TvIcon,
  UserGroupIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import PrimaryHeader from '../../components/PrimaryHeader';
import EmptyState from '../../components/EmptyState';
import useNetwork from '../../hooks/useNetwork';
import Loader from '../../components/Loader';
import Steward from './Steward';
import Section from './Section';
import Option from './Option';
import { useUser } from '@lore/auth';
import Services from './Services';
import Card from '../../components/Card';
import Divider from '../../forms/_fields_v2/Divider';

export default function Layout(props) {
  const network = useNetwork();

  const currentUser = useUser();

  const stewards = useConnect('steward.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id
        }
      }
    }
  });

  if (stewards.state === PayloadStates.FETCHING) {
    return (
      <>
        <Helmet>
          <title>Your Network</title>
        </Helmet>
        <PrimaryHeader
          style="simple"
          title="Your Network"
        />
        <div className="container page">
          <Loader />
        </div>
      </>
    );
  }

  if (stewards.data.length === 0) {
    return (
      <>
        <Helmet>
          <title>Your Network</title>
        </Helmet>
        <PrimaryHeader
          style="simple"
          title="Your Network"
        />
        <div className="container page">
          <EmptyState
            title="There are currently no stewards supporting this community."
          />
        </div>
      </>
    );
  }

  const placeholderLink = [
    `mailto:placeholder@example.com`,
    `?subject=`,
    encodeURIComponent(`Subject Placeholder`),
    `&body=`,
    encodeURIComponent(`This is ${currentUser.data.firstName} ${currentUser.data.lastName} and I have a question.`)
  ].join('');

  return (
    <>
      <Helmet>
        <title>Your Network</title>
      </Helmet>
      <PrimaryHeader
        style="simple"
        title="Your Network"
      />
      <div className="containerx pagex px-8 mb-12 space-y-12">
        <Section
          title="Your Stewards"
          subtitle={(
            <>
              Your stewards are locals and their goal is to help you feel supported through your
              whole learning journey. Take a look at their specialities!
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            {stewards.data.map(function(steward, index) {
              return (
                <Steward
                  key={index}
                  steward={steward}
                />
              );
            })}
          </div>
        </Section>
        {currentUser.data.isEducator && (
          <Services />
        )}
        {false && currentUser.data.isEducator && (
          <Section
            title="What can we help you with today?"
          >
            <div className="bg-white rounded-lg shadow-md divide-y divide-gray-100 overflow-hidden">
              <Option
                icon={ChatBubbleLeftRightIcon}
                title="Getting started"
                subtitle="I don't know where to start and I'd like to talk."
                linkText="Email steward"
                link={placeholderLink}
              />
              <Option
                icon={MapIcon}
                title="Mapping an idea"
                subtitle="I have an idea and I need help mapping it out before sharing it with potential partners."
                linkText="Email steward"
                link={placeholderLink}
              />
              <Option
                icon={UsersIcon}
                title="Finding a partner"
                subtitle="I need help locating a specific type of partner that isn't available here."
                linkText="Email steward"
                link={placeholderLink}
              />
              <Option
                icon={CameraIcon}
                title="Getting a photographer/videographer"
                subtitle="I would like someone to photograph or video my project in action."
                linkText="Email steward"
                link={placeholderLink}
              />
              <Option
                icon={TvIcon}
                title="Obtaining media coverage"
                subtitle="I would like help getting media coverage of my project."
                linkText="Email steward"
                link={placeholderLink}
              />
            </div>
          </Section>
        )}
        {currentUser.data.isEducator && (
          <Section
            title="Want to do a deeper dive into community engaged-learning?"
            // subtitle={(
            //   <>
            //     Our network-wide Learning Designer is available on a first come, first served
            //     basis to talk through project ideas and shape your learning experience.
            //   </>
            // )}
          >
            <Card className="overflow-hidden">
              <Option
                icon={UserGroupIcon}
                title="The CommunityShare Collaborative"
                subtitle="I want to connect with peers, ask questions, and learn together over time."
                linkText="Visit online community"
                link="https://collab.communityshare.org/home"
              />
              <div className="px-4">
                <Divider />
              </div>
              <Option
                icon={AcademicCapIcon}
                title="Professional Learning Opportunities"
                subtitle="I want to learn more through workshops, events, and cohorts."
                linkText="See calendar"
                link="https://collab.communityshare.org/c/public-events/"
              />
              <div className="px-4">
                <Divider />
              </div>
              <Option
                icon={CalendarIcon}
                title="Talk with our Professional Learning Designer"
                subtitle="I'd like to talk through ideas and begin shaping a community-engaged learning experience."
                linkText="Schedule time"
                link="https://calendar.app.google/swMxnJTbn7iMri3t7"
              />
            </Card>
          </Section>
        )}
      </div>
    </>
  );
};
