import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import usePageRouter from '../../../../dialogs-routable/_hooks/usePageRouter';
import ConnectButton from '../../../_banner/ConnectButton';
import Wrapper from '../../../_banner/Wrapper';
import LoadingBanner from '../../../_banner/LoadingBanner';
import useDialogLauncher from '../../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogConfig } from '../../../../dialogs-routable/_launcher/DialogKey';

export default function ConnectBanner(props) {
  const { question, creator, onClose } = props;

  const [timestamp] = useState(new Date().toISOString());

  const { history } = usePageRouter();
  const launch = useDialogLauncher();
  const color = 'green';

  const evaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          // questionId: question.id,
          projectId: question.data.projectId,
          userId: question.data.userId,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <LoadingBanner
        color={color}
        creator={creator}
      />
    );
  }

  const title = `Thanks for connecting!`;
  const subtitle = (
    <>
      Go to your messages to keep the project going. When you're done with the
      project, fill out an evaluation to let us know about your experience.
    </>
  );

  const evaluation = evaluations.data[0];

  if (!evaluation) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title={title}
        subtitle={subtitle}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton outline={true} onClick={() => {
            // history.push(`/project/${question.data.projectId}/messages`);
            // onClose && onClose();
            launch(DialogConfig.Messages(creator.id, { projectId: question.data.projectId }));
          }}>
            Go to messages
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  if (evaluation.data.completed) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title={(
          <>
            Thanks for being amazing!
          </>
        )}
        subtitle={(
          <>
            This project is complete and you finished your evaluation. Thank you for
            making CommunityShare stronger! We have more projects waiting for you to
            help. If you're ready for another one, see what's out there.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color={color} onClick={() => {
            history.push('/projects');
            onClose && onClose();
          }}>
            Explore Projects
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  const to = `/evaluation-project-partner/${evaluation.id}`;

  const diff = DateTime.fromISO(evaluation.data.updatedAt).diff(DateTime.fromISO(evaluation.data.createdAt)).milliseconds;

  if (diff > 100) {
    return (
      <Wrapper
        color={color}
        creator={creator}
        title={(
          <>
            Have 5 minutes? Complete your evaluation.
          </>
        )}
        subtitle={(
          <>
            Evaluations promote great partnerships and help us make future projects
            even better. Let's finish reviewing your experience.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color={color} onClick={() => {
            history.push(to);
            onClose && onClose();
          }}>
            Finish evaluation
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={title}
      subtitle={subtitle}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => {
          // history.push(`/project/${question.data.projectId}/messages`);
          // onClose && onClose();
          launch(DialogConfig.Messages(creator.id, { projectId: question.data.projectId }));
        }}>
          Go to messages
        </ConnectButton>
        <ConnectButton color={color} onClick={() => {
          history.push(to);
          onClose && onClose();
        }}>
          Start evaluation
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
