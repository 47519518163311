import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { formatDate, formatDateRange } from '../../../../utils/dateTimeUtils';

export default function DateRange(props) {
  const { offer } = props;

  return (
    <div className="flex space-x-1 items-center text-cs-gray-500 text-sm">
      <CalendarIcon className="h-6 w-6" />
      {offer.data.specificDate === 'Yes' ? (
        <span>
          {formatDate(offer.data.date)}
        </span>
      ) : (
        <span>
          {formatDateRange(offer.data.startDate, offer.data.endDate)}
        </span>
      )}
    </div>
  );
};
