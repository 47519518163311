import React from 'react';
import classNames from 'classnames';

export default function BannerTemplate(props) {
  const {
    icon,
    emoji,
    title,
    subtitle,
    actions,
    theme = 'purple'
  } = props;

  let titleColor = 'text-cs-gray-900';
  let subtitleColor = 'text-cs-gray-500';

  // titleColor = undefined;
  // subtitleColor = undefined;

  const colorMap = {
    green: {
      text: 'text-green-600',
      border: 'border border-green-500 -m-px',
      bg: 'bg-green-500 bg-opacity-5'
    },
    red: {
      text: 'text-red-600',
      border: 'border border-red-500 -m-px',
      bg: 'bg-red-500 bg-opacity-5'
    },
    purple: {
      text: 'text-cs-purple-600',
      border: 'border border-cs-purple-500 -m-px',
      bg: 'bg-cs-purple-500 bg-opacity-5'
    },
    blue: {
      text: 'text-cs-blue-600',
      border: 'border border-cs-blue-500 -m-px',
      bg: 'bg-cs-blue-500 bg-opacity-5'
    }
  }[theme];

  return (
    <div className={classNames(
      'flex space-x-5 rounded-cs-10 px-6 py-5 shadow-cs-flat',
      colorMap.bg,
      colorMap.border
    )}>
      {emoji ? (
        <div className="text-4xl">
          {emoji}
        </div>
      ) : (
        <props.icon className={`h-9 w-9 ${colorMap.text}`} />
      )}
      <div className="flex-1 space-y-0.5">
        <div className={`text-lg font-semibold ${titleColor || colorMap.text}`}>
          {title}
        </div>
        <div className={`text-sm ${subtitleColor || colorMap.text} opacity-90`}>
          {subtitle}
        </div>
        {actions && (
          <div className={`flex space-x-4 pt-2 ${colorMap.text}`}>
            {actions}
          </div>
        )}
      </div>
    </div>
  );
}
