import React from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from './Breadcrumbs';
import Header from './Header';
import ActionBar from './ActionBar';

export default function ProfileLayout(props) {
  const { children, page } = props;

  return (
    <>
      <Helmet>
        <title>Profile - {page}</title>
      </Helmet>
      <Breadcrumbs />
      <Header />
      <ActionBar />
      <div className="container">
        {children}
      </div>
    </>
  );
};
