import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from '@lore/auth';
import Offers from './Offers';
import Claims from './Claims';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ProtectedLinkButton from '../../components/Button/ProtectedLinkButton';
import { SolidButton } from '../../components/Button';

function Header(props) {
  const { currentUser, children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Your Offers</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <div className="text-3xl font-semibold text-cs-gray-900">
          Your Offers
        </div>
        {!currentUser.data.isEducator && (
          <ProtectedLinkButton
            button={SolidButton}
            to="/post-offer"
            label="Create Offer"
          />
        )}
      </div>
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          'offer.creatorId': {
            $not: currentUser.id
          },
          'offer.activityId': {
            $not: null
          },
          createdAt: {
            $lte: timestamp
          },
          $or: {
            userId: currentUser.id,
            creatorId: currentUser.id
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (
    offers.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <Header currentUser={currentUser}>
        <Loader className="mx-7" />
      </Header>
    );
  }

  return (
    <Header currentUser={currentUser}>
      {!currentUser.data.isEducator && (
        <div className="space-y-8">
          <Offers type="active" />
          <Offers type="draft" />
          <Offers type="past" />
        </div>
      )}
      {currentUser.data.isEducator && (
        <div className="space-y-8">
          <Claims type="invited" />
          <Claims type="active" />
          <Claims type="past" />
        </div>
      )}
    </Header>
  );
}
